import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { PrescriptionStatus as Status } from 'src/models/prescription';

@Pipe({ name: 'prescription_progress' })
export class PrescriptionProgressPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === Status.create_new,
        () => 5,
      )
      .on(
        v => v === Status.sent,
        () => 10,
      )
      .on(
        v => v === Status.confirmed,
        () => 40,
      )
      .on(
        v => v === Status.dispensed,
        () => 70,
      )
      .on(
        v => v === Status.req_resend,
        () => 5,
      )
      .on(
        v => v === Status.rejected,
        () => 0,
      )
      .on(
        v => v === Status.completed,
        () => 100,
      )
      .otherwise(() => 0);
  }
}
