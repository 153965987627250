import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from 'src/environments/environment';
import { IPCF } from 'src/models/pcf';
import { CognitoService } from '../cognito.service';
import { ServiceBase } from '../service-base';

@Injectable({
  providedIn: 'root',
})
export class PcfService extends ServiceBase<IPCF> {
  readonly propertyName = 'pcfs';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'pcfs');
  }

  // override
  public find(id: string): Promise<IPCF | null> {
    return super.find(id);
  }

  // override
  public create(pcf: IPCF): Promise<void> {
    return super.create(pcf);
  }

  public findAll(params?: { status?: string; patient_info_id?: string }): Promise<IPCF[]> {
    return super.findAll(params);
  }

  public async confirm(followId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/pcfs/${followId}/confirm`,
      undefined,
      await this.buildConfig(config, 'application/json'),
    );
  }

  public async cancel(followId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/pcfs/${followId}/cancel`,
      undefined,
      await this.buildConfig(config, 'application/json'),
    );
  }

  public findAllWithPagination(
    params: { status?: string; limit?: number; last_key?: string } = {
      limit: 25,
    },
  ) {
    console.log('pcfService', params);
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IPCF[],
      pagination: result.pagination as { totalrecords: number; displayrecords: number; last_key: string },
    }));
  }
}
