<div class="root" fxLayout="column">
  <h1>受信処方箋画像一覧</h1>
  <div fxLayout="row" class="patient-search-form-container">
    <mat-form-field fxFlex="50">
      <mat-label class="search-label">名前で検索</mat-label>
      <input matInput type="text" [formControl]="patientFilterController" />
    </mat-form-field>
  </div>
  <div class="table-container">
    <div class="paginator-and-table">
      <app-custom-paginator
        [disabled]="loading"
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
        (reload)="reloadEvent()"
      ></app-custom-paginator>
      <div class="table">
        <mat-card class="header" fxLayout="row">
          <div fxFlex fxLayout="row" fxLayoutGap="5px" class="header-content">
            <div fxFlexAlign="center">受信日時</div>
          </div>
          <div fxFlex class="header-content" fxFlexAlign="center">患者氏名</div>
          <div
            fxFlex
            fxLayout="row"
            class="header-content clickable"
            #stateFilterControllerOrigin="cdkOverlayOrigin"
            cdk-overlay-origin
            (click)="filterByStatus()"
          >
            <div fxFlexAlign="center">実施状況</div>
            <mat-icon fxFlexAlign="center">filter_alt</mat-icon>
          </div>
          <div fxFlex fxLayout="row" fxLayoutGap="5px" class="header-content">
            <div fxFlexAlign="center">進捗</div>
          </div>
        </mat-card>
        <mat-card
          *ngFor="let element of prescriptions; index as i"
          class="element clickable"
          [class.unclickable]="prescriptionsDisabled[i]"
          fxLayout="row"
          [routerLink]="'./' + element.id"
        >
          <div fxFlex class="element-content">{{ element.created_at | date: 'yyyy/MM/dd HH:mm:ss' }}</div>
          <div fxFlex class="element-content">
            <a
              color="primary"
              (click)="$event.stopPropagation()"
              [routerLink]="'/pharmacist/patients/' + element.patient_info_id"
            >
              <mat-icon
                class="patient-info-icon"
                matTooltip="患者詳細"
                matTooltipPosition="right"
                matTooltipShowDelay="250"
              >
                assignment_ind</mat-icon
              >
            </a>
            {{ getPatientName(element.patient_info) }}
          </div>
          <div fxFlex class="element-content">
            {{
              element.cancelled_at && element.pharmacist_confirmed_at
                ? 'キャンセル(確認)'
                : element.cancelled_at && !element.pharmacist_confirmed_at
                ? 'キャンセル(未確認)'
                : (element.status | prescription_status)
            }}
          </div>
          <div fxFlex class="element-content" fxLayout="column" fxLayoutAlign="center">
            <div class="progress-bar-wrap" fxLayout="column" fxLayoutAlign="center">
              <mat-progress-bar
                mode="determinate"
                [value]="element.status | prescription_progress"
                [color]="
                  element.status === 'req_resend' || element.status === 'rejected' || element.cancelled_at
                    ? 'warn'
                    : 'primary'
                "
              ></mat-progress-bar>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="loading-shade" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
