<div class="root" [@fadeInAnimation]="">
  <div fxLayout="column">
    <div class="title" fxLayout="row" fxLayoutGap="15px" *ngIf="title || icon">
      <mat-icon fxFlexAlign="center">{{ icon }}</mat-icon>
      <div fxFlexAlign="center">{{ title }}</div>
    </div>
    <div class="choice clickable" *ngFor="let name of names; index as i" (click)="state[i] = !state[i]" matRipple>
      <div fxLayout="row" fxLayoutGap="15px">
        <mat-pseudo-checkbox
          class="mat-pseudo-checkbox mat-option-pseudo-checkbox ng-star-inserted"
          [state]="state[i] ? 'checked' : 'unchecked'"
          fxFlexAlign="center"
        ></mat-pseudo-checkbox>
        <div fxFlexAlign="center">{{ name }}</div>
      </div>
    </div>
  </div>
</div>
