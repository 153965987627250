import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPharmacy } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class PharmacyService extends ServiceBase<IPharmacy> {
  propertyName = 'pharmacies';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'pharmacies');
  }

  public findAll(
    offset?: number,
    limit?: number,
    sortBy?: string,
    latitude?: number,
    longitude?: number,
  ): Promise<IPharmacy[]> {
    const params = {
      offset,
      limit,
      sortBy,
      latitude,
      longitude,
    };
    return super.findAll(params);
  }

  public async activate(pharmacyId: string): Promise<void> {
    await axios.post(
      environment.api_base_url + `pharmacy/pharmacies/${pharmacyId}/activate`,
      undefined,
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  public async deactivate(pharmacyId: string): Promise<void> {
    await axios.post(
      environment.api_base_url + `pharmacy/pharmacies/${pharmacyId}/deactivate`,
      undefined,
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  public async uploadImage(pharmacyId: string, image: string, image_type: 'interior' | 'exterior') {
    await axios.post(
      environment.api_base_url + `pharmacy/pharmacies/${pharmacyId}/image`,
      { image, image_type },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  public async deleteImage(pharmacyId: string, deletable_url: string) {
    await axios.post(
      environment.api_base_url + `pharmacy/pharmacies/${pharmacyId}/image/delete`,
      { deletable_url },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  public async updateLogo(pharmacyId: string, image: string) {
    await axios.post(
      environment.api_base_url + `pharmacy/pharmacies/${pharmacyId}/logo`,
      { image },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  public async deleteLogo(pharmacyId: string) {
    await axios.delete(
      environment.api_base_url + `pharmacy/pharmacies/${pharmacyId}/logo`,
      await this.buildConfig(undefined, 'application/json'),
    );
  }
}
