<div class="custom-paginator-wrapper">
  <mat-paginator
    [color]="color"
    [disabled]="disabled"
    [hidePageSize]="hidePageSize"
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="false"
    (page)="pageEvent($event)"
  ></mat-paginator>
  <div class="custom-paginator-overlay" fxLayout="row">
    <button
      class="reload-button"
      mat-icon-button
      fxFlexAlign="center"
      fxFlexOffset="15px"
      [disabled]="disabled"
      matTooltip="reload"
      matTooltipPosition="above"
      (click)="reloadEvent()"
    >
      <mat-icon>cached</mat-icon>
    </button>
  </div>
</div>
