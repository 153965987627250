import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { IQAT, QuestionType } from 'src/models/qa-template';

@Component({
  selector: 'app-questionnaire-editor',
  templateUrl: './questionnaire-editor.component.html',
  styleUrls: ['./questionnaire-editor.component.scss'],
})
export class QuestionnaireEditorComponent {
  @Input() questions: IQAT[] = [];
  @Input() disabled = false;
  @Input() isVisibleRequiredCheckBox = true;
  @Output() questionsChange = new EventEmitter<IQAT[]>();
  readonly trackByIndex = (index: number, item: any) => index;

  constructor(private cdRef: ChangeDetectorRef) {}

  toggleRequirement(q: number, event: boolean) {
    this.questions[q].required = event;
    this.questionsChange.emit(this.questions);
  }

  toggleQuestionType(q: number, event: QuestionType) {
    this.questions[q].type = event;
    if (event !== 'textbox' && this.questions[q].answers.length === 0) {
      this.questions[q].answers.push('', '');
    }
    if (event === 'textbox') {
      this.questions[q].answers = [];
    }
    this.questionsChange.emit(this.questions);
  }

  addQuestion() {
    this.questions.push({ required: false, type: QuestionType.radio, question: '', answers: [] });
    this.questionsChange.emit(this.questions);
    this.questions.slice(-1)[0].answers.push('', '');
  }

  dropQuestion(event: CdkDragDrop<IQAT[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    this.questionsChange.emit(this.questions);
  }

  enter(questionIndex: number, answerIndex: number) {
    if (this.questions[questionIndex].answers.length - 1 === answerIndex) {
      this.addChoice(questionIndex);
      this.cdRef.detectChanges();
    }
    this.focus(questionIndex, answerIndex + 1);
  }

  private focus(questionIndex: number, answerIndex: number) {
    const dom = document.getElementById(`choice-${questionIndex}-${answerIndex}`);
    if (dom) {
      dom.focus();
    }
  }

  addChoice(questionIndex: number) {
    if (!this.questions[questionIndex].answers) {
      this.questions[questionIndex].answers = [];
    }
    this.questions[questionIndex].answers = [...this.questions[questionIndex].answers, ''];
    this.questionsChange.emit(this.questions);
  }

  dropChoice(q: number, event: CdkDragDrop<IQAT[]>) {
    moveItemInArray(this.questions[q].answers, event.previousIndex, event.currentIndex);
    this.questionsChange.emit(this.questions);
  }
}
