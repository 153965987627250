<loading-spinner [loading]="loading"></loading-spinner>
<div *ngIf="!loading">
  <h1>服薬状況</h1>
  <div class="alert" *ngIf="this.isDeleted">
    <div fxFlexAlign="center">患者情報が削除されたため、詳細情報を表示できません。</div>
  </div>
  <div class="info-box" *ngIf="!this.isDeleted">
    <table class="patient-info-table" *ngIf="patientInfo">
      <tr>
        <td>
          {{ patientInfo?.family_name ? patientInfo?.family_name : '' }}
          {{ patientInfo?.given_name ? patientInfo?.given_name : '' }}
          （{{ patientInfo?.family_name_kana ? patientInfo?.family_name_kana : '' }}
          {{ patientInfo?.given_name_kana ? patientInfo?.given_name_kana : '' }}）
        </td>
        <td>
          {{ (patientInfo?.birth_date ? patientInfo?.birth_date : '') | date: 'yyyy年MM月dd日' }}
        </td>
        <td>
          {{ patientInfo?.sex ? (patientInfo?.sex | patient_sex) : '' }}
        </td>
      </tr>
      <tr>
        <td>
          {{ patientInfo?.zip ? (patientInfo?.zip | zip_code) : '' }}
          {{ patientInfo?.prefecture ? patientInfo?.prefecture : '' }}
          {{ patientInfo?.address1 ? patientInfo?.address1 : '' }}
          {{ patientInfo?.address2 ? patientInfo?.address2 : '' }}
          {{ patientInfo?.address3 ? patientInfo?.address3 : '' }}
        </td>
        <td>
          {{ patientInfo?.tel ? patientInfo?.tel : '' }}
        </td>
        <td>
          {{ patientInfo?.email ? patientInfo?.email : '' }}
        </td>
      </tr>
    </table>
  </div>

  <div class="alarm-data-box">
    <div class="month-select">
      <button
        class="new-follow-button round-button"
        fxFlexAlign="center"
        mat-raised-button
        color="primary"
        (click)="prevMonth()"
      >
        ◀
      </button>
      {{ year }}年{{ month }}月
      <button
        class="new-follow-button round-button"
        fxFlexAlign="center"
        mat-raised-button
        color="primary"
        (click)="nextMonth()"
      >
        ▶
      </button>
    </div>
    <div fxLayout="row">
      <div class="alarm-regular">
        定期薬
        <table class="alarm-regular-data">
          <tr>
            <th rowspan="2">写真</th>
            <th>飲み方</th>
            <th rowspan="2">平均時間</th>
            <th colspan="8">服薬率(%)</th>
          </tr>
          <tr>
            <th>パターン</th>
            <th>全体</th>
            <th>日</th>
            <th>月</th>
            <th>火</th>
            <th>水</th>
            <th>木</th>
            <th>金</th>
            <th>土</th>
          </tr>
          <ng-container *ngFor="let element of regularHistory">
            <tr>
              <td rowspan="2">
                <img class="medicine-picture" *ngIf="element.image !== ''" [src]="element.image" />
                <img class="medicine-picture" *ngIf="element.image === ''" src="/assets/NoImage.png" />
              </td>
              <td>{{ element.medication_timing | medication_timing }}</td>
              <td rowspan="2">{{ element.average_time }}</td>
              <td rowspan="2" *ngIf="element.average_rate_all === -1">-</td>
              <td rowspan="2" *ngIf="element.average_rate_all !== -1">
                {{ element.average_rate_all | number: '1.0-0' }}
              </td>
              <ng-container *ngFor="let rate of element.average_rate_wday">
                <td rowspan="2" *ngIf="rate === -1">-</td>
                <td rowspan="2" *ngIf="rate !== -1">{{ rate | number: '1.0-0' }}</td>
              </ng-container>
            </tr>
            <tr>
              <td>{{ patternText(element) }}</td>
            </tr>
          </ng-container>
        </table>
      </div>
      <div class="alarm-reluctance">
        頓服
        <table class="alarm-reluctance-data">
          <tr>
            <th rowspan="2">写真</th>
            <th colspan="3">薬品名</th>
          </tr>
          <tr>
            <th>用法</th>
            <th>使用日数</th>
            <th>使用回数</th>
          </tr>
          <ng-container *ngFor="let element of reluctanceHistory">
            <tr>
              <td rowspan="2">
                <img class="medicine-picture" *ngIf="element.image !== ''" [src]="element.image" />
                <img class="medicine-picture" *ngIf="element.image === ''" src="/assets/NoImage.png" />
              </td>
              <td colspan="3">{{ element.medicine_name }}</td>
            </tr>
            <tr>
              <td>{{ element.medication_usage }}</td>
              <td>{{ element.day_count }}</td>
              <td>{{ element.all_count }}</td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
</div>
