import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';

@Pipe({ name: 'medication_method_frequency' })
export class MedicationMethodFrequencyPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === 'everyday',
        _ => '毎日',
      )
      .on(
        v => v === 'everyweek',
        _ => '毎週',
      )
      .on(
        v => v === 'every other day',
        _ => '一日おき',
      )
      .on(
        v => v === 'date designation',
        _ => '日付指定',
      )
      .otherwise(_ => '不明');
  }
}
