import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AxiosRequestConfig } from 'axios';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { PopupCheckboxService } from 'src/app/parts/checkbox-list/checkbox-list.component';
import { IPharmacist } from 'src/models';
import { InstructionStatus, ReservationForList, TimeFrame } from 'src/models/pci';
import { ReservationStatusPipe } from 'src/pipes/reservation_status.pipe';
import { PciService } from 'src/services/api/pci.service';
import { PharmacistService } from 'src/services/api/pharmacist.service';
import { CognitoService } from 'src/services/cognito.service';
import { NotificationService } from 'src/services/notification.service';

@Component({
  selector: 'list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnDestroy {
  @ViewChild('statusHeader') private statusHeader!: CdkOverlayOrigin;
  private nameFilter = '';
  @Input('nameFilter') set _nameFilter(value: string) {
    this.nameFilter = value;
    this.filterByName();
  }
  reservations: ReservationForList[] = [];
  reservationsDisabled: boolean[] = [];
  private pharmacists: IPharmacist[] = [];
  readonly displayedColumns: string[] = [
    'receptionHours',
    'patientName',
    'pharmacistName',
    'patientTel',
    'reservation',
    'status',
    // 'payment',
    'delivery'
  ];

  totalRecords = 0;
  pageNumber = 0;
  readonly pageSize = 100;
  private lastKeys: string[] = [];

  status: InstructionStatus[] = [
    InstructionStatus.requested,
    InstructionStatus.withdrawn,
    InstructionStatus.confirmed,
    InstructionStatus.cancelled_by_patient,
    InstructionStatus.cancelled_by_pharmacist,
    InstructionStatus.declined,
    InstructionStatus.meeting_performed,
    InstructionStatus.meeting_completed,
    InstructionStatus.completed,
    InstructionStatus.incomplete,
    InstructionStatus.cancelled,
  ];
  statusFilter: boolean[] = [true, false, true, false, false, false, true, true, false, false, false];

  loading = true;

  notificationSubscription?: Subscription;
  reloadEventId: NodeJS.Timer | null = null;

  private get statusFilterString() {
    return this.statusFilter
      .reduce((acc: string, cur, index) => (cur ? acc + this.status[index] + ',' : acc), '')
      .slice(0, -1);
  }

  constructor(
    private readonly pciService: PciService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly pharmacistsService: PharmacistService,
    private readonly popupCheckboxService: PopupCheckboxService,
    private readonly reservationStatusPipe: ReservationStatusPipe,
    private readonly notificationService: NotificationService,
  ) { }

  async ngOnInit() {
    const init = async () => {
      this.loading = true;
      try {
        this.pharmacists = await this.fetchPharmacists();
        this.reservations = await this.fetchReservations(this.pharmacists, { status: this.statusFilterString });
      } finally {
        this.filterByName();
        this.loading = false;
      }
    };
    this.notificationSubscription = this.notificationService.messageReceive.subscribe(async _ => await init());
    await init();
    this.reloadEventId = setInterval(async () => {
      this.loading = true;
      try {
        this.pharmacists = await this.fetchPharmacists();
        this.reservations = await this.fetchReservations(this.pharmacists, {
          status: this.statusFilterString,
          last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
        });
        this.filterByName();
      } finally {
        this.loading = false;
      }
    }, 300000);
  }

  ngOnDestroy() {
    this.notificationSubscription?.unsubscribe();
    if (this.reloadEventId) {
      clearInterval(this.reloadEventId);
    }
    this.reloadEventId = null;
  }

  private fetchPharmacists() {
    return this.pharmacistsService.findAll();
  }

  private async fetchReservations(pharmacists: IPharmacist[], params?: { status?: string; last_key?: string }) {
    const data = await this.pciService.findAllWithPagination({ ...params, limit: this.pageSize });
    const [result, pagination] = [data.pcis, data.pagination];
    this.totalRecords = pagination.totalrecords;
    if (pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      this.lastKeys.push(pagination.last_key);
    }
    return result.map(r => new ReservationForList(r, pharmacists));
  }

  private filterByName() {
    const filters = this.nameFilter.trim().split(' ');
    this.reservationsDisabled = this.reservations.map(
      r =>
        !(
          (filters.length === 1 && !filters[0]) ||
          filters.every(f => r.pharmacistName.includes(f)) ||
          filters.every(f => r.patientName.includes(f))
        ),
    );
  }

  async filterByStatus() {
    this.statusFilter = await this.popupCheckboxService.showOverlay(
      this.statusHeader.elementRef,
      this.status.map(s => this.reservationStatusPipe.transform(s)),
      this.statusFilter,
    );
    try {
      this.loading = true;
      this.pageNumber = 0;
      this.lastKeys = [];
      this.reservations = await this.fetchReservations(this.pharmacists, { status: this.statusFilterString });
    } finally {
      this.filterByName();
      this.loading = false;
    }
  }

  openDetail(reservation: ReservationForList) {
    if (reservation.status === InstructionStatus.requested) {
      const dialogRef = this.dialog.open(ListDetailDialog, {
        data: { reservation },
        minWidth: 400,
      });
      dialogRef.afterClosed().subscribe(async result => {
        console.log(`Dialog result: ${result}`);
        if (result?.shouldReloadPcis) {
          this.loading = true;
          this.reservations = await this.fetchReservations(this.pharmacists, { status: this.statusFilterString });
          this.filterByName();
          this.loading = false;
        }
      });
    } else {
      this.router.navigate([`/pharmacist/reservation/${reservation.id}`]);
    }
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      this.reservations = await this.fetchReservations(this.pharmacists, {
        status: this.statusFilterString,
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
      this.filterByName();
    } finally {
      this.loading = false;
    }
  }

  async reloadEvent() {
    this.loading = true;
    this.pageNumber = 0;
    this.lastKeys = [];
    try {
      this.pharmacists = await this.fetchPharmacists();
      this.reservations = await this.fetchReservations(this.pharmacists, { status: this.statusFilterString });
    } finally {
      this.filterByName();
      this.loading = false;
    }
  }
}

@Component({
  selector: 'list-detail-dialog',
  templateUrl: './list-detail-dialog.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListDetailDialog implements OnInit {
  reservation: ReservationForList;

  patientName: string;
  timeFrames: TimeFrame[];

  loading = false;
  selectedTimeFrame = 0;

  confirmButtonLoading = false;
  declineButtonLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ListDetailDialog>,
    @Inject(MAT_DIALOG_DATA) data: { reservation: ReservationForList },
    public cognito: CognitoService,
    public pciService: PciService,
  ) {
    this.reservation = data.reservation;

    this.patientName = `${this.reservation.patientName}`;
    this.timeFrames = this.reservation.original.time_frames ?? [];
  }

  ngOnInit() { }

  async confirm() {
    const selectDate = format(new Date(this.timeFrames[this.selectedTimeFrame].start_time), 'yyyy年MM月dd日 HH:mm');
    const message = `${selectDate}からの予約を確定します。よろしいですか？`;
    if (confirm(message)) {
      try {
        this.confirmButtonLoading = true;
        const config = await this.getConfig();
        await this.pciService.confirm(this.reservation.id, this.selectedTimeFrame, config);
        this.dialogRef.close({ shouldReloadPcis: true });
      } finally {
        this.confirmButtonLoading = false;
      }
    }
  }

  async decline() {
    const message = `全ての予約をお断りします。よろしいですか？`;
    if (confirm(message)) {
      try {
        this.declineButtonLoading = true;
        const config = await this.getConfig();
        await this.pciService.decline(this.reservation.id, config);
        this.dialogRef.close({ shouldReloadPcis: true });
      } finally {
        this.declineButtonLoading = false;
      }
    }
  }

  async getConfig() {
    const config: AxiosRequestConfig = {};
    if (!config.headers) {
      config.headers = {};
    }
    const token = await this.cognito.getAccessToken();
    config.headers.Authorization = token.getJwtToken();
    config.headers['Content-Type'] = 'application/json';
    return config;
  }
}
