<div fxLayout="row" class="header">
  <h3 fxFlex mat-dialog-title>服薬指導を終了します。</h3>
  <!-- <div fxFlex="30%">
    <button class="closeButton" mat-button (click)="close()">閉じる</button>
  </div> -->
</div>
<div class="container" mat-dialog-content>服薬指導を終了すると、再接続はできません。よろしいですか？</div>
<div mat-dialog-actions class="button-area">
  <button mat-button (click)="endMeeting('completed')">はい、服薬指導を終了します。</button>
  <button mat-button (click)="endMeeting()">いいえ、服薬指導を再開する予定です。</button>
</div>
