<loading-spinner [loading]="loading"></loading-spinner>
<div class="root" *ngIf="!loading" fxLayout="column">
  <h1>支払・配送状況詳細</h1>
  <mat-accordion fxFlexOffset="20px" *ngIf="patientInfo !== undefined">
    <app-patient-info-accordion [patientInfo]="patientInfo!" [isExpanded]="false"></app-patient-info-accordion>
  </mat-accordion>
  <div fxLayout="row">
    <div fxLayout="column" class="settlement-frame">
      <div class="settlement-operations" fxLayout="row-reverse" fxLayoutGap="10px">
        <button class="round-button" *ngIf="canCancel" mat-raised-button color="warn" (click)="cancel()">
          <mat-icon>cancel</mat-icon>
          決済のキャンセル
        </button>
        <button class="round-button" *ngIf="canUnSettle" mat-raised-button color="note" (click)="unsettle()">
          未決済に変更
        </button>
        <button class="round-button" *ngIf="canSettle" mat-raised-button color="primary" (click)="settle()">
          決済済みに変更
        </button>
      </div>
      <div class="settlement-detail-display">
        <div class="status-section" fxLayout="row">
          <div class="status-section-title" fxFlex="50">決済状況</div>
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="center">
            <div>{{ payment?.status | payment_status }}</div>
          </div>
        </div>
        <div class="timestamps-section" fxLayout="column">
          <div fxFlexAlign="center" class="progress-bar-wrap">
            <div class="progress-bar">
              <div
                class="progress-bar-base"
                [class.error-state]="payment?.status === 'cancelled' || payment?.status === 'failed'"
              ></div>
              <div
                class="progress-bar-progress"
                [style.width]="(payment?.status | payment_progress) + '%'"
                [class.error-state]="payment?.status === 'cancelled' || payment?.status === 'failed'"
              ></div>
            </div>
          </div>
          <div fxFlexAlign="center" class="settlement-log" fxLayout="row">
            <div fxFlex="25">
              <div class="creation-time" fxLayout="column" fxLayoutAlign="center center">
                <div>{{ payment?.created_at | date: 'yyyy/MM/dd' }}</div>
                <div>{{ payment?.created_at | date: 'HH:mm:ss' }}</div>
                <div>作成</div>
              </div>
            </div>
            <div fxFlex="12.5"></div>
            <div fxFlex="25">
              <div
                *ngIf="payment?.status === 'completed' && payment?.payment_dates?.payment_processed_date"
                class="processed-time"
                fxLayout="column"
                fxLayoutAlign="center center"
              >
                <div>{{ payment?.payment_dates?.payment_processed_date | date: 'yyyy/MM/dd' }}</div>
                <div>{{ payment?.payment_dates?.payment_processed_date | date: 'HH:mm:ss' }}</div>
                <div>患者支払い</div>
              </div>
            </div>
            <div fxFlex="12.5"></div>
            <div fxFlex="25">
              <div
                *ngIf="payment?.status === 'completed' && payment?.payment_completed_date"
                class="completed-time"
                fxLayout="column"
                fxLayoutAlign="center center"
              >
                <div>{{ payment?.payment_completed_date | date: 'yyyy/MM/dd' }}</div>
                <div>{{ payment?.payment_completed_date | date: 'HH:mm:ss' }}</div>
                <div>決済完了</div>
              </div>
              <div
                *ngIf="payment?.status === 'cancelled' && payment?.payment_dates?.payment_cancelled_date"
                class="cancelled-time"
                fxLayout="column"
                fxLayoutAlign="center center"
              >
                <div>{{ payment?.payment_dates?.payment_cancelled_date | date: 'yyyy/MM/dd' }}</div>
                <div>{{ payment?.payment_dates?.payment_cancelled_date | date: 'HH:mm:ss' }}</div>
                <div>キャンセル</div>
              </div>
              <div
                *ngIf="payment?.status === 'failed' && payment?.payment_dates?.payment_failed_date"
                class="failed-time"
                fxLayout="column"
                fxLayoutAlign="center center"
              >
                <div>{{ payment?.payment_dates?.payment_failed_date | date: 'yyyy/MM/dd' }}</div>
                <div>{{ payment?.payment_dates?.payment_failed_date | date: 'HH:mm:ss' }}</div>
                <div>決済失敗</div>
              </div>
            </div>
          </div>
        </div>
        <div class="amount-section" fxLayout="column">
          <div class="amount-section-title">料金</div>
          <div class="amount-section-detail" fxFlexAlign="center" fxLayout="column">
            <div fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="space-between">
              <div>保険適用内料金</div>
              <div>{{ numberWithComma(payment?.settlement_amount?.insurance_covered_price) }}</div>
            </div>
            <div fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="space-between">
              <div>保険適用外料金</div>
              <div>{{ numberWithComma(payment?.settlement_amount?.insurance_uncovered_price) }}</div>
            </div>
            <!--
            <div fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="space-between">
              <div>その他料金</div>
              <div>{{ numberWithComma(payment?.settlement_amount?.other_price) }}</div>
            </div>
            -->
            <div fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="space-between">
              <div>送料</div>
              <div>{{ numberWithComma(payment?.settlement_amount?.shipping_fee) }}</div>
            </div>
            <div class="total-amount-row" fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="space-between">
              <div>合計</div>
              <div>{{ numberWithComma(totalSettlementAmount) }}</div>
            </div>
          </div>
        </div>
        <div class="settlement-method-section" fxLayout="row">
          <div class="settlement-method-section-title" fxFlex="50">支払方法</div>
          <div fxFlex="50" fxLayout="row">
            <div
              fxFlex="50"
              class="text-centering"
              [class.selected-method]="payment?.settlement_method === 'credit_card'"
            >
              クレジットカード
            </div>
            <div fxFlex="50" class="text-centering" [class.selected-method]="payment?.settlement_method === 'others'">
              その他
            </div>
          </div>
        </div>
        <div class="due-section" fxLayout="row">
          <div class="due-section-title" fxFlex="50">支払期限日時</div>
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="center">
            <div>{{ payment?.payment_dates?.payment_due_date | date: 'yyyy/MM/dd HH:mm' }}</div>
          </div>
        </div>
        <!--
        <div class="note-section" fxLayout="column">
          <div class="note-section-title">メモ</div>
          <div fxFlexAlign="center" class="note-area">{{ payment?.memo ? payment?.memo : '( なし )' }}</div>
        </div>
        -->
      </div>
    </div>
    <div fxLayout="column" class="settlement-frame">
      <div class="settlement-operations" fxLayout="row-reverse" fxLayoutGap="10px" *ngIf="delivery">
        <button
          class="round-button"
          *ngIf="delivery.status === 'unshipped'"
          mat-raised-button
          color="primary"
          (click)="shipped()"
        >
          <mat-icon>local_shipping</mat-icon>
          交付済みにする
        </button>
        <button
          class="round-button"
          *ngIf="delivery.status === 'shipped'"
          mat-raised-button
          color="warn"
          (click)="unshipped()"
        >
          <mat-icon>cancel</mat-icon>
          未交付にする
        </button>
      </div>

      <div class="settlement-detail-display" *ngIf="delivery">
        <div class="status-section" fxLayout="row">
          <div class="status-section-title" fxFlex="50">配送状況</div>
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="center">
            <div>{{ deliveryStatusText }}</div>
          </div>
        </div>
        <div class="settlement-method-section" fxLayout="row">
          <div class="settlement-method-section-title" fxFlex="50">配送方法</div>
          <div fxFlex="50" fxLayout="row">
            <div fxFlex="50" class="text-centering" [class.selected-method]="delivery?.method === 'normal'">
              通常配送
            </div>
            <div
              fxFlex="50"
              class="text-centering"
              [class.selected-method]="delivery?.method === 'collect_on_delivery'"
              *ngIf="delivery?.method !== 'cash_on_delivery'"
            >
              着払い配送
            </div>
            <div
              fxFlex="50"
              class="text-centering"
              [class.selected-method]="delivery?.method === 'cash_on_delivery'"
              *ngIf="delivery?.method === 'cash_on_delivery'"
            >
              代金引換
            </div>
            <div fxFlex="50" class="text-centering" [class.selected-method]="delivery?.method === 'drop_by'">
              店頭受取
            </div>
          </div>
        </div>
        <div class="due-section" fxLayout="row">
          <div class="due-section-title" fxFlex="50">配送方法決定日時</div>
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="center">
            <div>{{ pci.original.delivery_method_created_at | date: 'yyyy/MM/dd HH:mm' }}</div>
          </div>
        </div>
        <div class="due-section" fxLayout="row">
          <div class="due-section-title" fxFlex="50">配送引き渡し日時</div>
          <div fxFlex="50" fxLayout="row" fxLayoutAlign="center">
            <div>{{ delivery?.shipped_at | date: 'yyyy/MM/dd HH:mm' }}</div>
          </div>
        </div>

        <!--
        <div class="note-section" fxLayout="column">
          <div class="note-section-title">メモ(薬局用)</div>
          <div fxFlexAlign="center" class="note-area">
            {{ delivery?.memo_for_pharmacist ? delivery?.memo_for_pharmacist : '( なし )' }}
          </div>
        </div>
        -->

        <!--
        <div class="note-section" fxLayout="column">
          <div class="note-section-title">患者へのメッセージ</div>
          <div fxFlexAlign="center" class="note-area">
            {{ delivery?.message_to_patient ? delivery?.message_to_patient : '( なし )' }}
          </div>
        </div>
        -->
      </div>
    </div>
  </div>

  <!-- <div class="settlement-detail-display" *ngIf="!delivery">
    <mat-form-field class="form-field">
      <div class="status-section-title" fxFlex="50">配送方法</div>
      <mat-select [(value)]="deliveryMethodSelection" matNativeControl required>
        <mat-option value="normal">通常配送</mat-option>
        <mat-option value="cash_on_delivery">代金引換</mat-option>
        <mat-option value="drop_by">店舗受取</mat-option>
      </mat-select
    </mat-form-field>
  </div> -->
</div>
