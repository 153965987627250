import { Component, Input, OnInit } from '@angular/core';
import { IPatientInfo } from 'src/models';

@Component({
  selector: 'app-patient-info-accordion',
  templateUrl: './patient-info-accordion.component.html',
  styleUrls: ['./patient-info-accordion.component.scss'],
})
export class PatientInfoAccordionComponent implements OnInit {
  @Input() patientInfo?: IPatientInfo;
  @Input() isExpanded?: boolean;

  get patientName() {
    return (this.patientInfo?.family_name ?? '') + ' ' + (this.patientInfo?.given_name ?? '');
  }

  get patientNameKana() {
    return (this.patientInfo?.family_name_kana ?? '') + ' ' + (this.patientInfo?.given_name_kana ?? '');
  }

  constructor() {}

  async ngOnInit() {}
}
