import { Component, OnInit } from '@angular/core';
import { when } from 'src/app/modules/when';

@Component({
  selector: 'app-reservation-list',
  templateUrl: './reservation-list.component.html',
  styleUrls: ['./reservation-list.component.scss'],
})
export class ReservationListComponent implements OnInit {
  viewType: 'calendar' | 'list' = this.getViewType();
  name = '';

  constructor() {}

  ngOnInit() {}

  getViewType(): 'calendar' | 'list' {
    const viewType = localStorage.getItem('reservation-list-view-type');
    return when(viewType)
      .on<'calendar' | 'list'>(
        t => t === 'calendar' || t === 'list',
        t => t as 'calendar' | 'list',
      )
      .otherwise(_ => 'calendar');
  }

  toggleViewType(newValue: 'calendar' | 'list') {
    this.viewType = newValue;
    localStorage.setItem('reservation-list-view-type', this.viewType);
  }
}
