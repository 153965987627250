<div class="root">
  <div fxLayout="column" fxLayoutGap="20px">
    <div
      class="top-area"
      fxLayout.lt-md="column"
      fxLayoutGap.lt-md="20px"
      fxLayout="row-reverse"
      fxLayoutAlign="space-between"
    >
      <div fxLayout="row-reverse" fxLayoutGap="10px">
        <loading-button
          [name]="'保存'"
          [disabled]="loading || !templateNameFormControl.valid || !groupNameFormControl.valid"
          color="primary"
          [loading]="saving"
          (click)="saveTemplate()"
          icon="save"
        ></loading-button>
        <loading-button
          *ngIf="templateId !== 'new-template'"
          [name]="'削除'"
          [disabled]="loading"
          color="warn"
          [loading]="removing"
          (click)="deleteTemplate()"
          icon="delete"
        ></loading-button>
      </div>
      <div fxLayout.lt-sm="column" fxLayoutGap.lt-sm="10px" fxLayout="row" fxLayoutGap="20px">
        <mat-form-field>
          <mat-label>グループ名</mat-label>
          <input class="input" matInput [formControl]="groupNameFormControl" readonly />
          <mat-error *ngIf="groupNameFormControl.hasError('pattern')">
            グループ名に \ 記号を含めることはできません
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>テンプレート名</mat-label>
          <input
            class="input pci-template-name-input"
            matInput
            [formControl]="templateNameFormControl"
            [readonly]="loading"
          />
          <mat-error *ngIf="templateNameFormControl.hasError('required')"
            >テンプレート名は<strong>必須</strong>です。</mat-error
          >
          <mat-error *ngIf="templateNameFormControl.hasError('pattern')">
            テンプレート名に \ 記号を含めることはできません
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <app-questionnaire-editor
      [(questions)]="questions"
      [disabled]="loading"
      [isVisibleRequiredCheckBox]="true"
    ></app-questionnaire-editor>
  </div>
</div>
