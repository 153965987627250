import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { loginSessions } from 'src/app/app-store/actions/session.actions';
import { CognitoService } from 'src/services/cognito.service';

@Component({
  selector: 'pharmacy-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  userId = '';
  password = '';

  constructor(private store: Store, private router: Router, private cognito: CognitoService) {}

  async ngOnInit() {
    const isLogin = await this.cognito.isAuthenticated();
    if (isLogin) {
      this.router.navigate(['/pharmacy/index']);
    }
  }

  login() {
    this.store.dispatch(loginSessions({ email: this.userId, password: this.password, type: 'pharmacy' }));
  }
}
