import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-pharmacist-password-change',
  templateUrl: './pharmacist-password-change.component.html',
  styleUrls: ['./pharmacist-password-change.component.scss'],
})
export class PharmacistPasswordChangeComponent implements OnInit {
  currentPassword = '';
  newPasswordFormControl1 = new FormControl('', [
    Validators.required,
    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{8,}$'),
  ]);
  newPasswordFormControl2 = new FormControl('');
  readonly newPasswordGroup = new FormGroup(
    {
      password1: this.newPasswordFormControl1,
      password2: this.newPasswordFormControl2,
    },
    {
      validators: (ac: AbstractControl) => {
        if (ac.get('password1')?.value === ac.get('password2')?.value) {
          ac.get('password2')?.setErrors(null);
        } else {
          ac.get('password2')?.setErrors({ notMatch: true });
        }
        return null;
      },
    },
  );
  loading = true;

  get areAllFormsValid() {
    return this.newPasswordGroup.valid;
  }

  constructor(private router: Router) {}

  ngOnInit() {
    this.loading = false;
  }

  async changePassword() {
    if (!confirm('パスワードを変更しますか？')) {
      return;
    }
    this.loading = true;
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, this.currentPassword, this.newPasswordFormControl1.value as string);
      alert('パスワードが変更されました。');
      this.router.navigate(['/pharmacist/index']);
    } catch (error) {
      alert('パスワードを変更できませんでした。');
      console.log(error);
    } finally {
      this.loading = false;
    }
  }
}
