import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';
import { NewArrival, NewArrivalService } from 'src/services/new-arrival.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit {
  collapsed = false;
  sidenavClosing = false;
  @Input('collapsed') set setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed;
  }
  @Input('screenWidth') set screenWidth(width: 'small' | 'medium' | 'large') {
    if (width === 'small') {
      this.sidenavClosing = true;
      setTimeout(() => (this.sidenavClosing = false), 500);
    }
  }
  @Output() toggle = new EventEmitter<void>();
  @Output() menuSelect = new EventEmitter<void>();
  expanded = false;
  type?: 'pharmacy' | 'pharmacist';
  items: {
    type: 'pharmacy' | 'pharmacist';
    href: string;
    title: [string, string?];
    icon?: string;
    current: boolean;
  }[] = [
    {
      type: 'pharmacy',
      href: '/pharmacy/pharmacists',
      title: ['薬剤師一覧'],
      icon: 'people',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/reservation-slots',
      title: ['オンライン服薬指導', '予約可能枠'],
      icon: 'event_note',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/pci-templates',
      title: ['服薬指導フォーマット'],
      icon: 'question_answer',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/payment_setting',
      title: ['支払設定'],
      icon: 'payment',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/medical-questionnaire-template',
      title: ['問診票テンプレート'],
      icon: 'quiz',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/pcf-templates',
      title: ['服薬フォロー', 'テンプレート'],
      icon: 'article',
      current: false,
    },
    // {
    //   type: 'pharmacy',
    //   href: '/pharmacy/cbf-templates',
    //   title: ['チャットボット', '服薬フォローテンプレート'],
    //   icon: 'android',
    // },
    {
      type: 'pharmacy',
      href: '/pharmacy/profile',
      title: ['薬局情報編集'],
      icon: 'manage_accounts',
      current: false,
    },
    {
      type: 'pharmacy',
      href: '/pharmacy/change-password',
      title: ['パスワード変更'],
      icon: 'lock',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/patients',
      title: ['患者情報一覧'],
      icon: 'assignment_ind',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/reservation',
      title: ['服薬指導予約一覧'],
      icon: 'event_note',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/payments',
      title: ['支払情報一覧'],
      icon: 'payment',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/prescriptions',
      title: ['受信処方箋画像一覧'],
      icon: 'receipt',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/follow',
      title: ['服薬フォロー一覧'],
      icon: 'question_answer',
      current: false,
    },
    {
      type: 'pharmacist',
      href: '/pharmacist/change-password',
      title: ['パスワード変更'],
      icon: 'lock',
      current: false,
    },
  ];
  noticesSubscription: Subscription;
  notices: NewArrival[] = [];
  previousNotices: NewArrival[] = [];
  noticesNum: { prescription: number; pci: number; pcf: number } = { prescription: 0, pci: 0, pcf: 0 };

  constructor(private store: Store, private router: Router, private readonly newArrivalService: NewArrivalService) {
    this.noticesSubscription = this.newArrivalService.newArrivals.subscribe(n => {
      this.previousNotices = this.notices;
      this.notices = n;
      this.noticesNum = { prescription: 0, pci: 0, pcf: 0 };
      this.notices.forEach(notice => {
        if (notice.type === 'pci') {
          this.noticesNum.pci += 1;
        }
        if (notice.type === 'pcf') {
          this.noticesNum.pcf += 1;
        }
        if (notice.type === 'prescription') {
          this.noticesNum.prescription += 1;
        }
      });
    });
  }

  ngOnInit(): void {
    getLoginSession(this.store).subscribe(s => (this.type = s.type));
    this.router.events.pipe(filter(f => f instanceof NavigationEnd)).subscribe((s: any) => {
      for (const item of this.items) {
        const arr = item.href.split('/').filter(e => e);
        if (arr.every(e => s.url.includes(e))) {
          item.current = true;
        } else {
          item.current = false;
        }
      }
    });
  }

  emitToggleEvent() {
    this.toggle.emit();
  }

  emitMenuSelectEvent() {
    this.menuSelect.emit();
  }

  badgeLength(item: {
    type: 'pharmacy' | 'pharmacist';
    href: string;
    title: [string, string?];
    icon?: string;
    current: boolean;
  }) {
    if (item.title[0] === '受信処方箋画像一覧') {
      return this.noticesNum.prescription;
    }
    if (item.title[0] === '服薬指導予約一覧') {
      return this.noticesNum.pci;
    }
    if (item.title[0] === '服薬フォロー一覧') {
      return this.noticesNum.pcf;
    }
    return 0;
  }

  get filteredItems() {
    return this.items.filter(i => i.type === this.type);
  }
}
