import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { GmoSetting } from 'src/models/gmo-setting';
import { PaymentSettingService } from 'src/services/api/payment-setting.service';

@Component({
  selector: 'app-payment-setting',
  templateUrl: './payment-setting.component.html',
  styleUrls: ['./payment-setting.component.scss'],
})
export class PaymentSettingComponent implements OnInit {
  readonly idFormControl = new FormControl('', [Validators.required]);
  readonly passwordFormControl = new FormControl('', [Validators.required]);
  readonly configIdFormControl = new FormControl('', [Validators.required]);

  constructor(private paymentSettingService: PaymentSettingService, private readonly store: Store) {}

  async ngOnInit() {
    const config = await this.getSetting();
    this.idFormControl.setValue(config.gmo_payment_id);
    this.passwordFormControl.setValue(config.gmo_payment_password);
    this.configIdFormControl.setValue(config.gmo_payment_config_id);
  }

  getSetting(): Promise<GmoSetting> {
    return this.paymentSettingService.getSetting();
  }

  async updateSetting() {
    const pharmacy = await getLoginSession(this.store)
      .pipe(
        filter(isNotNull),
        first(),
        map(s => s.pharmacy ?? undefined),
      )
      .toPromise();
    if (!pharmacy) {
      console.log('薬局ログイン情報がみつかりませんでした');
      return;
    }
    return await this.paymentSettingService.simplePut({
      pharmacy_id: pharmacy.id,
      gmo_payment_id: this.idFormControl.value,
      gmo_payment_password: this.passwordFormControl.value,
      gmo_payment_config_id: this.configIdFormControl.value,
    });
  }
}
