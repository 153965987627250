import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { PatientSex } from 'src/models/patient-info';

@Pipe({ name: 'zip_code' })
export class ZipCodePipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return value.slice(0,3) + "-" + value.slice(3)
  }
}
