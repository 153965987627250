import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { configure } from 'src/services/cognito.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  async canActivate(): Promise<boolean> {
    configure('pharmacist');
    const pharmacistCognitoUser = await Auth.currentAuthenticatedUser().catch(_ => null);
    if (pharmacistCognitoUser) {
      this.router.navigate(['pharmacist/index']);
      return false;
    }
    configure('pharmacy');
    const pharmacyCognitoUser = await Auth.currentAuthenticatedUser().catch(_ => null);
    if (pharmacyCognitoUser) {
      this.router.navigate(['pharmacy/index']);
      return false;
    }
    return true;
  }
}
