import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from 'src/environments/environment';
import { Payment } from 'src/models/payment';
import { CognitoService } from '../cognito.service';
import { ServiceBase } from '../service-base';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends ServiceBase<Payment> {
  readonly propertyName = 'payments';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'payments');
  }

  public findAll(params?: { status?: string; patient_info_id?: string; pci_id?: string }): Promise<Payment[]> {
    return super.findAll(params);
  }

  public findAllWithPagination(params?: { limit?: number; last_key?: string; pci_id?: string; status?: string; payment_method?: string, delivery_method?: string, sort_key?: string, sort_order?: string }) {
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as Payment[],
      pagination: result.pagination,
    }));
  }

  public async settle(paymentId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/payments/${paymentId}/settled`,
      undefined,
      await this.buildConfig(config, 'application/json'),
    );
  }

  public async unsettle(paymentId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/payments/${paymentId}/unsettled`,
      undefined,
      await this.buildConfig(config, 'application/json'),
    );
  }

  public async cancel(paymentId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/payments/${paymentId}/cancel`,
      undefined,
      await this.buildConfig(config, 'application/json'),
    );
  }
}
