import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { delay, filter, first, map, startWith } from 'rxjs/operators';
import { getLoading } from './app-store/reducers';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { getLoginSession } from './modules/storeModules';
import { environment } from 'src/environments/environment';
import { when } from './modules/when';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  loading$ = this.store.select<boolean>(getLoading as any);
  screenWidth$: Observable<'small' | 'medium' | 'large'>;
  sidenavOpen$: Observable<boolean>;
  sidenavExpanded = false;
  sidenavPreferOpen = localStorage.getItem('sidenavPreferOpen') === 'true' ?? true;
  sidenavRequestOpen$ = new BehaviorSubject<boolean>(false);
  sidenavMode$: Observable<'side' | 'over' | 'push'>;
  sidenavBackdrop$: Observable<boolean>;
  sidenavCollapse$: Observable<boolean>;
  isSmallScreen$: Observable<boolean> = of(false);
  loginType$: Observable<'pharmacist' | 'pharmacy' | undefined> = of(undefined);
  loginTypeAndId$: Observable<string> = of('');
  resizeCallbackTimeout: NodeJS.Timeout = setTimeout(() => { }, 0);
  openSidenav$: Observable<boolean> = of(false);
  displayHamburger$: Observable<boolean> = of(false);
  navigationEndSubscription: Subscription;
  private titleSuffix = environment.title;
  private _title = '';
  @HostListener('window:resize') onResize() {
    clearTimeout(this.resizeCallbackTimeout);
    this.resizeCallbackTimeout = setTimeout(async () => {
      const width = await this.screenWidth$.pipe(first()).toPromise();
      if (width === 'medium' || width === 'small') {
        this.closeSidenav();
      } else if (this.sidenavPreferOpen) {
        this.openSidenav();
      }
    }, 25);
  }

  get title(): string {
    return this._title ? this.titleSuffix : `${this._title} | ${this.titleSuffix}`;
  }

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private breakPointObserver: BreakpointObserver,
    private windowTitle: Title,
  ) {
    this.windowTitle.setTitle(environment.windowTitle);
    const session$ = getLoginSession(this.store);
    this.loginType$ = session$.pipe(map(s => s.type));
    this.loginTypeAndId$ = session$.pipe(
      map(s => (s.pharmacist ? '-pharmacist-' + s.pharmacist.id : s.pharmacy ? '-pharmacy-' + s.pharmacy.id : '')),
    );
    this.loginTypeAndId$.subscribe(id => {
      this.sidenavPreferOpen = localStorage.getItem('sidenavPreferOpen' + id) === 'true' ?? true;
    });
    this.isSmallScreen$ = this.breakPointObserver.observe(['(max-width: 1500px)']).pipe(map(state => state.matches));
    this.screenWidth$ = this.breakPointObserver
      .observe(['(max-width: 650px)', '(max-width: 1500px)'])
      .pipe(
        map(state =>
          state.breakpoints['(max-width: 650px)']
            ? 'small'
            : state.breakpoints['(max-width: 1500px)']
              ? 'medium'
              : 'large',
        ),
      );
    this.sidenavBackdrop$ = this.screenWidth$.pipe(map(w => w === 'small'));
    this.sidenavOpen$ = combineLatest([this.screenWidth$, this.sidenavRequestOpen$, this.loginType$]).pipe(
      map(([w, r, l]) =>
        when(w)
          .on(
            v => v === 'small',
            _ => r && !!l,
          )
          .otherwise(_ => {
            return !!l;
          }),
      ),
    );
    this.sidenavMode$ = combineLatest([this.screenWidth$, this.sidenavRequestOpen$]).pipe(
      map(([w, r]) =>
        when(w)
          .on<'over'>(
            v => v === 'small',
            _ => 'over',
          )
          .on<'over' | 'side'>(
            v => v === 'medium',
            _ => (r ? 'over' : 'side'),
          )
          .otherwise<'side'>(_ => 'side'),
      ),
    );
    this.sidenavCollapse$ = combineLatest([this.screenWidth$, this.sidenavRequestOpen$]).pipe(
      delay(10),
      map(([w, r]) =>
        when(w)
          .on(
            v => v === 'small',
            _ => false,
          )
          .otherwise(_ => !r),
      ),
    );
    this.displayHamburger$ = combineLatest([this.screenWidth$, this.sidenavRequestOpen$]).pipe(
      map(([w, r]) => w === 'small' && !r),
    );
    const appTitle = this.titleService.getTitle();
    this.navigationEndSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute.firstChild?.snapshot.data.title ?? appTitle),
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
        this._title = title;
      });
  }

  async ngOnInit() {
    // prettier-ignore
    if (this.sidenavPreferOpen && await this.screenWidth$.pipe(first(), map(w => w === 'large'))) {
      this.closeSidenav();
    }
  }

  ngOnDestroy() {
    this.navigationEndSubscription.unsubscribe();
  }

  async toggleSidenav() {
    const width = await this.screenWidth$.pipe(first()).toPromise();
    this.sidenavRequestOpen$.next(!this.sidenavRequestOpen$.value);
    if (width === 'large') {
      this.sidenavPreferOpen = this.sidenavRequestOpen$.value;
      const id = await this.loginTypeAndId$.pipe(first()).toPromise();
      if (id) {
        localStorage.setItem('sidenavPreferOpen' + id, this.sidenavPreferOpen.toString());
      }
    }
  }

  onBackdropClick() {
    this.closeSidenav();
  }

  openSidenav() {
    this.sidenavRequestOpen$.next(true);
  }

  closeSidenav() {
    this.sidenavRequestOpen$.next(false);
  }

  async onMenuSelect() {
    const mode = await this.sidenavMode$.pipe(first()).toPromise();
    if (mode === 'over') {
      this.closeSidenav();
    }
  }
}
