<div class="root">
  <h1>決済機能設定</h1>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field class="form-field">
        <mat-label>GMO Payment ショップID</mat-label>
        <input matInput type="text" [formControl]="idFormControl" required />
        <mat-error *ngIf="idFormControl.hasError('required')">ショップIDは<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>GMO Paymentショップパスワード</mat-label>
        <input matInput type="text" [formControl]="passwordFormControl" required />
        <mat-error *ngIf="passwordFormControl.hasError('required')"
          >ショップパスワードは<strong>必須</strong>です</mat-error
        >
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>GMO Payment Config ID</mat-label>
        <input matInput type="text" [formControl]="configIdFormControl" required />
        <mat-error *ngIf="configIdFormControl.hasError('required')"
          >Payment Config IDは<strong>必須</strong>です</mat-error
        >
      </mat-form-field>

      <div>
        <loading-button [name]="'変更を保存'" color="primary" (click)="updateSetting()" icon="save"></loading-button>
      </div>
    </div>
  </div>
</div>
