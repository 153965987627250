<div class="patient-list">
  <h1>患者情報一覧</h1>
  <div class="patient-search">
    <mat-form-field>
      <mat-label>患者名または生年月日で検索</mat-label>
      <input matInput type="text" [formControl]="nameOrBirthdayFormControl" />
    </mat-form-field>
  </div>

  <div class="table-container">
    <div class="paginator-and-table">
      <div class="paginator-wrapper">
        <app-custom-paginator
          class="paginator"
          [disabled]="loading"
          [length]="totalRecords"
          [pageSize]="pageSize"
          [pageIndex]="pageNumber"
          (page)="pageEvent($event)"
          (reload)="reloadEvent()"
        ></app-custom-paginator>
        <div class="paginator-overlay"></div>
      </div>
      <table
        mat-table
        [dataSource]="filteredPatients.slice(pageNumber * pageSize, (1 + pageNumber) * pageSize)"
        matSort
        matSortDisableClear="true"
        (matSortChange)="sortData($event)"
        matSortActive="activated_date"
        matSortDirection="asc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <th class="patient-name" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="name">
            名前
          </th>
          <td class="patient-name" mat-cell *matCellDef="let element">{{ getPatientName(element) }}</td>
        </ng-container>

        <ng-container matColumnDef="birth_date">
          <th mat-header-cell *matHeaderCellDef>生年月日</th>
          <td mat-cell *matCellDef="let element">{{ element.patient_info.birth_date | date: 'yyyy/MM/dd' }}</td>
        </ng-container>

        <ng-container matColumnDef="sex">
          <th mat-header-cell *matHeaderCellDef>性別</th>
          <td mat-cell *matCellDef="let element">
            {{ element.patient_info.sex | patient_sex }}
          </td>
        </ng-container>

        <ng-container matColumnDef="deactivated">
          <th
            #favoriteHeader="cdkOverlayOrigin"
            cdk-overlay-origin
            mat-header-cell
            *matHeaderCellDef
            [style.cursor]="'pointer'"
            (click)="filterByFavorite()"
          >
            お気に入り
            <mat-icon>filter_alt</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="deactivated" *ngIf="element.deactivated_at !== null">解除済み</span>
            <span disabled *ngIf="element.deactivated_at === null">登録</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="activated_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="activated_at">登録日</th>
          <td mat-cell *matCellDef="let element">{{ element.activated_at | date: 'yyyy/MM/dd' }}</td>
        </ng-container>

        <ng-container matColumnDef="deactivated_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="deactivated_at">解除日</th>
          <td mat-cell *matCellDef="let element">{{ element.deactivated_at | date: 'yyyy/MM/dd' }}</td>
        </ng-container>

        <ng-container matColumnDef="alarm_exist">
          <th mat-header-cell *matHeaderCellDef>服ア</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.medicine_upload_at">
              〇
            </ng-container>
            <span style="color:red;" *ngIf="isMedicineNew(element)">New</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          [class]="row.deactivated_at ? 'deactivated' : 'active'"
          (click)="openPatientDetails(row)"
          routerLink="/pharmacist/patients/{{ row.patient_info.id }}"
          disabled
          *matRowDef="let row; columns: displayedColumns; index as i"
        ></tr>
      </table>
    </div>
    <div *ngIf="loading" class="loading-shade">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
