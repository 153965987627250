<div class="sidebar-content-root" fxLayout="column">
  <div
    class="title-button"
    fxLayout="row"
    fxLayoutAlign="center"
    matTooltip="トップ画面"
    matTooltipPosition="right"
    matTooltipShowDelay="250"
    matTooltipClass="sidenav-tooltip"
    [matTooltipDisabled]="!(collapsed || sidenavClosing)"
    [routerLink]="type === 'pharmacist' ? '/pharmacist/index' : type === 'pharmacy' ? '/pharmacy/index' : '/index'"
    (click)="emitMenuSelectEvent()"
  >
    <mat-icon class="sidenav-icon" ffxFlexAlign="center" *ngIf="collapsed || sidenavClosing">home</mat-icon>
    <div fxFlexAlign="center" *ngIf="!(collapsed || sidenavClosing)" class="title-text">Connect Online</div>
  </div>
  <mat-divider></mat-divider>
  <div
    *ngFor="let item of filteredItems; index as i"
    class="link-button"
    [class]="item.current ? 'current' : ''"
    routerLink="{{ item.href }}"
    fxLayout="row"
    [matTooltip]="item.title[0] + (item.title[1] ? item.title[1] : '')"
    matTooltipPosition="right"
    matTooltipShowDelay="250"
    matTooltipClass="sidenav-tooltip"
    [matTooltipDisabled]="!(collapsed || sidenavClosing)"
    [fxFlexOffset]="!i ? '30px' : '0px'"
    (click)="emitMenuSelectEvent()"
  >
    <div [fxFlex]="collapsed || sidenavClosing ? 100 : 20" fxLayout="row" fxLayoutAlign="center">
      <mat-icon
        class="sidenav-icon"
        fxFlexAlign="center"
        [matBadge]="badgeLength(item)"
        [matBadgeColor]="'warn'"
        [matBadgeHidden]="badgeLength(item) === 0"
        >{{ item.icon ? item.icon : 'home' }}</mat-icon
      >
    </div>
    <div
      fxFlex="80"
      *ngIf="!(collapsed || sidenavClosing)"
      class="{{ item.title[1] ? 'double-row' : 'single-row' }} label"
    >
      <div fxLayout="row">
        <div class="sidenav-text" *ngFor="let letter of item.title[0].split('')">{{ letter }}</div>
      </div>
      <div fxLayout="row" *ngIf="item.title[1]">
        <div class="sidenav-text" *ngFor="let letter of item.title[1].split('')">{{ letter }}</div>
      </div>
    </div>
  </div>
  <div fxFlex fxLayout="column-reverse">
    <div class="toggle-button" fxLayout="row" fxLayoutAlign="center" (click)="emitToggleEvent()">
      <div
        [fxFlex]="collapsed || sidenavClosing ? 100 : 20"
        fxLayout="row"
        fxLayoutAlign="center"
        matTooltip="サイドメニューを開く"
        matTooltipPosition="right"
        matTooltipShowDelay="250"
        matTooltipClass="sidenav-tooltip"
        [matTooltipDisabled]="!(collapsed || sidenavClosing)"
      >
        <mat-icon
          fxFlexAlign="center"
          [style.transform]="collapsed || sidenavClosing ? 'rotate(0deg)' : 'rotate(180deg)'"
        >
          double_arrow
        </mat-icon>
      </div>
      <div
        class="sidenav-close"
        fxFlexAlign="center"
        fxFlex="80"
        fxLayout="row"
        fxLayoutAlign="center"
        *ngIf="!(collapsed || sidenavClosing)"
      >
        <div class="sidenav-text">閉じる</div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="!(collapsed || sidenavClosing)" class="sidenav-width-adjuster"></div>
  </div>
</div>
