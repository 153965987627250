<loading-spinner [loading]="loading"></loading-spinner>
<div fxLayout="column" *ngIf="!loading">
  <div>
    <table class="table" mat-table [dataSource]="pciTemplates">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        (click)="openDetailDialog(row)"
        *matRowDef="let row; columns: displayedColumns"
        class="clickable-row"
      ></tr>
    </table>
  </div>
</div>
