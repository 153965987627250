<div class="chat-area">
  <div class="pre-wrap" id="chat">
    <div *ngFor="let post of posts; index as i" class="post-wrapper">
      <p class="post" [class]="post.isSelf ? 'self' : 'patient'" [id]="'chat-' + i">
        <span>{{ post.message }}</span>
      </p>
    </div>
  </div>
  <div class="form-field">
    <mat-form-field>
      <input
        type="text"
        matInput
        placeholder="テキストチャット入力…"
        (keydown.enter)="sendMessage($event)"
        [(ngModel)]="text"
      />
      <mat-icon matSuffix (click)="sendMessage2($event)">send</mat-icon>
    </mat-form-field>
  </div>
</div>
