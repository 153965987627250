import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IQAT, QuestionType } from 'src/models/qa-template';
import { ActivatedRoute, Router } from '@angular/router';
import { PCFTemplateService } from 'src/services/api/pcf-template.service';
import { IPCFTemplate } from 'src/models';
import { Store } from '@ngrx/store';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { filter, first, map } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-pcf-template-detail',
  templateUrl: './pcf-template-detail.component.html',
  styleUrls: ['./pcf-template-detail.component.scss'],
})
export class PcfTemplateDetailComponent implements OnInit {
  templateId = '';
  pharmacyId = '';
  groupNameFormControl = new FormControl('', [Validators.pattern(new RegExp('^(?!.*\\\\).*'))]);
  templateNameFormControl = new FormControl('新しいテンプレート', [
    Validators.required,
    Validators.pattern(new RegExp('^(?!.*\\\\).*')),
  ]);
  name = '新しいテンプレート';
  qaTemplate: IPCFTemplate | null = null;
  questions: IQAT[] = [];
  isBeingEdited: boolean[] = [];
  loading = true;
  saving = false;
  removing = false;
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private pcfTemplateService: PCFTemplateService,
  ) {}

  async ngOnInit(): Promise<void> {
    getLoginSession(this.store)
      .pipe(
        map(s => s.pharmacy),
        filter(isNotNull),
      )
      .subscribe(async pharmacy => {
        this.pharmacyId = pharmacy.id;
      });

    this.templateId = this.route.snapshot.paramMap.get('templateId') ?? '';
    if (this.templateId === 'new-template' || this.templateId === 'new-template-history') {
      const queryParams = await this.route.queryParams.pipe(first()).toPromise();
      this.groupNameFormControl.setValue(queryParams.groupName ? queryParams.groupName.toString() : '');
      this.questions = [{ type: QuestionType.radio, question: '', answers: ['', ''], required: false }];
      this.loading = false;
    } else {
      this.qaTemplate = await this.fetchTemplate(this.templateId);
      if (!this.qaTemplate) {
        this.router.navigate(['/pharmacy/pcf-templates']);
      } else {
        const groupAndName = this.qaTemplate.name?.split('\\', 2) ?? [''];
        const [groupName, templateName] =
          groupAndName.length > 1 ? [groupAndName[0], groupAndName[1]] : ['', groupAndName[0]];
        this.questions = this.qaTemplate?.qa ?? [];
        this.groupNameFormControl.setValue(groupName);
        this.templateNameFormControl.setValue(templateName);
      }
    }
    this.questions.filter(q => !q.answers).forEach(q => (q.answers = ['']));
  }

  async fetchTemplate(templateId: string) {
    try {
      this.loading = true;
      return await this.pcfTemplateService.find(templateId);
    } finally {
      this.loading = false;
    }
  }

  async saveTemplate() {
    if (!confirm('この内容で保存しますか？')) {
      return;
    }
    const processedQuestions: IQAT[] = this.questions.map(q => ({
      required: q.required,
      type: q.type,
      question: q.question,
      answers: q.type !== QuestionType.textbox ? q.answers.filter(a => a.trim()) : [],
    }));
    try {
      this.loading = true;
      this.saving = true;
      const groupName = this.groupNameFormControl.value.toString().trim();
      const groupAndName = groupName
        ? groupName + '\\' + this.templateNameFormControl.value.toString()
        : this.templateNameFormControl.value.toString();
      if (this.templateId !== 'new-template' && this.templateId !== 'new-template-history') {
        await this.pcfTemplateService.update({
          id: this.templateId,
          pharmacy_id: this.pharmacyId,
          name: groupAndName,
          qa: processedQuestions,
        });
      } else {
        await this.pcfTemplateService.create({
          pharmacy_id: this.pharmacyId,
          name: groupAndName,
          qa: processedQuestions,
        });
      }
    } catch (error) {
      console.log(error);
      alert('保存できませんでした。');
    } finally {
      this.loading = false;
      this.saving = false;
      this.router.navigate(['/pharmacy/pcf-templates']);
    }
  }

  async deleteTemplate() {
    if (!confirm('このテンプレートを削除しますか？')) {
      return;
    }
    this.loading = true;
    this.removing = true;
    try {
      await this.pcfTemplateService.remove(this.templateId);
    } catch (error) {
      console.log(error);
      alert('削除できませんでした。');
    } finally {
      this.router.navigate(['/pharmacy/pcf-templates']);
      this.loading = false;
      this.removing = false;
    }
  }
}
