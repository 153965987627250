import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { ICBFTemplate } from 'src/models';
import { CBFTemplateService } from 'src/services/api/cbf-template.service';
import { every, filter, map } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';

@Component({
  selector: 'app-cbf-templates',
  templateUrl: './cbf-templates.component.html',
  styleUrls: ['./cbf-templates.component.scss'],
})
export class CbfTemplatesComponent implements OnInit {
  pharmacyId: string = '';
  cbfTemplates: ICBFTemplate[] = [];
  displayedColumns: string[] = ['name'];
  loading: boolean = true;

  constructor(private store: Store, private cbfTemplateService: CBFTemplateService) {}

  ngOnInit(): void {
    getLoginSession(this.store)
      .pipe(
        map(s => s.pharmacy),
        filter(isNotNull),
      )
      .subscribe(async pharmacy => {
        this.pharmacyId = pharmacy.id;
        this.cbfTemplates = await this.fetchCbfTemplates(pharmacy.id);
      });
  }

  private async fetchCbfTemplates(pharmacyId: string): Promise<ICBFTemplate[]> {
    try {
      this.loading = true;
      return await this.cbfTemplateService.findAll(undefined, undefined, pharmacyId);
    } finally {
      this.loading = false;
    }
  }
}
