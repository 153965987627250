import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { filter, first, map } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { when } from 'src/app/modules/when';
import { IPatientInfo } from 'src/models';
import { IPCF } from 'src/models/pcf';
import { IQAT, QuestionType } from 'src/models/qa-template';
import { PatientInfoService } from 'src/services/api/patient-info.service';
import { PCFTemplateService } from 'src/services/api/pcf-template.service';
import { PcfService } from 'src/services/api/pcf.service';

@Component({
  selector: 'app-new-follow',
  templateUrl: './new-follow.component.html',
  styleUrls: ['./new-follow.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class NewFollowComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private pcfTemplateService: PCFTemplateService,
    private pcfService: PcfService,
    private patientInfoService: PatientInfoService,
    private router: Router,
    private store: Store,
  ) { }

  get selectableHour(): number[] {
    return Array(24)
      .fill(0)
      .map((_, i) => i);
  }

  get selectableMin(): number[] {
    return Array(60 / this.timeUnitMin)
      .fill(0)
      .map((_, i) => i * this.timeUnitMin);
  }
  questions: IQAT[] = [];
  loading = true;
  buttonLoading = false;
  patientInfoId = '';
  patientInfo?: IPatientInfo;
  selectedDate: moment.Moment | null = null;
  sendDate: moment.Moment | null = null;
  patientName = new FormControl();
  selectedHour = 12;
  selectedMin = 0;
  noPatientAlert = false;
  noDateAlert = false;
  pharmacistId = '';
  pharmacyId = '';
  templateId = '';
  repeat = 'no';
  repeatTimes = 1;
  readonly timeUnitMin: 15 | 30 = 15;
  trackByIndex = (index: number, item: any) => index;
  dateFilter = (d: moment.Moment | null) => (d ? 86400 + d.unix() - moment().unix() > 0 : false);

  async ngOnInit() {
    getLoginSession(this.store)
      .pipe(
        map(s => s.pharmacist),
        filter(isNotNull),
        first(),
      )
      .subscribe(pharmacist => {
        this.pharmacistId = pharmacist?.id;
        this.pharmacyId = pharmacist.pharmacy?.id ?? '';
      });
    await this.route.queryParams
      .pipe(first())
      .toPromise()
      .then(async params => {
        if (params.templateId) {
          this.templateId = params.templateId;
          this.questions = await this.pcfTemplateService.find(params.templateId).then(result => result?.qa ?? []);
        } else if (params.followId) {
          this.questions = await this.pcfService.find(params.followId).then(result => result?.pcf_questions ?? []);
        } else {
          this.questions = [];
        }
        if (params.patientInfoId) {
          this.patientInfoId = params.patientInfoId.toString();
          this.patientInfo = await this.patientInfoService.find(this.patientInfoId).catch(error => {
            alert('患者情報が見つかりませんでした。患者一覧に戻ります。');
            this.router.navigate(['pharmacist/patients']);
            return undefined;
          });
        } else {
          alert('患者情報が見つかりませんでした。患者一覧に戻ります。');
          this.router.navigate(['pharmacist/patients']);
        }
      });
    this.loading = false;
  }

  dropQuestion(event: CdkDragDrop<IQAT[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
  }

  dropChoice(index: number, event: CdkDragDrop<string[]>) {
    moveItemInArray(this.questions[index].answers, event.previousIndex, event.currentIndex);
  }

  addQuestion() {
    this.questions.push({ question: '', type: QuestionType.radio, required: false, answers: [''] });
  }

  async save() {
    let alertMessage = '';
    if (this.selectedDate === null || this.selectedDate === undefined) {
      this.noDateAlert = true;
      alertMessage += '送信日が設定されていません。\n';
    }
    if (alertMessage) {
      setTimeout(_ => alert(alertMessage), 50);
      return;
    }
    if (this.questions.length === 0) {
      alert('質問が設定されていない服薬フォローを送信することはできません。')
    }
    else
      if (confirm('この内容で服薬フォローの送信を予約しますか？')) {
        try {
          this.buttonLoading = true;

          for (let i = 0; i < this.repeatTimes; i++) {
            let tempDate = moment(this.selectedDate);
            this.sendDate = when(this.repeat)
              .on((r: string) => r === "daily", (): moment.Moment | null => tempDate!.add(i, 'days'))
              .on((r: string) => r === "weekly", (): moment.Moment | null => tempDate!.add(i, 'week'))
              .on((r: string) => r === "monthly", (): moment.Moment | null => tempDate!.add(i, 'months'))
              .otherwise((): moment.Moment => tempDate!)

            const time =
              (this.sendDate
                ?.hours(this.selectedHour)
                .minutes(this.selectedMin)
                .unix() ?? 0) * 1000;
            const target: IPCF = {
              id: '',
              pharmacy_id: this.pharmacyId,
              pharmacist_id: this.pharmacistId,
              patient_account_id: this.patientInfo?.patient_account_id,
              patient_info_id: this.patientInfo?.id,
              template_id: this.templateId,
              pcf_questions: this.questions,
              send_at: time,
            };
            await this.pcfService.create(target);
          }
          // if(confirm('この服薬フォローをテンプレートとして保存しますか？')) {

          // }
          this.router.navigate(['pharmacist/follow']);
        } finally {
          this.buttonLoading = false;
        }
      }
  }

  async send() {
    if (this.questions.length === 0) {
      alert('質問が設定されていない服薬フォローを送信することはできません。')
    }
    else if (confirm('この内容で服薬フォローを即時送信しますか？')) {
      this.questions = this.questions.map((item) => {
        if (item.type === 'textbox') {
          item.answers = [];
        }
        return item;
      });
      try {
        const now = moment()
        const time = (now.unix() ?? 0) * 1000;
        const target: IPCF = {
          id: '',
          pharmacy_id: this.pharmacyId,
          pharmacist_id: this.pharmacistId,
          patient_account_id: this.patientInfo?.patient_account_id,
          patient_info_id: this.patientInfo?.id,
          template_id: this.templateId,
          pcf_questions: this.questions,
          send_at: time,
        };
        await this.pcfService.create(target);
        this.router.navigate(['pharmacist/follow']);
      } finally {
        this.buttonLoading = false;
      }
    }
  }

  patientNameWithSpace(patientInfo: IPatientInfo) {
    return patientInfo.family_name + ' ' + patientInfo.given_name;
  }
  patientNameKanaWithSpace(patientInfo: IPatientInfo) {
    return patientInfo.family_name_kana + ' ' + patientInfo.given_name_kana;
  }
  patientNameWithoutSpace(patientInfo: IPatientInfo) {
    return patientInfo.family_name + patientInfo.given_name;
  }
  patientNameKanaWithoutSpace(patientInfo: IPatientInfo) {
    return patientInfo.family_name_kana + patientInfo.given_name_kana;
  }

  createFollow() {
    this.router.navigate(['/pharmacist/follow/base-select'], {
      queryParams: { patientInfoId: this.patientInfo?.id ?? '' },
    });
  }

}
