<loading-spinner [loading]="loading"></loading-spinner>
<div class="root" *ngIf="!loading">
  <h1>服薬指導予約可能枠</h1>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row-reverse" fxLayoutGap="20px">
      <loading-button
        *ngIf="slotId"
        name="削除"
        [color]="'warn'"
        [loading]="loading"
        (click)="delete()"
        icon="delete"
      ></loading-button>
      <loading-button
        name="{{ slotId ? '更新' : '登録' }}"
        [color]="'primary'"
        [loading]="loading"
        [disabled]="!areAllFormsValid"
        (click)="save()"
        icon="save"
      ></loading-button>
    </div>
    <div fxLayout="column" fxLayoutGap="20px">
      <div class="edit-meta" fxLayout="row" fxLayoutGap="40px">
        <mat-form-field fxFlex="75">
          <mat-label>名前</mat-label>
          <input matInput type="text" [formControl]="nameFormControl" required />
          <mat-error *ngIf="nameFormControl.hasError('required')">名前は<strong>必須</strong>です</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="25">
          <mat-label>優先度 (0~99)</mat-label>
          <input matInput type="number" [formControl]="priorityFormControl" required />
          <mat-error *ngIf="priorityFormControl.hasError('required')">優先度は<strong>必須</strong>です</mat-error>
          <mat-error *ngIf="priorityFormControl.hasError('pattern')"><strong>0~99</strong>を入力してください</mat-error>
        </mat-form-field>
      </div>
      <div class="edit-slot" fxLayout="column" fxLayoutGap="25px">
        <div class="timeframe-container" fxLayout="column" fxLayoutGap="15px">
          <div
            class="timeframe-unit"
            [style.border-color]="!displayTimeSelector(slot) ? '#999' : ''"
            *ngFor="let slot of slotsForEdit; index as i"
          >
            <div fxLayout="row" fxLayoutAlign="space-between">
              <div fxLayout="row" fxFlex="grow" fxLayout.lt-lg="column">
                <div class="radio-group-wrap" fxLayout="column" fxLayoutAlign="center">
                  <mat-radio-group
                    [(ngModel)]="slot.type"
                    fxFlexAlign="center"
                    fxLayout="column"
                    fxLayout.lt-lg="row"
                    fxLayoutGap.lt-lg="30px"
                  >
                    <mat-radio-button [value]="'open'">open</mat-radio-button>
                    <mat-radio-button [value]="'close'">close</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div fxFlex="grow" fxLayout="column" fxLayoutGap="0px">
                  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="5px">
                    <div>
                      <mat-form-field>
                        <mat-label *ngIf="!slot.frequency">頻度</mat-label>
                        <mat-select [ngModel]="slot.frequency" (valueChange)="frequencyChange(i, $event)">
                          <mat-option [value]="null">- -</mat-option>
                          <mat-option [value]="'once'">特定の日</mat-option>
                          <mat-option [value]="'every_year'">毎年</mat-option>
                          <mat-option [value]="'every_month'">毎月</mat-option>
                          <mat-option [value]="'every_week'">毎週</mat-option>
                          <mat-option [value]="'every_day'">毎日</mat-option>
                          <mat-option [value]="'every_2_weeks'">隔週</mat-option>
                          <mat-option [value]="'every_3_weeks'">2週おき</mat-option>
                          <mat-option [value]="'every_4_weeks'">3週おき</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="displayMonthSelector(slot)">
                      <mat-form-field>
                        <mat-label *ngIf="!slot.month">月</mat-label>
                        <mat-select [ngModel]="slot.month" (valueChange)="monthChange(i, $event)">
                          <mat-option [value]="null">- -</mat-option>
                          <mat-option *ngFor="let month of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" [value]="month">
                            {{ month + '月' }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="displayDateSelector(slot)">
                      <mat-form-field>
                        <mat-label *ngIf="!slot.date">選択</mat-label>
                        <mat-select [(ngModel)]="slot.date">
                          <mat-option [value]="null">- -</mat-option>
                          <mat-optgroup label="日付選択">
                            <mat-option *ngFor="let date of availableDates(slot.month)" [value]="date">
                              {{ date + '日' }}
                            </mat-option>
                          </mat-optgroup>
                          <mat-optgroup label="曜日指定 (N週目)">
                            <mat-option [value]="'in_1st_week'">1週目の</mat-option>
                            <mat-option [value]="'in_2nd_week'">2週目の</mat-option>
                            <mat-option [value]="'in_3rd_week'">3週目の</mat-option>
                            <mat-option [value]="'in_4th_week'">4週目の</mat-option>
                            <mat-option [value]="'in_5th_week'">5週目の</mat-option>
                          </mat-optgroup>
                          <mat-optgroup label="曜日指定 (N番目)">
                            <mat-option [value]="'1st'">1番目の</mat-option>
                            <mat-option [value]="'2nd'">2番目の</mat-option>
                            <mat-option [value]="'3rd'">3番目の</mat-option>
                            <mat-option [value]="'4th'">4番目の</mat-option>
                            <mat-option [value]="'5th'">5番目の</mat-option>
                          </mat-optgroup>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="displayDaySelector(slot)">
                      <mat-form-field>
                        <mat-label *ngIf="slot.day === null">曜日</mat-label>
                        <mat-select [(ngModel)]="slot.day">
                          <mat-option [value]="null">- -</mat-option>
                          <mat-option [value]="0">日曜日</mat-option>
                          <mat-option [value]="1">月曜日</mat-option>
                          <mat-option [value]="2">火曜日</mat-option>
                          <mat-option [value]="3">水曜日</mat-option>
                          <mat-option [value]="4">木曜日</mat-option>
                          <mat-option [value]="5">金曜日</mat-option>
                          <mat-option [value]="6">土曜日</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="displayStartDateSelector(slot)">
                      <div fxLayout="row" fxLayoutGap="10px">
                        <mat-form-field class="zero-width">
                          <input
                            matInput
                            type="text"
                            [matDatepickerFilter]="datePickerFilter"
                            [matDatepicker]="picker"
                            [(ngModel)]="slot.startDate"
                          />
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <button
                          mat-stroked-button
                          (click)="picker.open()"
                          fxFlexAlign="center"
                          fxLayout="row"
                          fxLayoutAlign="center"
                        >
                          <div fxFlexAlign="center" fxLayout="row" fxLayoutGap="5px" fxLayoutGap.lt-sm="0">
                            <mat-icon fxFlexAlign="center">event</mat-icon>
                            <div fxHide.lt-sm>{{ slot.frequency === 'once' ? '日付を選択' : '開始日を選択' }}</div>
                          </div>
                        </button>
                        <div fxFlexAlign="center">
                          {{
                            slot.frequency === 'once'
                              ? slot.startDate
                                ? (slot.startDate | date: 'yyyy/MM/dd')
                                : '日付を選択してください'
                              : slot.startDate
                              ? (slot.startDate | date: 'yyyy/MM/dd') + ' 開始'
                              : '開始日を選択してください'
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="displayTimeSelector(slot)"
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    fxLayoutGap="10px"
                  >
                    <div>
                      <mat-form-field>
                        <mat-label>開始時刻</mat-label>
                        <mat-select [ngModel]="slot.startTime" (valueChange)="startTimeChange(i, $event)">
                          <ng-container *ngFor="let h of hours">
                            <mat-option
                              *ngFor="let m of [0, 30]"
                              [value]="h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0')"
                            >
                              {{ h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0') }}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div>から</div>
                    <div>
                      <mat-form-field>
                        <mat-label>終了時刻</mat-label>
                        <mat-select [ngModel]="slot.endTime" (valueChange)="endTimeChange(i, $event)">
                          <ng-container *ngFor="let h of hours">
                            <mat-option
                              *ngFor="let m of [0, 30]"
                              [value]="h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0')"
                            >
                              {{ h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0') }}
                            </mat-option>
                          </ng-container>
                          <mat-option [value]="'24:00'">24:00</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div>まで</div>
                  </div>
                </div>
              </div>
              <div fxFlexAlign="center">
                <button mat-icon-button [disabled]="slotsForEdit.length < 2" (click)="removeSlot(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <button mat-stroked-button fxFlexAlign="center" (click)="addSlot()" class="add-button">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
            <mat-icon>add_circle_outline</mat-icon>
            <div>追加</div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
