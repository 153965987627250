import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss'],
})
export class CustomPaginatorComponent {
  @Input() color: ThemePalette;
  @Input() disabled = false;
  @Input() hidePageSize = false;
  @Input() length = 0;
  @Input() pageIndex = 0;
  @Input() pageSize = 50;
  @Input() pageSizeOptions: number[] = [];
  @Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() reload: EventEmitter<void> = new EventEmitter<void>();
  constructor() {}
  pageEvent(event: PageEvent) {
    this.page.emit(event);
  }
  reloadEvent() {
    this.reload.emit();
  }
}
