type ChainedWhen<T, R> = {
  on: <A>(pred: (v: T) => boolean, fn: (v: T) => A) => ChainedWhen<T, R | A>;
  otherwise: <A>(fn: (v: T) => A) => R | A;
};

const match = <T, R>(val: any): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: (v: T) => A) => match<T, R | A>(val),
  otherwise: <A>(fn: (v: T) => A): A | R => val,
});

const chain = <T, R>(val: T): ChainedWhen<T, R> => ({
  on: <A>(pred: (v: T) => boolean, fn: (v: T) => A) => (pred(val) ? match(fn(val)) : chain<T, A | R>(val)),
  otherwise: <A>(fn: (v: T) => A) => fn(val),
});

export const when = <T>(val: T) => ({
  on: <A>(pred: (v: T) => boolean, fn: (v: T) => A) => (pred(val) ? match<T, A>(fn(val)) : chain<T, A>(val)),
});
