import { Component, Input, OnInit } from '@angular/core';
import { LinkCardContent } from '../card/card.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-top-button',
  templateUrl: './top-button.component.html',
  styleUrls: ['./top-button.component.scss'],
})
export class TopButtonComponent implements OnInit {
  @Input() linkContents: LinkCardContent[] = [];
  @Input() isSingle = false;
  constructor() {}

  ngOnInit(): void {}

  onMouseOver(icon: MatIcon, background: HTMLElement) {
    icon._elementRef.nativeElement.style.transform = 'scale(0.85)';
    background.style.backgroundColor = '#fff7ef';
  }

  onMouseLeave(icon: MatIcon, background: HTMLElement) {
    icon._elementRef.nativeElement.style.transform = 'scale(0.75)';
    background.style.backgroundColor = '';
  }
}
