import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { first, map } from 'rxjs/operators';

export const PASSWORD_PROMPT_CONFIG = new InjectionToken<{
  title?: string;
  message: string;
  constraints?: string[];
  regexp?: RegExp;
  overlayRef: OverlayRef;
}>('PASSWORD_PROMPT_CONFIG');

@Component({
  selector: 'app-password-prompt',
  templateUrl: './password-prompt.component.html',
  styleUrls: ['./password-prompt.component.scss'],
})
export class PasswordPromptComponent implements AfterViewInit {
  @ViewChild('input') input!: ElementRef;
  title?: string = undefined;
  message = '';
  constraints: string[] = [];
  passwordFormControl = new FormControl('', []);
  private overlayRef: OverlayRef;

  constructor(
    @Inject(PASSWORD_PROMPT_CONFIG)
    data: {
      title?: string;
      message: string;
      constraints: string[];
      regexp?: RegExp;
      overlayRef: OverlayRef;
    },
  ) {
    this.title = data.title;
    this.message = data.message;
    this.constraints = data.constraints;
    this.overlayRef = data.overlayRef;
    this.passwordFormControl = new FormControl('', [Validators.required, Validators.pattern(data.regexp ?? '')]);
  }

  ngAfterViewInit() {
    this.input.nativeElement.focus();
  }

  submit() {
    this.overlayRef.dispose();
  }
  cancel() {
    this.passwordFormControl.setValue('');
    this.overlayRef.dispose();
  }
}

@Injectable({
  providedIn: 'root',
})
export class PasswordPromptService {
  constructor(private readonly parentInjector: Injector, private readonly overlay: Overlay) {}
  prompt(message?: string, constraints?: string[], regexp?: RegExp, title?: string) {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .top();
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const config = {
      positionStrategy,
      scrollStrategy,
      width: 'auto',
      height: 'auto',
      hasBackdrop: true,
      backdropClass: ['backdrop-class'],
    };
    const overlayRef = this.overlay.create(config);
    const injector = Injector.create({
      parent: this.parentInjector,
      providers: [
        {
          provide: PASSWORD_PROMPT_CONFIG,
          useValue: {
            title: title ?? undefined,
            message: message ?? 'パスワードを入力してください。',
            constraints: constraints ?? [],
            regexp: regexp ?? undefined,
            overlayRef,
          },
        },
      ],
    });
    const componentRef = overlayRef.attach(new ComponentPortal(PasswordPromptComponent, null, injector));
    return overlayRef
      .detachments()
      .pipe(
        first(),
        map(() => componentRef.instance.passwordFormControl.value as string),
      )
      .toPromise();
  }
}
