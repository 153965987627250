<div fxLayout="column">
  <h1>服薬指導フォーマット一覧</h1>
  <div class="add-button-area" fxLayoutAlign="end" fxFlexOffset="auto">
    <button class="round-button" mat-raised-button color="primary" routerLink="/pharmacy/pci-templates/new-template">
      <mat-icon>library_add</mat-icon>
      新規フォーマット作成
    </button>
  </div>
  <div class="table-container">
    <div class="paginator-and-table">
      <app-custom-paginator
        [disabled]="loading"
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
        (reload)="reloadEvent()"
      ></app-custom-paginator>
      <table class="table" mat-table [dataSource]="pciTemplates">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>フォーマット名</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          routerLink="/pharmacy/pci-templates/{{ row.id }}"
          class="clickable-row"
        ></tr>
      </table>
    </div>
    <div class="loading-shade" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
