import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { PcfStatus } from 'src/models/pcf';

@Pipe({ name: 'delivery_status' })
export class DeliveryStatusPiPe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === 'unshipped',
        _ => '未交付',
      )
      .on(
        v => v === 'shipped',
        _ => '交付済み',
      )
      .otherwise(v => '未設定');
  }
}
