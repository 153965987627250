import { Component, OnInit } from '@angular/core';
import { LinkCardContent } from '../parts/card/card.component';
import { version } from '../../../package.json';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  version: string = version;
  linkContents: LinkCardContent[] = [
    {
      href: '/pharmacy/login',
      title: ['薬局管理メニュー'],
      icon: 'medical_services',
    },
    {
      href: '/pharmacist/login',
      title: ['ログイン'],
      icon: 'people',
    },
  ];

  constructor() { }

  ngOnInit(): void { }
}
