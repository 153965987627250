import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';

@Pipe({ name: 'payment_status' })
export class PaymentStatusPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === 'created',
        _ => '通知済み(未決済)',
      )
      .on(
        v => v === 'started',
        _ => '患者処理中(未決済)',
      )
      .on(
        v => v === 'completed',
        _ => '完了済み',
      )
      .on(
        v => v === 'failed',
        _ => '失敗',
      )
      .on(
        v => v === 'cancelled',
        _ => 'キャンセル済み',
      )
      .otherwise(_ => 'その他');
  }
}
