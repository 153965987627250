import { when } from 'src/app/modules/when';
import { IPharmacist, IPharmacy } from '.';

export class Session {
  public pharmacy: IPharmacy | null = null;
  public pharmacist: IPharmacist | null = null;
  constructor(user?: IPharmacy | IPharmacist) {
    if (user === undefined) {
      return;
    }
    if ((user as IPharmacy).store_name !== undefined) {
      this.pharmacy = user as IPharmacy;
    }
    if ((user as IPharmacist).pharmacy?.id !== undefined) {
      this.pharmacist = user as IPharmacist;
    }
  }

  get type(): 'pharmacy' | 'pharmacist' | undefined {
    return when(this)
      .on<'pharmacy'>(
        v => v.pharmacy !== null,
        _ => 'pharmacy',
      )
      .on<'pharmacist'>(
        v => v.pharmacist !== null,
        _ => 'pharmacist',
      )
      .otherwise(_ => undefined);
  }
}
