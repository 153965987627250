<div>
  <h1>薬剤師一覧</h1>
  <div class="top-area" fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex="50" class="input-area">
      <mat-form-field>
        <mat-label class="search-label" for="name">名前で検索</mat-label>
        <input type="text" matInput [formControl]="nameFilterFormControl" />
      </mat-form-field>
    </div>
    <div fxFlexAlign="center">
      <button class="round-button" mat-raised-button color="primary" (click)="openCreatePharmacistDialog()">
        <mat-icon>person_add</mat-icon>薬剤師登録
      </button>
    </div>
  </div>

  <div class="table-container">
    <div class="paginator-and-table">
      <app-custom-paginator
        [disabled]="loading"
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
        (reload)="reloadEvent()"
      ></app-custom-paginator>
      <table mat-table [dataSource]="pharmacists">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>氏名</th>
          <td mat-cell *matCellDef="let element">{{ element.family_name + element.given_name }}</td>
        </ng-container>

        <ng-container matColumnDef="kana">
          <th mat-header-cell *matHeaderCellDef>かな</th>
          <td mat-cell *matCellDef="let element">{{ element.family_name_kana + element.given_name_kana }}</td>
        </ng-container>

        <ng-container matColumnDef="disable">
          <th mat-header-cell *matHeaderCellDef>無効</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip disabled class="disabled" *ngIf="element.deleted_at">無効化済み</mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          class="clickable-row"
          [class.unclickable]="pharmacistsDisabled[i]"
          (click)="openDetailDialog(row)"
          *matRowDef="let row; columns: displayedColumns; index as i"
        ></tr>
      </table>
    </div>
    <div class="loading-shade" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
