<div class="root" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row-reverse">
    <loading-button
      [loading]="loading"
      [color]="'primary'"
      [name]="'パスワード変更'"
      [disabled]="!areAllFormsValid"
      (click)="changePassword()"
    ></loading-button>
  </div>
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
    <mat-form-field class="password-field">
      <mat-label>現在のパスワード</mat-label>
      <input matInput type="password" [(ngModel)]="currentPassword" />
    </mat-form-field>
    <div fxLayout="column" class="password-check">
      <div>以下を満たすパスワードを設定してください。</div>
      <mat-checkbox [checked]="newPasswordFormControl1.value.toString().length > 7">
        8文字以上
      </mat-checkbox>
      <mat-checkbox [checked]="newPasswordFormControl1.value.toString().match('[A-Z]')">
        英語大文字 (A~Z) を含む
      </mat-checkbox>
      <mat-checkbox [checked]="newPasswordFormControl1.value.toString().match('[a-z]')">
        英語小文字 (a~z) を含む
      </mat-checkbox>
      <mat-checkbox [checked]="newPasswordFormControl1.value.toString().match('[0-9]')">
        数字 (0~9) を含む
      </mat-checkbox>
    </div>
    <form [formGroup]="newPasswordGroup" fxLayout="column" fxLayoutGap="20px">
      <mat-form-field class="password-field">
        <mat-label>新しいパスワード</mat-label>
        <input matInput type="password" [formControl]="newPasswordFormControl1" />
        <mat-error *ngIf="newPasswordFormControl1.hasError('pattern')">パスワードの形式が正しくありません</mat-error>
      </mat-form-field>
      <mat-form-field class="password-field">
        <mat-label>新しいパスワード ( 再入力 )</mat-label>
        <input matInput type="password" [formControl]="newPasswordFormControl2" />
        <mat-error *ngIf="newPasswordFormControl2.hasError('notMatch')">入力パスワードが一致していません</mat-error>
      </mat-form-field>
    </form>
  </div>
</div>
