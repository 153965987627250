import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAlarmReluctance } from 'src/models/alarm-reluctance';
import { CognitoService } from '../cognito.service';
import { ServiceBase } from '../service-base';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlarmReluctanceService extends ServiceBase<any> {
  readonly propertyName = 'reluctance_history';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'alarm/reluctance_history');
  }

  public findAll(params?: { patient_info_id?: string, year?: number, month?: number }): Promise<IAlarmReluctance[]> {
    return super.findAll(params);
  }

  async getImage(medicineId: string): Promise<string> {
    return (
      await axios.get<any>(
        environment.api_base_url + `pharmacist/alarm/${medicineId}/image`,
        await this.buildConfig(undefined, 'application/json'),
      )
    ).data.image as string;
  }
}
