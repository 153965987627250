import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Amplify, { Auth } from 'aws-amplify';
import { IPharmacist, IPharmacy } from 'src/models';
import { when } from 'src/app/modules/when';
import { PasswordPromptService } from 'src/app/parts/password-prompt/password-prompt.component';

export const configure = (type: 'pharmacy' | 'pharmacist' | 'admin') => {
  const [userPoolId, userPoolWebClientId] = when(type)
    .on(
      v => v === 'pharmacy',
      _ => [environment.userPoolIdForPharmacy, environment.clientIdForPharmacy],
    )
    .on(
      v => v === 'pharmacist',
      _ => [environment.userPoolIdForPharmacist, environment.clientIdForPharmacist],
    )
    .otherwise(_ => [null, null]);
  if (userPoolId === null || userPoolWebClientId === null) {
    throw new Error('Cognitoの設定に失敗しました。');
  }
  Amplify.configure({
    Auth: {
      region: environment.region,
      userPoolId,
      userPoolWebClientId,
      mandatorySignIn: false,
      //storage: localStorage,
      // Chrome を閉じた際にログアウトするようにする。
      storage: sessionStorage,

      authenticationFlowType: 'USER_SRP_AUTH',
    },
  });
  return Auth.configure();
};

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private _userId = '';

  constructor(private passwordPromptService: PasswordPromptService) { }

  get userId() {
    return this._userId;
  }

  loginInfo: IPharmacy | IPharmacist | null = null;

  async signUp(
    username: string,
    password: string,
    currentType: 'pharmacy' | 'pharmacist' | 'admin',
    targetType: 'pharmacy' | 'pharmacist',
  ) {
    configure(targetType);
    const params = {
      username,
      password,
    };
    try {
      return Auth.signUp(params);
    } finally {
      configure(currentType);
    }
  }

  // ログイン処理
  async login(username: string, password: string, type: 'pharmacy' | 'pharmacist' | 'admin'): Promise<string | null> {
    configure(type);
    let user: any = null;
    try {
      user = await Auth.signIn(username, password);
    } catch (error) {
      console.error('signin error!', error);
      if (type === 'pharmacist') {
        throw new Error('ログインに失敗しました。薬局ID, 薬剤師ID, パスワードが誤っている可能性があります。');
      } else {
        throw new Error('ログインに失敗しました。薬局管理者ID, パスワードが誤っている可能性があります。');
      }
    }

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const newPassword = await this.passwordPromptService.prompt(
        '新しいパスワードを入力してください。',
        ['8文字以上', '英語大文字 (A~Z) を含む', '英語小文字 (a~z) を含む', '数字 (0~9) を含む'],
        new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{8,}$'),
        'パスワード初期設定',
      );
      if (!newPassword) {
        throw new Error('新しいパスワードは必須です。');
      }
      try {
        await Auth.completeNewPassword(user, newPassword);
      } catch (error) {
        throw new Error('新パスワードの登録に失敗しました。');
      }
    }
    if (!this.userId) {
      const session = await Auth.currentSession();
      this._userId = session.getIdToken().payload.sub;
    }
    return this.userId;
  }

  // ログイン済確認処理
  async isAuthenticated(): Promise<string | null> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (!this.userId) {
        const session = await Auth.currentSession();
        this._userId = session.getIdToken().payload.sub;
      }
      // tslint:disable-next-line
      console.info('AUTH , ', this.userId);
      return this.userId;
    } catch (error) {
      return null;
    }
  }

  // IDトークン取得処理
  async getAccessToken() {
    try {
      const session = await Auth.currentSession();
      return session.getAccessToken();
    } catch (error) {
      throw error;
    }
  }

  // ログアウト処理
  async logout() {
    console.log('LogOut!');
    this._userId = '';
    await Auth.signOut();
  }
}
