import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';

@Pipe({ name: 'medication_timing' })
export class MedicationTimingPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === 'waking up',
        _ => '起床時',
      )
      .on(
        v => v === 'morning',
        _ => '朝',
      )
      .on(
        v => v === 'noon',
        _ => '昼',
      )
      .on(
        v => v === 'evening',
        _ => '夕',
      )
      .on(
        v => v === 'before sleep',
        _ => '寝る前',
      )
      .otherwise(_ => '不明');
  }
}
