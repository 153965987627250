<div class="question" *ngFor="let qa of displayQa!; index as i">
  <p>{{ qa.question }}</p>
  <div *ngIf="qa.type === 'radio'">
    <mat-radio-group fxLayout="row" (ngModelChange)="changeValue()" [(ngModel)]="qaAnswer[i]!.answers[0]">
      <mat-radio-button fxFlex fxFlexFill [value]="ans" *ngFor="let ans of qa.answers">{{ ans }}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="qa.type === 'textbox'">
    <textarea
      cols="50"
      rows="10"
      name="msg"
      (ngModelChange)="changeValue()"
      (input)="inputText()"
      [(ngModel)]="qaAnswer[i]!.answers[0]"
    ></textarea>
  </div>
  <div *ngIf="qa.type === 'checkbox'">
    <section class="list-section">
      <mat-checkbox
        *ngFor="let checkbox of qa.checkBox; let j = index"
        [(ngModel)]="qaAnswer[i]!.checkBox[j].checked"
        (ngModelChange)="changeValue()"
      >
        {{ checkbox.label }}
      </mat-checkbox>
    </section>
  </div>
</div>
