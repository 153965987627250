import { Injectable } from '@angular/core';
import { IPatientAccount } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PatientAccountService extends ServiceBase<IPatientAccount> {
  propertyName = 'patientaccounts';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'patient_accounts');
  }

  public findAll(offset?: number, limit?: number): Promise<IPatientAccount[]> {
    const params = {
      offset,
      limit,
    };
    return super.findAll(params);
  }
}
