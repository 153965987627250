<mat-dialog-content>
  <div>{{'患者氏名 : '+getPatientName(follow.patient_info)}}</div>
  <div>{{'状態 : '+follow.status}}</div>
  <div>{{'送信日時 : '+(follow.send_at | date: 'yyyy/MM/dd HH:mm')}}</div>
  <div *ngIf="['未確認','確認済み'].includes(follow.status)">
    {{'回答日時 : '+(follow.answered_at|date:'yyyy/MM/dd HH:mm')}}
  </div>
  <div class="qa-container">
    <div class="qa-content" *ngFor="let question of follow.pcf_questions index as i">
      <div class="question">
        <mat-chip-list fxLayout="row">
          <mat-chip *ngIf="question.type === 'radio'" [selectable]="false">1つ選択</mat-chip>
          <mat-chip *ngIf="question.type === 'checkbox'">複数選択</mat-chip>
          <mat-chip *ngIf="question.type === 'textbox'">自由記述</mat-chip>
        </mat-chip-list>
        <mat-divider class="cq-divider"></mat-divider>
        <div>{{question.question}}</div>
        <div *ngIf="question.type !== 'textbox'">
          <div *ngFor="let choice of question.answers">・{{choice}}</div>
        </div>
      </div>
      <mat-divider class="qa-divider"></mat-divider>
      <div class="answer" *ngIf="['未確認','確認済み'].includes(follow.status)">
        >> {{followAnswer(i)}}
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
  <button class="round-button" color="primary" mat-raised-button mat-dialog-close>
    <mat-icon>close</mat-icon>閉じる
  </button>
  <loading-button
    *ngIf="follow.status==='未確認'"
    [loading]="loading"
    [name]="'確認'"
    color="primary"
    (click)="confirm()"
    icon="check"
  ></loading-button>
  <loading-button
    *ngIf="follow.status==='送信予約済み'"
    [loading]="loading"
    [name]="'削除'"
    color="warn"
    (click)="delete()"
    icon="delete"
  ></loading-button>
  <loading-button
    *ngIf="follow.status==='回答待ち（未読）'"
    [loading]="loading"
    [name]="'取り消し'"
    color="warn"
    icon="cancel"
    (click)="cancel()"
  ></loading-button>
</mat-dialog-actions>
