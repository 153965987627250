<form class="login_form" fxLayout="column">
  <mat-form-field fxFlex>
    <mat-label for="user_id">薬局管理者ID</mat-label>
    <input class="id_input" matInput name="user_id" [(ngModel)]="userId" />
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label for="password">パスワード</mat-label>
    <input class="pw_input" type="password" matInput name="password" [(ngModel)]="password" />
  </mat-form-field>
  <div class="button-area" fxFlex>
    <button class="round-button" mat-raised-button color="primary" (click)="login()">ログイン</button>
  </div>
  <!-- <div class="link-area" fxFlex>
    <a mat-button routerLink="/pharmacy/reset-password">パスワードを忘れた場合は</a>
  </div> -->
</form>
