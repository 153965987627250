<h3>服薬指導フォーマット</h3>
<mat-dialog-content>
  <div class="question" *ngFor="let qa of pciTemplate?.qa ? pciTemplate?.qa : []; index as i">
    <p>{{ qa.question }}</p>
    <div *ngIf="qa.type === 'radio'">
      <mat-radio-group fxLayout="row">
        <mat-radio-button disabled fxFlex fxFlexFill [value]="ans" *ngFor="let ans of qa.answers">{{
          ans
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="qa.type === 'textbox'">
      <textarea cols="50" rows="10" name="msg" disabled></textarea>
    </div>
    <div *ngIf="qa.type === 'checkbox'">
      <section class="list-section">
        <mat-checkbox *ngFor="let checkbox of qa.answers; let i = index" disabled>
          {{ checkbox }}
        </mat-checkbox>
      </section>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
  <button color="primary" mat-raised-button mat-dialog-close><mat-icon>close</mat-icon>閉じる</button>
  <loading-button
    class="round-button"
    name="指定する"
    color="primary"
    (click)="specifyPciTemplate()"
    [loading]="loading"
    cdkFocusInitial
    icon="check"
  ></loading-button>
</mat-dialog-actions>
