import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NewArrival, NewArrivalService } from 'src/services/new-arrival.service';

@Component({
  selector: 'pharmacist-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  loading = true;
  readonly displayedColumns = ['textTime', 'patientName', 'title'];
  notices$: Observable<NewArrival[]> = of([]);

  constructor(newArrivalService: NewArrivalService) {
    this.notices$ = newArrivalService.newArrivals;
  }

  async ngOnInit() {
    this.loading = false;
  }
}
