<loading-spinner [loading]="loading"></loading-spinner>
<div class="accordion-container">
  <mat-accordion>
    <mat-expansion-panel #expansionPanel class="filter-panel">
      <mat-expansion-panel-header class="filter-panel-header">
        <mat-panel-title>絞り込み</mat-panel-title>
        <mat-panel-description fxLayout="row-reverse"><mat-icon>filter_alt</mat-icon></mat-panel-description>
      </mat-expansion-panel-header>
      <div fxLayout="row" fxLayoutGap="30px" class="filter-panel-content">
        <div fxFlex="50" fxLayout="column">
          <div
            class="filter-panel-button"
            fxLayout="row"
            fxLayoutGap="5px"
            *ngFor="let status of instructionStatusNames.slice(0, ceil(instructionStatusNames.length / 2)); index as i"
            matRipple
            (click)="filterByStatus(i)"
          >
            <mat-pseudo-checkbox
              fxFlexAlign="center"
              [state]="statusFilter[i] ? 'checked' : 'unchecked'"
              [style.background-color]="statusFilter[i] ? (status | calendar_entry_color) : ''"
            ></mat-pseudo-checkbox>
            <div fxFlexAlign="center" fxFlex>{{ status | reservation_status }}</div>
          </div>
        </div>
        <div fxFlex="50" fxLayout="column">
          <div
            class="filter-panel-button"
            fxLayout="row"
            fxLayoutGap="5px"
            *ngFor="
              let status of instructionStatusNames.slice(
                ceil(instructionStatusNames.length / 2),
                instructionStatusNames.length
              );
              index as i
            "
            matRipple
            (click)="filterByStatus(i + ceil(instructionStatusNames.length / 2))"
          >
            <mat-pseudo-checkbox
              fxFlexAlign="center"
              [state]="statusFilter[i + ceil(instructionStatusNames.length / 2)] ? 'checked' : 'unchecked'"
              [style.background-color]="
                statusFilter[i + ceil(instructionStatusNames.length / 2)] ? (status | calendar_entry_color) : ''
              "
            ></mat-pseudo-checkbox>
            <div fxFlexAlign="center" fxFlex>{{ status | reservation_status }}</div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<full-calendar #calendar class="calendar" [options]="calendarOptions" [deepChangeDetection]="true"> </full-calendar>
