<loading-spinner [loading]="loading"></loading-spinner>
<div class="pci-detail" *ngIf="!loading">
  <h1>服薬指導予約詳細</h1>
  <app-insurance-check-alert [patientInfo]="patientInfo"></app-insurance-check-alert>
  <table class="pci-detail-table" mat-table [dataSource]="pci ? [pci] : []">
    <ng-container matColumnDef="timeText">
      <th mat-header-cell *matHeaderCellDef>開始日時</th>
      <td mat-cell *matCellDef="let element">{{ element.reservation }}</td>
    </ng-container>

    <ng-container matColumnDef="patientName">
      <th mat-header-cell *matHeaderCellDef>患者名</th>
      <td mat-cell *matCellDef="let element">
        <a class="patient-info-link-global" [routerLink]="'/pharmacist/patients/' + element.patientInfoId">
          {{ element.patientName }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="pharmacistName">
      <th mat-header-cell *matHeaderCellDef>薬剤師名</th>
      <td mat-cell *matCellDef="let element">{{ element.pharmacistName }}</td>
    </ng-container>

    <ng-container matColumnDef="wasConfirmed">
      <th mat-header-cell *matHeaderCellDef>予約確認</th>
      <td mat-cell *matCellDef="let element">{{ element.status | reservation_status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-accordion fxLayoutGap="15px">
    <app-patient-info-accordion [patientInfo]="patientInfo" [isExpanded]="false"></app-patient-info-accordion>
  </mat-accordion>

  <div class="buttons-area">
    <div [formGroup]="selectTemplateGroup">
      <mat-form-field class="format-select" *ngIf="canTemplateChange">
        <mat-label>服薬指導テンプレートを選択</mat-label>
        <mat-select
          [formControlName]="'template'"
          [(value)]="selectedTemplate"
          (selectionChange)="changeTemplate($event)"
        >
          <mat-option *ngFor="let template of pciTemplates" [value]="template">
            {{ template.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!canTemplateChange">
      服薬指導テンプレート確定済み
    </div>
  </div>

  <div [class]="boxClass">
    <div>
      <div class="toggle-buttons" *ngIf="canDisplayQA">
        <mat-button-toggle-group
          class="button-group"
          [value]="viewType"
          (change)="toggleViewType(group.value)"
          #group="matButtonToggleGroup"
        >
          <mat-button-toggle value="default" aria-label="HTML表示"
            >HTML表示<mat-icon>check_box</mat-icon></mat-button-toggle
          >
          <mat-button-toggle value="text" aria-label="テキスト表示"
            >テキスト表示<mat-icon>text_format</mat-icon></mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>

      <div class="template-area" *ngIf="canDisplayQA && viewType === 'default'">
        <div class="buttons-area">
          <button
            class="round-button save-button"
            mat-raised-button
            *ngIf="canSaveQA"
            (click)="savePciQa()"
            aria-label="incomplete meeting"
            color="primary"
            [disabled]="saveQa"
          >
            <mat-icon>save</mat-icon>
            服薬指導QAを保存
          </button>
        </div>
        <qa-template [selectedTemplate]="selectedTemplate" (modelEvent)="qaAnswer = $event"></qa-template>
      </div>
      <div class="qa-text-area" *ngIf="canDisplayQA && viewType === 'text'">
        <app-qa-text></app-qa-text>
      </div>
    </div>

    <div class="payment-area" *ngIf="canDisplayPayment">
      <h1>支払・配送情報</h1>
      <div *ngIf="canCreatePayment" fxLayout="column">
        <mat-form-field class="form-field">
          <mat-label>保険適用内料金</mat-label>
          <input matInput type="text" [formControl]="coveredPriceForm" required />
          <mat-error *ngIf="coveredPriceForm.hasError('pattern')">
            保険適用内料金は数字で入力してください
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field">
          <mat-label>保険適用外料金</mat-label>
          <input matInput type="text" [formControl]="uncoveredPriceForm" required />
          <mat-error *ngIf="uncoveredPriceForm.hasError('pattern')">
            保険適用外料金は数字で入力してください
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field">
          <mat-label>送料</mat-label>
          <div *ngIf="isShippingFeeRequired">
            <input matInput type="text" [formControl]="shippingFeeForm" required />
            <mat-error *ngIf="shippingFeeForm.hasError('pattern')">
              送料は数字で入力してください
            </mat-error>
          </div>
          <div *ngIf="!isShippingFeeRequired">
            <input matInput type="text" [value]="'なし'" readonly />
          </div>
        </mat-form-field>

        <mat-form-field class="form-field">
          <mat-label>支払方法</mat-label>
          <mat-select [(value)]="settlementMethodSelection" matNativeControl required>
            <mat-option value="credit_card">クレジットカード</mat-option>
            <mat-option value="others">その他</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="payment" fxLayout="column">
        <mat-form-field class="form-field">
          <mat-label>保険適用内料金</mat-label>
          <input matInput type="text" [value]="payment.settlement_amount.insurance_covered_price" readonly />
        </mat-form-field>

        <mat-form-field class="form-field">
          <mat-label>保険適用外料金</mat-label>
          <input matInput type="text" [value]="payment.settlement_amount.insurance_uncovered_price" readonly />
        </mat-form-field>

        <mat-form-field class="form-field">
          <mat-label>送料</mat-label>
          <input matInput type="text" [value]="payment.settlement_amount.shipping_fee" readonly />
        </mat-form-field>

        <mat-form-field class="form-field">
          <mat-label>支払方法</mat-label>
          <input matInput type="text" [value]="settlementMethodText" readonly />
        </mat-form-field>
      </div>

      <div fxLayout="column" *ngIf="canDisplayPayment && canCreateDelivery && !delivery">
        <mat-form-field class="form-field">
          <mat-label>配送方法</mat-label>
          <mat-select [(value)]="deliveryMethodSelection" matNativeControl required>
            <mat-option value="normal">通常配送</mat-option>
            <mat-option value="collect_on_delivery">着払い配送</mat-option>
            <mat-option value="drop_by">店舗受取</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" *ngIf="canDisplayPayment && delivery">
        <mat-form-field class="form-field">
          <mat-label>配送方法</mat-label>
          <input matInput type="text" [value]="deliveryMethodText" readonly />
        </mat-form-field>

        <mat-form-field class="form-field">
          <mat-label>配送ステータス</mat-label>
          <input matInput type="text" [value]="deliveryStatusText" readonly />
        </mat-form-field>

        <div class="form-field">
          <mat-label>配送方法決定日時</mat-label>
          {{ pci.original.delivery_method_created_at | date: 'yyyy/MM/dd HH:mm' }}
        </div>

        <div class="form-field">
          <mat-label>配送引き渡し日時</mat-label>
          {{ delivery.shipped_at | date: 'yyyy/MM/dd HH:mm' }}
        </div>
      </div>
    </div>
  </div>

  <div class="buttons-area">
    <button
      class="round-button"
      *ngIf="isMeetingOver && isPharmacistInCharge"
      mat-raised-button
      color="warn"
      fxFlexOffset="10px"
      (click)="complete()"
      [disabled]="!isValidComplete"
    >
      <mat-icon>phonelink_off</mat-icon>
      服薬指導を完了
    </button>

    <button
      class="round-button"
      *ngIf="isMeetingOver && !isPharmacistInCharge"
      mat-raised-button
      color="warn"
      fxFlexOffset="10px"
      [disabled]="true"
    >
      <mat-icon>phonelink_off</mat-icon>
      服薬指導を完了
    </button>

    <button class="round-button" mat-raised-button *ngIf="canCancelMeeting" color="warn" (click)="cancel()">
      <mat-icon> cancel</mat-icon>
      予約キャンセル
    </button>
    <button
      class="round-button"
      *ngIf="canForceEndMeeting"
      mat-raised-button
      color="warn"
      fxFlexOffset="10px"
      (click)="forceEndMeeting()"
    >
      <mat-icon>phonelink_off</mat-icon>
      服薬指導を強制終了
    </button>
    <button
      class="round-button"
      *ngIf="canStartMeeting"
      mat-raised-button
      color="primary"
      fxFlexOffset="auto"
      (click)="implementation()"
    >
      <mat-icon aria-hidden="false" aria-label="logout icon">phonelink</mat-icon>服薬指導を開始
    </button>
  </div>
  <span *ngIf="isMeetingOver && !isPharmacistInCharge">服薬指導を実施した薬剤師でないと服薬指導を完了できません</span>
</div>
