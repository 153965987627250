import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { PcfStatus } from 'src/models/pcf';

@Pipe({ name: 'delivery_method' })
export class DeliveryMethodPiPe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === 'normal',
        _ => '通常配送',
      )
      .on(
        v => v === 'cash_on_delivery',
        _ => '代金引換',
      )
      .on(
        v => v === 'collect_on_delivery',
        _ => '着払い配送',
      )
      .on(
        v => v === 'drop_by',
        _ => '店舗受取',
      )
      .otherwise(v => '不明');
  }
}
