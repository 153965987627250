import { Injectable } from '@angular/core';
import { ICBFTemplate } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class CBFTemplateService extends ServiceBase<ICBFTemplate> {
  propertyName = 'templates';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'cbf_templates');
  }

  public findAll(offset?: number, limit?: number, pharmacy?: string): Promise<ICBFTemplate[]> {
    const params = {
      offset,
      limit,
      pharmacy,
    };
    return super.findAll(params);
  }
}
