import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IPatientInfo } from 'src/models/patient-info';
import { Payment } from 'src/models/payment';
import { Delivery, ReservationForList } from 'src/models/pci';
import { DeliveryMethodPiPe } from 'src/pipes/delivery_method.pipe';
import { DeliveryStatusPiPe } from 'src/pipes/delivery_status.pipe';
import { PaymentService } from 'src/services/api/payment.service';
import { PciService } from 'src/services/api/pci.service';
import { PharmacistService } from 'src/services/api/pharmacist.service';

@Component({
  selector: 'app-payments-detail',
  templateUrl: './payments-detail.component.html',
  styleUrls: ['./payments-detail.component.scss'],
})
export class PaymentsDetailComponent implements OnInit {
  pci!: ReservationForList;
  patientInfo?: IPatientInfo;

  payment: Payment | null = null;
  loading = false;

  // 配送関連
  deliveryMethodSelection: '' | 'normal' | 'cash_on_delivery' | 'drop_by' = '';

  get isCreditPayment(): boolean {
    return this.payment?.settlement_method === 'credit_card';
  }

  get canSettle() {
    return this.payment?.status === 'created' && !this.isCreditPayment;
  }

  get canUnSettle() {
    return this.payment?.status === 'completed' && !this.isCreditPayment;
  }

  get canCancel() {
    return this.payment?.status === 'created' || this.payment?.status === 'started';
  }

  get totalSettlementAmount() {
    return (
      (this.payment?.settlement_amount?.insurance_covered_price ?? 0) +
      (this.payment?.settlement_amount?.insurance_uncovered_price ?? 0) +
      (this.payment?.settlement_amount?.other_price ?? 0) +
      (this.payment?.settlement_amount?.shipping_fee ?? 0)
    );
  }

  get displayPayment(): boolean {
    return this.pci?.status === 'meeting_completed' || this.pci?.status === 'completed';
  }

  get deliveryMethodText(): string {
    return this.deliveryMethodPipe.transform(this.delivery?.method ?? '');
  }

  get delivery(): Delivery | undefined {
    return this.pci.original.delivery;
  }

  get deliveryStatusText(): string {
    return this.deliveryStatusPipe.transform(this.delivery?.status ?? '');
  }

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private pharmacistsService: PharmacistService,

    private pciService: PciService,

    private deliveryMethodPipe: DeliveryMethodPiPe,
    private deliveryStatusPipe: DeliveryStatusPiPe,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      this.payment = await this.fetchPayment();
      this.pci = await this.fetchPci();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchPci() {
    const data = await this.pciService.find(this.payment?.pci_id ?? '');
    const pharmacists = await this.pharmacistsService.findAll();
    if (data === null) {
      throw new Error('fetch pci failed');
    }
    this.patientInfo = data.patient_info;
    return new ReservationForList(data, pharmacists);
  }


  async postDeliveryInfo() {
    if (this.deliveryMethodSelection === '') {
      alert('配送方法を入力してください！');
      return;
    }

    this.loading = true;
    try {
      await this.pciService.postDeliveryInfo(this.pci.id, {
        method: this.deliveryMethodSelection,
        memo_for_pharmacist: this.delivery?.memo_for_pharmacist!,
        //message_to_patient: this.delivery?.message_to_patient!,
      });
      alert('配送情報を登録しました');
      this.pci = await this.fetchPci();
    } finally {
      this.loading = false;
    }
  }

  async shipped() {
    this.loading = true;
    try {
      await this.pciService.shipped(this.pci.id);
      alert('交付済みにしました。');
      this.pci = await this.fetchPci();
    } finally {
      this.loading = false;
    }
  }

  async unshipped() {
    this.loading = true;
    try {
      await this.pciService.unshipped(this.pci.id);
      alert('未交付にしました。');
      this.pci = await this.fetchPci();
    } finally {
      this.loading = false;
    }
  }


  async fetchPayment(): Promise<Payment> {
    const data = await this.paymentService.find(this.route.snapshot.paramMap.get('id') ?? '');
    if (data === null) {
      throw new Error('fetch payment failed');
    }
    return data;
  }

  async settle() {
    if (!this.payment?.id) {
      alert('支払情報が取得できません');
      return;
    }
    if (!confirm('決済済みに変更します。よろしいですか？')) {
      return;
    }
    this.loading = true;
    try {
      await this.paymentService.settle(this.payment.id);
      await new Promise<void>(resolve =>
        setTimeout(() => {
          resolve();
        }, 2000),
      );
      this.payment = await this.fetchPayment();
    } catch (error) {
      console.log(error);
      alert('処理に失敗しました');
    } finally {
      this.loading = false;
    }
  }

  async unsettle() {
    if (!this.payment?.id) {
      alert('支払情報が取得できません');
      return;
    }
    if (!confirm('未決済に変更します。よろしいですか？')) {
      return;
    }
    this.loading = true;
    try {
      await this.paymentService.unsettle(this.payment.id);
      await new Promise<void>(resolve =>
        setTimeout(() => {
          resolve();
        }, 2000),
      );
      this.payment = await this.fetchPayment();
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  async cancel() {
    if (!this.payment?.id) {
      alert('支払情報が取得できません');
      return;
    }
    if (!confirm('決済をキャンセルします。よろしいですか？')) {
      return;
    }
    this.loading = true;
    try {
      await this.paymentService.cancel(this.payment.id);
      await new Promise<void>(resolve =>
        setTimeout(() => {
          resolve();
        }, 2000),
      );
      this.payment = await this.fetchPayment();
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  numberWithComma(num?: number) {
    return num !== null && num !== undefined ? num.toLocaleString() : '0';
  }
}
