import { Component, EventEmitter, HostBinding, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent {
  @Input() loading = false;
  @Input() icon = '';
  @Input() name = '';
  @Input() color = '';
  @Input() disabled = false;
  @HostBinding('style.pointer-events') event = 'none';
}
