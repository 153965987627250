import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { ReservationForList } from 'src/models/pci';
import { displayQa, TemplateQa } from 'src/models/pci-template';
import { IQAT, QuestionType } from 'src/models/qa-template';
import { PciService } from 'src/services/api/pci.service';
import { PharmacistService } from 'src/services/api/pharmacist.service';

@Component({
  selector: 'app-qa-text',
  templateUrl: './qa-text.component.html',
  styleUrls: ['./qa-text.component.scss'],
})
export class QaTextComponent implements OnInit {
  @Output() modelEvent = new EventEmitter();
  qaAnswer: IQAT[] = [];
  pharmacistId = '';
  loading = true;

  constructor(
    private pciService: PciService,
    private route: ActivatedRoute,
    private pharmacistsService: PharmacistService,
    private store: Store,
  ) {}

  async ngOnInit() {
    getLoginSession(this.store)
      .pipe(
        map(s => s.pharmacist),
        filter(isNotNull),
        take(1),
      )
      .subscribe(pharmacist => {
        this.pharmacistId = pharmacist?.id;
      });
    const pci = await this.pciService.find(this.route.snapshot.paramMap.get('pciId') ?? '');
    const pharmacists = await this.pharmacistsService.findAll();

    if (pci === null) {
      throw new Error('fetch pci failed.');
    }

    const reservationForList = new ReservationForList(pci, pharmacists);
    this.qaAnswer = reservationForList.pci_answers ?? [];
    this.loading = false;
  }
}
