<loading-spinner [loading]="loading"></loading-spinner>
<div *ngIf="!loading">
  <h1>患者情報詳細</h1>
  <div class="alert" *ngIf="this.isDeleted">
    <div fxFlexAlign="center">患者情報が削除されたため、詳細情報を表示できません。</div>
  </div>
  <div class="alert" *ngIf="this.isDeactivated && !this.isDeleted">
    <div fxFlexAlign="center">お気に入り薬局から解除されているため、患者の詳細情報を表示できません。</div>
  </div>

  <div class="info-box" *ngIf="!this.isDeleted && !this.isDeactivated">
    <table class="patient-info-table" *ngIf="patientInfo">
      <tr>
        <td>
          {{ patientInfo?.family_name ? patientInfo?.family_name : '' }}
          {{ patientInfo?.given_name ? patientInfo?.given_name : '' }}
          （{{ patientInfo?.family_name_kana ? patientInfo?.family_name_kana : '' }}
          {{ patientInfo?.given_name_kana ? patientInfo?.given_name_kana : '' }}）
        </td>
        <td>
          {{ (patientInfo?.birth_date ? patientInfo?.birth_date : '') | date: 'yyyy年MM月dd日' }}
        </td>
        <td>
          {{ patientInfo?.sex ? (patientInfo?.sex | patient_sex) : '' }}
        </td>
      </tr>
      <tr>
        <td>
          {{ patientInfo?.zip ? (patientInfo?.zip | zip_code) : '' }}
          {{ patientInfo?.prefecture ? patientInfo?.prefecture : '' }}
          {{ patientInfo?.address1 ? patientInfo?.address1 : '' }}
          {{ patientInfo?.address2 ? patientInfo?.address2 : '' }}
          {{ patientInfo?.address3 ? patientInfo?.address3 : '' }}
        </td>
        <td>
          {{ patientInfo?.tel ? patientInfo?.tel : '' }}
        </td>
        <td>
          {{ patientInfo?.email ? patientInfo?.email : '' }}
        </td>
      </tr>
    </table>

    <div *ngIf="paths.length <= 0">
      <mat-card class="no-insurance-card" fxLayout="column" fxLayoutAlign="center">
        保険証・公費受給者証が登録されていません。
      </mat-card>
    </div>
    <mat-accordion *ngIf="0 < paths.length">
      <mat-expansion-panel class="insurance-card">
        <mat-expansion-panel-header>
          <mat-panel-title>保険証・公費受給者証</mat-panel-title>
          <mat-panel-description fxLayout="row-reverse">
            <mat-icon fxFlexAlign="center">badge</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="insurance-card-panel-content">
          <carousel #carousel fxFlexOffset="20px" class="carousel hidden-on-print" [loop]="false" [dots]="true"
            [counter]="true" [cellsToShow]="1" [height]="300" [objectFit]="'contain'" [images]="paths"></carousel>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div *ngIf="haveAlarmData" class="button-box" fxLayout="row-reverse">
    <button class="new-follow-button round-button" fxFlexAlign="center" mat-raised-button color="primary"
      (click)="openAlarmData()">
      服薬状況
    </button>
  </div>

  <div class="boxes-wrapper">
    <div>
      <div fxLayout="row" fxLayoutGap="10px">
        <h3>服薬フォロー履歴</h3>
        <button class="new-follow-button round-button" fxFlexAlign="center" mat-raised-button color="primary"
          (click)="createEmptyFollow()" *ngIf="!this.isDeleted">
          フォロー作成
        </button>
      </div>

      <div class="history-div">
        <div *ngFor="let pcf of pcfs" class="history-detail">
          <mat-accordion>
            <mat-expansion-panel [disabled]="pcf.pcf_answers === undefined || pcf.pcf_answers.length === 0">
              <mat-expansion-panel-header>
                <mat-panel-title class="time">
                  {{ pcf.send_at | date: 'yyyy/MM/dd HH:mm' }}
                  <mat-chip-list>
                    <mat-chip disabled [class]="pcf.status">
                      {{ pcf.status | pcf_status }}
                    </mat-chip>
                  </mat-chip-list>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="qa" *ngFor="let qa of pcf.pcf_answers ? pcf.pcf_answers : []">
                <h5 class="question">{{ qa.question }}</h5>
                <p class="answer" *ngIf="qa.type === 'radio'">{{ qa.answers }}</p>
                <section class="list-section" *ngIf="qa.type === 'checkbox'">
                  <li *ngFor="let ans of qa.answers">
                    {{ ans }}
                  </li>
                </section>
                <p class="answer" *ngIf="qa.type === 'textbox'">{{ qa.answers }}</p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>

    <div>
      <h3>服薬指導履歴</h3>
      <div class="history-div">
        <div *ngFor="let reservation of reservations" class="history-detail">
          <mat-accordion>
            <mat-expansion-panel
              [disabled]="reservation.pci_answers === undefined || reservation.pci_answers.length === 0">
              <mat-expansion-panel-header>
                <mat-panel-title class="time">
                  {{
                  (reservation.time_frames ? reservation.time_frames[0].start_time : '') | date: 'yyyy/MM/dd HH:mm'
                  }}
                  <mat-chip-list>
                    <mat-chip class="reservation_status" disabled [class]="reservation.status">
                      {{ reservation.status | reservation_status }}
                    </mat-chip>
                  </mat-chip-list>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="qa" *ngFor="let qa of reservation.pci_answers ? reservation.pci_answers : []">
                <h5 class="question">{{ qa.question }}</h5>
                <p class="answer" *ngIf="qa.type === 'radio'">{{ qa.answers }}</p>
                <section class="list-section" *ngIf="qa.type === 'checkbox'">
                  <li *ngFor="let ans of qa.answers">
                    {{ ans }}
                  </li>
                </section>
                <p class="answer" *ngIf="qa.type === 'textbox'">{{ qa.answers }}</p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div>
      <h3>処方箋履歴</h3>
      <div class="history-div">
        <div *ngFor="let prescription of prescriptions" class="history-detail">
          <mat-accordion>
            <mat-expansion-panel [disabled]="true">
              <mat-expansion-panel-header class="clickable"
                [routerLink]="'/pharmacist/prescriptions/' + prescription.id" *ngIf="!this.isDeleted">
                <mat-panel-title class="time">
                  {{ prescription.created_at | date: 'yyyy/MM/dd HH:mm' }}
                  <mat-chip-list>
                    <mat-chip disabled [class]="prescription.status">
                      {{ prescription.status | prescription_status }}
                    </mat-chip>
                  </mat-chip-list>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-expansion-panel-header class="clickable" *ngIf="this.isDeleted">
                <mat-panel-title class="time">
                  {{ prescription.created_at | date: 'yyyy/MM/dd HH:mm' }}
                  <mat-chip-list>
                    <mat-chip disabled [class]="prescription.status">
                      {{ prescription.status | prescription_status }}
                    </mat-chip>
                  </mat-chip-list>
                </mat-panel-title>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>