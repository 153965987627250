import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { startOfMonth } from 'date-fns';
import { IPatientInfo } from 'src/models';

@Component({
  selector: 'app-insurance-check-alert',
  templateUrl: './insurance-check-alert.component.html',
  styleUrls: ['./insurance-check-alert.component.scss'],
})
export class InsuranceCheckAlertComponent implements OnInit {
  @HostBinding('style.display') display = 'none';
  @Input() patientInfo?: IPatientInfo;
  constructor(private readonly router: Router) {}

  ngOnInit() {
    if (this.patientInfo) {
      if (!this.confirmedInThisMonth) {
        this.display = '';
      }
    } else {
      this.display = 'none';
    }
  }

  gotoPatientDetail() {
    this.router.navigate(['/pharmacist/patients/' + this.patientInfo?.id]);
  }

  get confirmedInThisMonth() {
    const monthStart = startOfMonth(Date.now()).getTime();
    return [
      ...(this.patientInfo?.certificates ?? []).map(c => (c.patient_confirmed_at ?? 0) > monthStart),
      (this.patientInfo?.insurance_card?.patient_confirmed_at ?? 0) > monthStart,
    ].some(b => b);
  }
}
