import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { first, map } from 'rxjs/operators';
import { loginSessions } from 'src/app/app-store/actions/session.actions';
import { environment } from 'src/environments/environment';
import { IPharmacy } from 'src/models';
import { CognitoService, configure } from 'src/services/cognito.service';
import { PasswordPromptService } from 'src/app/parts/password-prompt/password-prompt.component';
import { filteredPrefectures, prefectures } from 'src/models/prefectures';
import { Observable, of } from 'rxjs';
import { ZipToAddressService } from 'src/services/zip-to-address.service';
import { GeoLocationService } from 'src/services/geo-location.service';
import { PCITemplateService } from 'src/services/api/pci-template.service';
import { QuestionType } from 'src/models/qa-template';
import { MQTemplateService } from 'src/services/api/mq-template.service';

@Component({
  selector: 'app-pharmacy-registration',
  templateUrl: './pharmacy-registration.component.html',
  styleUrls: ['./pharmacy-registration.component.scss'],
})
export class PharmacyRegistrationComponent implements OnInit {
  loading = true;
  fetchingAddress = false;
  fetchingGeoLocation = false;
  readonly pharmacyCodeFormControl = new FormControl('', [Validators.required]);
  readonly companyNameFormControl = new FormControl('');
  readonly storeNameFormControl = new FormControl('', [Validators.required]);
  readonly storeNameKanaFormControl = new FormControl('', [Validators.required, Validators.pattern('^[\u3040-\u309fー・]+$')]);
  readonly zipFormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{7}$')]);
  readonly prefectureFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^(' + prefectures.reduce((acc: string, cur) => acc + cur + '|', '').slice(0, -1) + ')$'),
  ]);
  readonly address1FormControl = new FormControl('', [Validators.required]);
  readonly address2FormControl = new FormControl('', [Validators.required]);
  readonly address3FormControl = new FormControl('');
  readonly emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  readonly webFormControl = new FormControl('');
  readonly telFormControl = new FormControl('', [Validators.required, Validators.pattern('^0[0-9]{9,10}$')]);
  readonly faxFormControl = new FormControl('', [Validators.required, Validators.pattern('^0[0-9]{9,10}$')]);
  readonly latitudeFormControl = new FormControl('', [Validators.pattern('^[+-]?[0-9]+.[0-9]+([eE][+-]?[0-9]+)?$'), Validators.min(-90), Validators.max(90)]);
  readonly longitudeFormControl = new FormControl('', [Validators.pattern('^[+-]?[0-9]+.[0-9]+([eE][+-]?[0-9]+)?$'), Validators.min(-180), Validators.max(180)]);
  readonly unitTimeFormControl = new FormControl(15, [Validators.required]);
  isParkingAvailable = false;
  businessHours = '';
  freeComment = '';

  private cognitoId = '';
  private id = '';
  filteredPrefectures: Observable<string[]> = of([]);
  private readonly exteriorImageFileReader = new FileReader();
  exteriorImages: string[] = [];
  private readonly interiorImageFileReader = new FileReader();
  interiorImages: string[] = [];
  private readonly logoImageFileReader = new FileReader();
  logoImage?: string;
  private readonly acceptableImageTypes = ['image/png', 'image/jpeg'];

  get areAllFormsValid() {
    return [
      this.pharmacyCodeFormControl,
      this.companyNameFormControl,
      this.storeNameFormControl,
      this.storeNameKanaFormControl,
      this.zipFormControl,
      this.prefectureFormControl,
      this.address1FormControl,
      this.address2FormControl,
      this.address3FormControl,
      this.webFormControl,
      this.emailFormControl,
      this.telFormControl,
      this.longitudeFormControl,
      this.latitudeFormControl,
      this.unitTimeFormControl,
    ].every(c => c.valid);
  }

  constructor(
    private readonly store: Store,
    private readonly cognito: CognitoService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly passwordPromptService: PasswordPromptService,
    private readonly zipToAddressService: ZipToAddressService,
    private readonly geoLocationService: GeoLocationService,
    private readonly pciTemplateService: PCITemplateService,
    private readonly mqTemplateService: MQTemplateService,
  ) {
    this.filteredPrefectures = this.prefectureFormControl.valueChanges.pipe(map((v: string) => filteredPrefectures(v)));
    this.exteriorImageFileReader.onload = event => {
      if (typeof event.target?.result === 'string') {
        this.exteriorImages.push(event.target.result);
      }
    };
    this.interiorImageFileReader.onload = event => {
      if (typeof event.target?.result === 'string') {
        this.interiorImages.push(event.target.result);
      }
    };
    this.logoImageFileReader.onload = event => {
      if (typeof event.target?.result === 'string') {
        this.logoImage = event.target.result;
      }
    };
    this.exteriorImageFileReader.onerror = error => console.log(error);
    this.interiorImageFileReader.onerror = error => console.log(error);
    this.logoImageFileReader.onerror = error => console.log(error);
  }

  async ngOnInit() {
    this.loading = true;
    [this.cognitoId, this.id] = await this.route.queryParams
      .pipe(first())
      .toPromise()
      .then(result => [result.name ?? '', result.id ?? ''])
      .catch(_ => ['', '']);
    if (!this.cognitoId || !this.id) {
      this.router.navigate(['index']);
    }
    this.loading = false;
    console.log(this.id, this.cognitoId);
  }

  dropExteriorImage(event: any) {
    if (this.acceptableImageTypes.includes(event.target.files[0].type ?? '')) {
      this.exteriorImageFileReader.readAsDataURL(event.target.files[0]);
    }
    event.target.value = '';
  }

  dropInteriorImage(event: any) {
    if (this.acceptableImageTypes.includes(event.target.files[0].type ?? '')) {
      this.interiorImageFileReader.readAsDataURL(event.target.files[0]);
    }
    event.target.value = '';
  }

  dropLogoImage(event: any) {
    if (this.acceptableImageTypes.includes(event.target.files[0].type ?? '')) {
      this.logoImageFileReader.readAsDataURL(event.target.files[0]);
    }
    event.target.value = '';
  }

  removeExteriorImage(index: number) {
    this.exteriorImages.splice(index, 1);
  }

  removeInteriorImage(index: number) {
    this.interiorImages.splice(index, 1);
  }

  removeLogoImage() {
    this.logoImage = undefined;
  }

  async getAddress() {
    this.fetchingAddress = true;
    configure('pharmacy');
    await this.zipToAddressService
      .getAddress(this.zipFormControl.value as string)
      .then(result => {
        if (result.notFound) {
          alert('該当する住所が見つかりませんでした。');
          return;
        }
        this.prefectureFormControl.setValue(result.prefecture);
        this.address1FormControl.setValue(result.address);
        this.address2FormControl.setValue(result.address2);
      })
      .catch(error => {
        console.log(error);
        return;
      });
    this.fetchingAddress = false;
  }

  async getGeoLocation() {
    this.fetchingGeoLocation = true;
    const address =
      this.prefectureFormControl.value +
      this.address1FormControl.value +
      this.address2FormControl.value +
      this.address3FormControl.value;
    await this.geoLocationService
      .getGeoLocation(address)
      .then(result => {
        if (result.notFound) {
          alert('入力された住所から緯度経度が入力できませんでした。');
          return;
        }
        this.latitudeFormControl.setValue(result.latitude);
        this.longitudeFormControl.setValue(result.longitude);
      })
      .catch(error => {
        console.log(error);
        return;
      });
    this.fetchingGeoLocation = false;
  }

  async register() {
    this.loading = true;
    configure('pharmacy');
    const password = (await this.passwordPromptService.prompt()) ?? '';
    try {
      await Auth.signIn(this.cognitoId, password);
    } catch {
      this.loading = false;
      alert('パスワードが違います。');
      return;
    }
    const token = await this.cognito.getAccessToken();
    const config = {
      headers: {
        Authorization: token.getJwtToken(),
        'Content-Type': 'application/json',
      },
    };
    const baseUrl = environment.api_base_url;
    try {
      const data: Omit<IPharmacy, 'is_active' | 'activated_at'> = {
        id: this.id,
        pharmacy_code: this.pharmacyCodeFormControl.value,
        company_name: this.companyNameFormControl.value,
        store_name: this.storeNameFormControl.value,
        store_name_kana: this.storeNameKanaFormControl.value,
        zip: this.zipFormControl.value,
        prefecture: this.prefectureFormControl.value,
        address1: this.address1FormControl.value,
        address2: this.address2FormControl.value,
        address3: this.address3FormControl.value,
        tel: this.telFormControl.value,
        fax: this.faxFormControl.value,
        is_parking_available: this.isParkingAvailable,
        business_hours: this.businessHours,
        email: this.emailFormControl.value,
        web: this.webFormControl.value,
        location: {
          latitude: Number(this.latitudeFormControl.value),
          longitude: Number(this.longitudeFormControl.value),
        },
        pci_slot_interval: this.unitTimeFormControl.value,
        free_comment: this.freeComment,
      };
      await axios.post<IPharmacy>(`${baseUrl}pharmacy/pharmacies`, data, config);
      for (const image of this.exteriorImages) {
        await axios.post(
          environment.api_base_url + `pharmacy/pharmacies/${this.id}/image`,
          { image: image.replace(new RegExp('data:image/.*;base64,'), ''), image_type: 'exterior' },
          config,
        );
      }
      for (const image of this.interiorImages) {
        await axios.post(
          environment.api_base_url + `pharmacy/pharmacies/${this.id}/image`,
          { image: image.replace(new RegExp('data:image/.*;base64,'), ''), image_type: 'interior' },
          config,
        );
      }
      if (this.logoImage) {
        await axios.post(
          environment.api_base_url + `pharmacy/pharmacies/${this.id}/logo`,
          { image: this.logoImage.replace(new RegExp('data:image/.*;base64,'), '') },
          config,
        );
      }
      // デフォルトの服薬指導テンプレートを登録
      await axios.post(
        environment.api_base_url + `pharmacy/pci_templates`,
        {
          pharmacy_id: this.id,
          name: 'default',
          qa: [{
            question: "服薬指導メモ",
            answers: [],
            required: false,
            type: QuestionType.textbox,
          }],
        },
        config,
      );
      // 問診票のテンプレートを登録
      /*
      await axios.post(
        environment.api_base_url + `pharmacy/mq_template`,
        {
          pharmacy_id: this.id,
          qa: [{
            required: true,
            question: 'お薬をお受取りに来られるのはいつですか？',
            answers: ['いまから', '本日中', '明日以降'],
            type: QuestionType.radio,
          }],
        },
        config,
      );
      */
    } catch (error) {
      alert('情報の登録に失敗しました。');
      console.log(error);
    }
    this.store.dispatch(loginSessions({ email: this.cognitoId, password, type: 'pharmacy' }));
    this.loading = false;
  }
}
