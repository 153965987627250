<loading-spinner *ngIf="loading"></loading-spinner>
<div class="root" *ngIf="!loading">
  <h1>服薬フォローテンプレート</h1>
  <div class="add-button-area" fxLayout="row-reverse" fxLayoutAlign="space-between">
    <button class="round-button" mat-raised-button fxFlexAlign="center" color="primary" (click)="createFolder()">
      <mat-icon>create_new_folder</mat-icon>
      新規グループ作成
    </button>
    <button
      class="reload-button"
      mat-icon-button
      fxFlexAlign="center"
      fxLayout="row"
      fxLayoutAlign="center"
      (click)="reloadEvent()"
    >
      <mat-icon>cached</mat-icon>
    </button>
  </div>

  <div class="boxes-wrapper" cdkDropListGroup>
    <div class="history-div">
      <mat-accordion class="template-groups" multi>
        <mat-expansion-panel class="template-group" *ngFor="let groupName of groupNames">
          <mat-expansion-panel-header class="template-group-header">
            <mat-panel-title>{{ groupName }}</mat-panel-title>
            <mat-panel-description fxLayout="row-reverse">
              <mat-icon fxFlexAlign="center">topic</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div
            class="template-group-content"
            cdkDropList
            #templateList="cdkDropList"
            [cdkDropListData]="groupName"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="dropEvent($event)"
          >
            <div class="template-panel-header" fxLayout="row">
              <div fxFlexAlign="center">テンプレート名</div>
            </div>
            <div
              class="template-panel"
              *ngFor="let template of templateGroups[groupName]"
              cdkDrag
              [cdkDragData]="template"
              fxLayout="row"
              routerLink="/pharmacy/pcf-templates/{{ template.id }}"
            >
              <div fxFlexAlign="center">{{ template.name }}</div>
            </div>
          </div>
          <mat-action-row>
            <button
              *ngIf="groupName !== 'その他のテンプレート'"
              mat-button
              color="warn"
              (click)="deleteFolder(groupName)"
            >
              グループの削除
            </button>
            <button mat-button color="primary" (click)="createTemplate(groupName)">
              新規テンプレートの作成
            </button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="dragdrop"><img class="dragdrop" src="/assets/one-way-arrow.png" alt="arrow" /></div>
    <div class="history-div">
      <div fxLayout="row" fxLayoutGap="10px">
        <h5>服薬フォロー履歴</h5>
      </div>

      <div cdkDropList #templateList="cdkDropList" [cdkDropListData]="historyName" cdkDropListSortingDisabled>
        <div *ngFor="let pcf of followsForDisplay" class="history-detail" cdkDrag>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title class="time">
                  送信日時:{{ pcf.send_at | date: 'yyyy/MM/dd HH:mm' }}, 薬剤師名:{{
                    getPharmacistName(pcf.pharmacist)
                  }}, 患者名:{{ getPatientName(pcf.patient_info) }}
                  <!-- <mat-chip-list>
                    <mat-chip disabled [class]="pcf.status">
                      {{ pcf.status | pcf_status }}
                    </mat-chip>
                  </mat-chip-list> -->
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="qa" *ngFor="let qa of pcf.pcf_questions ? pcf.pcf_questions : []">
                <h5 class="question">{{ qa.question }}</h5>
                <p class="answer" *ngIf="qa.type === 'radio'">{{ qa.answers }}</p>
                <section class="list-section" *ngIf="qa.type === 'checkbox'">
                  <li *ngFor="let ans of qa.answers">
                    {{ ans }}
                  </li>
                </section>
                <!-- <p class="answer" *ngIf="qa.type === 'textbox'">{{ qa.answers }}</p> -->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
