<div class="tooltip-root">
  <div class="text title">{{ data[0].title }}</div>
  <mat-divider class="divider" *ngIf="data.length > 0"></mat-divider>
  <div class="text">{{ data[0].instructionStatus | reservation_status }}</div>
  <mat-divider class="divider"></mat-divider>
  <div class="row-wrap" *ngFor="let row of data; index as i">
    <div class="row" [class.highlight]="row.selected && data.length > 1" fxLayout="row">
      <div fxFlexAlign="center" class="fc-daygrid-event-dot" [style.borderColor]="row.backgroundColor"></div>
      <div class="text">{{ dateToString(row.start, row.end) }}</div>
    </div>
    <mat-divider class="divider" *ngIf="i < data.length - 1"></mat-divider>
  </div>
</div>
