import { when } from 'src/app/modules/when';
import { IQAT, IQATemplate, QuestionType } from './qa-template';

export interface IPCITemplate extends IQATemplate {
  id: string;
  pharmacy_id: string;
  name?: string;
  qa?: IQAT[];
}

export type displayQa = {
  type: QuestionType;
  question: string;
  answers: string[];
  checkBox: { label: string; checked: boolean }[];
  required: boolean;
};

export class TemplateQa {
  displayQa: displayQa[];
  constructor(questionTemplate: IQAT[], type: 'template' | 'answered' | 'newAnswer', answered: IQAT[] = []) {
    const template = answered.length !== 0 ? answered : questionTemplate;
    this.displayQa = template.map((qat, index) => {
      const checkBox = when(qat.type)
        .on(
          t => t === QuestionType.checkbox && answered.length !== 0,
          _ =>
            questionTemplate[index].answers.map(label => ({
              label,
              checked: answered[index].answers.includes(label),
            })),
        )
        .on(
          t => t === QuestionType.checkbox && answered.length === 0,
          _ =>
            questionTemplate[index].answers?.map(label => ({
              label,
              checked: false,
            })) ?? [],
        )
        .otherwise(_ => []);
      const answers: string[] = when(qat.answers)
        .on(
          qa =>
            qa.length === 0 ||
            type === 'newAnswer' ||
            (qat.type === QuestionType.radio && qat.answers.length !== 1 && type === 'answered'),
          _ => [],
        )
        .otherwise(qa => qa);

      return {
        type: qat.type,
        question: qat.question,
        answers,
        checkBox,
        required: qat.required,
      };
    });
  }
}
