import { Injectable } from '@angular/core';
import { IMedicalQuestionnaireTemplate, IPharmacy } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { IQAT, QuestionType } from 'src/models/qa-template';

@Injectable({
  providedIn: 'root',
})
export class MQTemplateService extends ServiceBase<IMedicalQuestionnaireTemplate> {
  propertyName = 'mq_template';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'mq_template');
  }

  public findAll(offset?: number, limit?: number, pharmacy?: string): Promise<IMedicalQuestionnaireTemplate[]> {
    const params = {
      offset,
      limit,
      pharmacy,
    };
    return super.findAll(params);
  }

  public async updateMedicalQuestionnaire(medicalQuestionnaire: IQAT[]): Promise<IPharmacy> {
    const data: {
      required: boolean;
      type: QuestionType;
      question: string;
      answers: string[];
    }[] = medicalQuestionnaire.map(q => ({
      required: q.required,
      type: q.type,
      question: q.question,
      answers: q.answers,
    }));
    return (
      await axios.put(
        environment.api_base_url + 'pharmacy/mq_template',
        data,
        await this.buildConfig(undefined, 'application/json'),
      )
    ).data as IPharmacy;
  }
}
