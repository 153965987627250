import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { PatientSex } from 'src/models/patient-info';

@Pipe({ name: 'patient_sex' })
export class PatientSexPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === PatientSex.male,
        _ => '男性',
      )
      .on(
        v => v === PatientSex.female,
        _ => '女性',
      )
      .on(
        v => v === PatientSex.other,
        _ => 'その他',
      )
      .otherwise(_ => '');
  }
}
