<loading-spinner [loading]="loading"></loading-spinner>
<div fxLayout="column" *ngIf="!loading">
  <div class="top-area" fxLayout="row" fxLayoutAlign="space-between">
    <mat-form-field>
      <mat-label>テンプレート名</mat-label>
      <input matInput [(ngModel)]="name" />
    </mat-form-field>
    <div>
      <loading-button
        [name]="this.templateId === 'new-template' ? '新規作成' : '変更を保存'"
        color="primary"
        (click)="save()"
        [loading]="loading"
      ></loading-button>
    </div>
  </div>
  <div class="question-container" cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="question-card" cdkDrag [cdkDragStartDelay]="0" *ngFor="let question of questions; index as i">
      <div *cdkDragPlaceholder>
        <span><mat-icon>south</mat-icon> ここに移動</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div class="handle" cdkDragHandle><mat-icon>drag_indicator</mat-icon></div>
        <div class="question-display" *ngIf="!isBeingEdited[i]; else edit">
          <div class="question-type">
            <mat-chip-list>
              <mat-chip *ngIf="question.required" class="chip-required" color="accent" selected>必須</mat-chip>
              <mat-chip *ngIf="question.type === 'radio'" class="chip-radio">1つ選択</mat-chip>
              <mat-chip *ngIf="question.type === 'checkbox'" class="chip-check">複数選択</mat-chip>
              <mat-chip *ngIf="question.type === 'textbox'" class="chip-text">自由記述</mat-chip>
            </mat-chip-list>
          </div>
          <div class="question-question">
            {{ question.question ? question.question : '( 質問文なし )' }}
            <ul *ngIf="question.type === 'radio' || question.type === 'checkbox'" class="question-answers">
              <li *ngFor="let choice of question.answers">{{ choice ? choice : '( 選択肢名なし )' }}</li>
            </ul>
          </div>
        </div>
        <ng-template #edit>
          <div class="question-edit" fxLayout="column">
            <div class="question-type-select" fxLayout="column">
              <mat-checkbox class="required-check" [(ngModel)]="question.required">必須</mat-checkbox>
              <div>
                <mat-label class="type-label">回答形式</mat-label>
                <mat-button-toggle-group [(ngModel)]="question.type">
                  <mat-button-toggle value="radio">1つ選択</mat-button-toggle>
                  <mat-button-toggle value="checkbox">複数選択</mat-button-toggle>
                  <mat-button-toggle value="textbox">自由記述</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div>
              <mat-form-field>
                <mat-label>質問文を入力</mat-label>
                <textarea class="question-textarea" matInput [(ngModel)]="question.question"></textarea>
              </mat-form-field>
            </div>
            <div
              class="choice-edit"
              *ngIf="question.type === 'radio' || question.type === 'checkbox'"
              fxLayout="column"
              fxLayoutAlign="center"
            >
              <mat-divider class="divider"></mat-divider>
              <div>
                <div *ngFor="let choice of question.answers; index as j; trackBy: choiceTrackBy" fxLayout="row">
                  <mat-form-field>
                    <mat-label>選択肢を入力</mat-label>
                    <input matInput type="text" [(ngModel)]="question.answers[j]" />
                  </mat-form-field>
                  <button mat-icon-button (click)="removeChoice(i, j)" [disabled]="question.answers.length < 2">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
              <button mat-stroked-button (click)="addChoice(i)">
                <mat-icon>add_circle_outline</mat-icon> 選択肢を追加
              </button>
            </div>
          </div>
        </ng-template>
        <div class="palette" fxLayout="column">
          <button mat-stroked-button (click)="editQuestion(i)">
            <ng-container *ngIf="!isBeingEdited[i]; else done">
              <mat-icon>edit</mat-icon><span class="icon-description"> 編集</span>
            </ng-container>
            <ng-template #done>
              <ng-container><mat-icon>done</mat-icon><span class="icon-description"> 完了</span></ng-container>
            </ng-template>
          </button>
          <button mat-stroked-button color="warn" (click)="removeQuestion(i)">
            <mat-icon>delete</mat-icon><span class="icon-description"> 削除</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="add-question-button-wrap">
    <button class="add-question-button" mat-stroked-button (click)="addQuestion()">
      <mat-icon>add_circle_outline</mat-icon> 質問を追加
    </button>
  </div>
</div>
