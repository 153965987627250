<loading-spinner [loading]="loading"></loading-spinner>
<div *ngIf="!loading" class="root">
  <h1>受信処方箋画像詳細</h1>
  <div fxLayout="column" fxLayoutGap="15px">
    <app-insurance-check-alert [patientInfo]="prescription?.patient_info"></app-insurance-check-alert>
    <div
      *ngIf="prescription?.status === 'sent' && prescription?.cancelled_at === undefined"
      fxLayout="row-reverse"
      fxLayoutGap="10px"
    >
      <button class="round-button" mat-raised-button (click)="confirm()" color="primary">
        <mat-icon>save_alt</mat-icon> 受領
      </button>
      <button class="round-button" mat-raised-button (click)="openCancelDialog()" color="warn">
        <mat-icon>undo</mat-icon> キャンセル
      </button>
      <button class="round-button" mat-raised-button (click)="openRequestResendDialog()" color="note">
        <mat-icon>swap_vert</mat-icon>
        再送要求
      </button>
      <button class="round-button" mat-raised-button (click)="openRejectDialog()" color="warn">
        <mat-icon>undo</mat-icon> 拒否
      </button>
    </div>
    <div
      *ngIf="prescription?.status === 'rejected' && prescription?.cancelled_at === undefined"
      fxLayout="row-reverse"
      fxLayoutGap="10px"
    >
      <button class="round-button" mat-raised-button (click)="cancel()" color="warn">
        <mat-icon>undo</mat-icon> キャンセル
      </button>
    </div>
    <div
      *ngIf="prescription?.status === 'req_resend' && prescription?.cancelled_at === undefined"
      fxLayout="row-reverse"
      fxLayoutGap="10px"
    >
      <button class="round-button" mat-raised-button (click)="cancel()" color="warn">
        <mat-icon>undo</mat-icon> キャンセル
      </button>
    </div>
    <div
      *ngIf="prescription?.status === 'confirmed' && prescription?.cancelled_at === undefined"
      fxLayout="row-reverse"
      fxLayoutGap="10px"
    >
      <button class="round-button" mat-raised-button (click)="cancel()" color="warn">
        <mat-icon>undo</mat-icon> キャンセル
      </button>
      <button class="round-button" mat-raised-button (click)="dispensed()" color="primary">
        <mat-icon>done</mat-icon> 調剤完了
      </button>
    </div>
    <div
      *ngIf="prescription?.status === 'dispensed' && prescription?.cancelled_at === undefined"
      fxLayout="row-reverse"
      fxLayoutGap="10px"
    >
      <button class="round-button" mat-raised-button (click)="cancel()" color="warn">
        <mat-icon>undo</mat-icon> キャンセル
      </button>
      <button class="round-button" mat-raised-button (click)="complete()" color="primary">交付済み</button>
    </div>
    <div
      *ngIf="prescription?.cancelled_at !== undefined && prescription?.pharmacist_confirmed_at === undefined"
      fxLayout="row-reverse"
      fxLayoutGap="10px"
    >
      <button class="round-button" mat-raised-button (click)="pharmacistConfirm()" color="primary">確認済み</button>
    </div>
    <div class="prescription-progress" fxLayout="column" fxLayoutGap="30px" fxFlexOffset="30px">
      <div fxFlexAlign="stretch" fxLayout="column" fxLayoutGap="0px">
        <mat-progress-bar
          class="progress-bar"
          [value]="onNormalFlow ? (prescription?.status | prescription_progress) : 50"
          [color]="onNormalFlow ? 'primary' : 'accent'"
        ></mat-progress-bar>
        <mat-chip-list class="chip-list">
          <mat-chip
            [color]="'primary'"
            [selected]="prescription?.status === 'sent'"
            [style.right]="'calc(' + (100 - ('sent' | prescription_progress)) + '% - 5px)'"
            [disabled]="
              prescription?.status === 'confirmed' ||
              prescription?.status === 'dispensed' ||
              prescription?.status === 'completed' ||
              prescription?.status === 'req_resend' ||
              prescription?.status === 'rejected'
            "
          >
            受信
          </mat-chip>
          <mat-chip
            *ngIf="onNormalFlow"
            [color]="'primary'"
            [selected]="prescription?.status === 'confirmed' && prescription?.cancelled_at === undefined"
            [style.right]="'calc(' + (100 - ('confirmed' | prescription_progress)) + '% - 5px)'"
            [disabled]="prescription?.status === 'dispensed' || prescription?.status === 'completed'"
          >
            {{ 'confirmed' | prescription_status }}
          </mat-chip>
          <mat-chip
            *ngIf="onNormalFlow"
            [color]="'primary'"
            [selected]="prescription?.status === 'dispensed' && prescription?.cancelled_at === undefined"
            [style.right]="'calc(' + (100 - ('dispensed' | prescription_progress)) + '% - 5px)'"
            [disabled]="prescription?.status === 'completed'"
          >
            {{ 'dispensed' | prescription_status }}
          </mat-chip>
          <mat-chip
            *ngIf="onNormalFlow"
            [color]="'primary'"
            [selected]="prescription?.status === 'completed' && prescription?.cancelled_at === undefined"
            [style.right]="'calc(' + (100 - ('completed' | prescription_progress)) + '% - 5px)'"
          >
            {{ 'completed' | prescription_status }}
          </mat-chip>
          <mat-chip
            class="accent state-chip-resend"
            *ngIf="prescription?.status === 'req_resend' && prescription?.cancelled_at === undefined"
            [color]="'accent'"
            [selected]="prescription?.status === 'req_resend'"
          >
            {{ 'req_resend' | prescription_status }}
          </mat-chip>
          <mat-chip
            class="accent state-chip-reject"
            *ngIf="prescription?.status === 'rejected' && prescription?.cancelled_at === undefined"
            [color]="'accent'"
            [selected]="prescription?.status === 'rejected'"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            {{ 'rejected' | prescription_status }}
          </mat-chip>
          <mat-chip
            class="accent state-chip-cancelled"
            *ngIf="prescription?.cancelled_at !== undefined"
            [color]="'accent'"
            [selected]="prescription?.cancelled_at !== undefined"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            {{ 'キャンセル' }}
          </mat-chip>
        </mat-chip-list>
        <div class="timestamps">
          <div
            class="timestamp"
            [style.right]="'calc(' + (100 - ('sent' | prescription_progress)) + '% - 5px)'"
            fxLayout="column"
          >
            <div>{{ prescription?.created_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.created_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="
              prescription?.confirmed_at !== undefined &&
              prescription?.cancelled_at === undefined &&
              (prescription?.status === 'confirmed' ||
                prescription?.status === 'dispensed' ||
                prescription?.status === 'completed')
            "
            class="timestamp"
            [style.right]="'calc(' + (100 - ('confirmed' | prescription_progress)) + '% - 5px)'"
            fxLayout="column"
          >
            <div>{{ prescription?.confirmed_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.confirmed_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="
              prescription?.dispensed_at !== undefined &&
              prescription?.cancelled_at === undefined &&
              (prescription?.status === 'dispensed' || prescription?.status === 'completed')
            "
            class="timestamp"
            [style.right]="'calc(' + (100 - ('dispensed' | prescription_progress)) + '% - 5px)'"
            fxLayout="column"
          >
            <div>{{ prescription?.dispensed_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.dispensed_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="
              prescription?.completed_at !== undefined &&
              prescription?.status === 'completed' &&
              prescription?.cancelled_at === undefined
            "
            class="timestamp"
            [style.right]="'calc(' + (100 - ('completed' | prescription_progress)) + '% - 5px)'"
            fxLayout="column"
          >
            <div>{{ prescription?.completed_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.completed_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="
              prescription?.req_resend_at !== undefined &&
              prescription?.status === 'req_resend' &&
              prescription?.cancelled_at === undefined
            "
            class="timestamp timestamp-resend"
            fxLayout="column"
          >
            <div>{{ prescription?.req_resend_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.req_resend_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div
            *ngIf="
              prescription?.rejected_at !== undefined &&
              prescription?.status === 'rejected' &&
              prescription?.cancelled_at === undefined
            "
            class="timestamp timestamp-reject"
            fxLayout="column"
          >
            <div>{{ prescription?.rejected_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.rejected_at | date: 'HH:mm:ss' }}</div>
          </div>
          <div *ngIf="prescription?.cancelled_at !== undefined" class="timestamp timestamp-cancelled" fxLayout="column">
            <div>{{ prescription?.cancelled_at | date: 'yyyy/MM/dd' }}</div>
            <div class="flushright">{{ prescription?.cancelled_at | date: 'HH:mm:ss' }}</div>
          </div>
        </div>
      </div>
    </div>
    <mat-accordion fxLayout="column" fxLayoutGap="15px" fxFlexOffset="85px" multi>
      <app-patient-info-accordion
        [patientInfo]="prescription?.patient_info"
        [isExpanded]="false"
      ></app-patient-info-accordion>
      <mat-expansion-panel class="message-to-patient">
        <mat-expansion-panel-header>
          <mat-panel-title>患者へのメッセージ</mat-panel-title>
          <mat-panel-description fxLayout="row-reverse">
            <mat-icon fxFlexAlign="center">sms</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="message-panel-content">
          {{ prescription?.message ? prescription?.message : '( メッセージが設定されていません )' }}
        </div>
        <mat-action-row>
          <button mat-button color="primary" (click)="openMessageEditDialog()">
            メッセージの編集
          </button>
        </mat-action-row>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true" class="medical-questionnaire">
        <mat-expansion-panel-header>
          <mat-panel-title>問診票</mat-panel-title>
          <mat-panel-description fxLayout="row-reverse">
            <mat-icon fxFlexAlign="center">quiz</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="questionnaire-panel-content" fxLayout="column">
          <div class="questionnaire-row" *ngFor="let question of prescription?.mq_questions; index as i" fxLayout="row">
            <div fxFlex="50" class="question" fxLayout="column" *ngIf="isDisp(i)">
              <div>{{ question.question }}</div>
              <div *ngFor="let choice of question.answers">・{{ choice }}</div>
            </div>
            <div fxFlex="50" class="answer" *ngIf="isDisp(i)">
              {{ getAnswer(i) }}
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div fxLayout="row-reverse" fxFlexOffset="20px" *ngIf="imageExist">
      <button mat-stroked-button class="print-button" (click)="print()">
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-icon fxFlexAlign="center">print</mat-icon>
          <div fxFlexAlign="center">処方箋画像を印刷</div>
        </div>
      </button>
    </div>
    <carousel
      #carousel
      fxFlexOffset="20px"
      class="carousel hidden-on-print"
      [loop]="false"
      [dots]="true"
      [counter]="true"
      [cellsToShow]="1"
      [height]="900"
      [objectFit]="'contain'"
      [images]="imagesForCarousel"
      *ngIf="imageExist"
    ></carousel>
    <span *ngIf="!imageExist">処方箋画像は保存期間(180日)が過ぎているため削除されています。</span>
  </div>
</div>

<div *ngFor="let image of images" class="on-print print-image-wrap">
  <img class="on-print print-image" [src]="image" />
  <br />
  <span class="print-message">
    患者名: {{ patientName }}<br />
    受信日時: {{ prescription?.created_at | date: 'yyyy/MM/dd HH:mm:ss' }}<br />
    問診回答: {{ getAnswerForPrint() }}
  </span>
</div>
