import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { IPharmacy } from 'src/models';
import { filteredPrefectures, prefectures } from 'src/models/prefectures';
import { PharmacyService } from 'src/services/api/pharmacy.service';
import { GeoLocationService } from 'src/services/geo-location.service';
import { ZipToAddressService } from 'src/services/zip-to-address.service';

@Component({
  selector: 'app-pharmacy-profile',
  templateUrl: './pharmacy-profile.component.html',
  styleUrls: ['./pharmacy-profile.component.scss'],
})
export class PharmacyProfileComponent implements OnInit {
  pharmacyCode = '';
  readonly companyNameFormControl = new FormControl('');
  readonly storeNameFormControl = new FormControl('', [Validators.required]);
  readonly storeNameKanaFormControl = new FormControl('', [Validators.required, Validators.pattern('^[\u3040-\u309fー・]+$')]);
  readonly zipFormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{7}$')]);
  readonly prefectureFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^(' + prefectures.reduce((acc: string, cur) => acc + cur + '|', '').slice(0, -1) + ')$'),
  ]);
  readonly address1FormControl = new FormControl('', [Validators.required]);
  readonly address2FormControl = new FormControl('', [Validators.required]);
  readonly address3FormControl = new FormControl('');
  readonly telFormControl = new FormControl('', [Validators.required, Validators.pattern('^0[0-9]{9,10}$')]);
  readonly faxFormControl = new FormControl('', [Validators.required, Validators.pattern('^0[0-9]{9,10}$')]);
  readonly emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  readonly webFormControl = new FormControl('');
  readonly latitudeFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[+-]?[0-9]+[.][0-9]+([eE][+-]?[0-9]+)?$'),
    Validators.min(-90),
    Validators.max(90),
  ]);
  readonly longitudeFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[+-]?[0-9]+[.][0-9]+([eE][+-]?[0-9]+)?$'),
    Validators.min(-180),
    Validators.max(180),
  ]);
  readonly unitTimeFormControl = new FormControl('', [Validators.required]);
  isParkingAvailable = false;
  businessHours = '';
  freeComment = '';

  private pharmacyId = '';
  private pharmacy?: IPharmacy;
  private isSaved = false;
  loading = true;
  fetchingAddress = false;
  fetchingGeoLocation = false;
  isActive = false;
  filteredPrefectures: Observable<string[]> = of([]);
  private readonly exteriorImageFileReader = new FileReader();
  exteriorImages: { source: string; newlyUploaded: boolean }[] = [];
  deletingExteriorImageSources: string[] = [];
  private readonly interiorImageFileReader = new FileReader();
  interiorImages: { source: string; newlyUploaded: boolean }[] = [];
  deletingInteriorImageSources: string[] = [];
  private readonly logoImageFileReader = new FileReader();
  logoImage?: { source: string; newlyUploaded: boolean };
  deletingLogoImageSource?: string;
  private readonly acceptableImageTypes = ['image/png', 'image/jpeg'];

  constructor(
    private readonly store: Store,
    private readonly pharmacyService: PharmacyService,
    private readonly zipToAddressService: ZipToAddressService,
    private readonly geoLocationService: GeoLocationService,
  ) {
    this.filteredPrefectures = this.prefectureFormControl.valueChanges.pipe(map((v: string) => filteredPrefectures(v)));
    this.exteriorImageFileReader.onload = event => {
      if (typeof event.target?.result === 'string') {
        this.exteriorImages.push({ source: event.target.result, newlyUploaded: true });
      }
    };
    this.interiorImageFileReader.onload = event => {
      if (typeof event.target?.result === 'string') {
        this.interiorImages.push({ source: event.target.result, newlyUploaded: true });
      }
    };
    this.logoImageFileReader.onload = event => {
      if (typeof event.target?.result === 'string') {
        this.logoImage = { source: event.target.result, newlyUploaded: true };
      }
    };
    this.exteriorImageFileReader.onerror = error => console.log(error);
    this.interiorImageFileReader.onerror = error => console.log(error);
    this.logoImageFileReader.onerror = error => console.log(error);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.pharmacy = await getLoginSession(this.store)
      .pipe(
        filter(isNotNull),
        first(),
        map(s => s.pharmacy ?? undefined),
      )
      .toPromise();
    this.pharmacyId = this.pharmacy?.id ?? '';
    try {
      await this.fetchProfile();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchProfile() {
    const data = await this.pharmacyService.find(this.pharmacyId);
    if (data) {
      this.pharmacyCode = data.pharmacy_code;
      this.companyNameFormControl.setValue(data.company_name);
      this.storeNameFormControl.setValue(data.store_name);
      this.storeNameKanaFormControl.setValue(data.store_name_kana.trim());
      this.zipFormControl.setValue(data.zip ?? '');
      this.prefectureFormControl.setValue(data.prefecture ?? '');
      this.address1FormControl.setValue(data.address1 ?? '');
      this.address2FormControl.setValue(data.address2 ?? '');
      this.address3FormControl.setValue(data.address3 ?? '');
      this.telFormControl.setValue(data.tel ?? '');
      this.faxFormControl.setValue(data.fax ?? '');
      this.emailFormControl.setValue(data.email ?? '');
      this.webFormControl.setValue(data.web ?? '');
      this.latitudeFormControl.setValue(data.location?.latitude ?? '');
      this.longitudeFormControl.setValue(data.location?.longitude ?? '');
      this.unitTimeFormControl.setValue(data.pci_slot_interval ?? 15);
      this.isParkingAvailable = data.is_parking_available ?? false;
      this.businessHours = data.business_hours ?? '';
      this.freeComment = data.free_comment ?? '';
      this.isActive = data.is_active === 1;
      this.exteriorImages = (data.images?.exterior ?? []).map(url => ({ source: url, newlyUploaded: false }));
      this.interiorImages = (data.images?.interior ?? []).map(url => ({ source: url, newlyUploaded: false }));
      this.logoImage = data.logo ? { source: data.logo, newlyUploaded: false } : undefined;
    }
  }

  get areAllFormsValid() {
    return [
      this.companyNameFormControl,
      this.storeNameFormControl,
      this.storeNameKanaFormControl,
      this.zipFormControl,
      this.prefectureFormControl,
      this.address1FormControl,
      this.address2FormControl,
      this.address3FormControl,
      this.telFormControl,
      this.faxFormControl,
      this.emailFormControl,
      this.webFormControl,
      this.latitudeFormControl,
      this.longitudeFormControl,
    ].every(c => c.valid);
  }

  get noMqTemplate() {
    return this.pharmacy?.medical_questionnaire === null || this.pharmacy?.medical_questionnaire === undefined;
  }

  dropExteriorImage(event: any) {
    if (this.acceptableImageTypes.includes(event.target.files[0].type ?? '')) {
      this.exteriorImageFileReader.readAsDataURL(event.target.files[0]);
    }
    event.target.value = '';
  }

  dropInteriorImage(event: any) {
    if (this.acceptableImageTypes.includes(event.target.files[0].type ?? '')) {
      this.interiorImageFileReader.readAsDataURL(event.target.files[0]);
    }
    event.target.value = '';
  }

  dropLogoImage(event: any) {
    if (this.acceptableImageTypes.includes(event.target.files[0].type ?? '')) {
      this.logoImageFileReader.readAsDataURL(event.target.files[0]);
    }
    event.target.value = '';
  }

  removeExteriorImage(index: number) {
    if (!this.exteriorImages[index].newlyUploaded) {
      this.deletingExteriorImageSources.push(this.exteriorImages[index].source);
    }
    this.exteriorImages.splice(index, 1);
  }

  removeInteriorImage(index: number) {
    if (!this.interiorImages[index].newlyUploaded) {
      this.deletingInteriorImageSources.push(this.interiorImages[index].source);
    }
    this.interiorImages.splice(index, 1);
  }

  removeLogoImage() {
    if (this.logoImage && !this.logoImage.newlyUploaded) {
      this.deletingLogoImageSource = this.logoImage.source;
    }
    this.logoImage = undefined;
  }

  async getAddress() {
    this.fetchingAddress = true;
    await this.zipToAddressService
      .getAddress(this.zipFormControl.value as string)
      .then(result => {
        if (result.notFound) {
          alert('該当する住所が見つかりませんでした。');
          return;
        }
        this.prefectureFormControl.setValue(result.prefecture);
        this.address1FormControl.setValue(result.address);
        this.address2FormControl.setValue(result.address2);
      })
      .catch(error => {
        console.log(error);
        return;
      });
    this.fetchingAddress = false;
  }

  async getGeoLocation() {
    this.fetchingGeoLocation = true;
    const address =
      this.prefectureFormControl.value +
      this.address1FormControl.value +
      this.address2FormControl.value +
      this.address3FormControl.value;
    await this.geoLocationService
      .getGeoLocation(address)
      .then(result => {
        if (result.notFound) {
          alert('入力された住所から緯度経度が入力できませんでした。');
          return;
        }
        this.latitudeFormControl.setValue(result.latitude);
        this.longitudeFormControl.setValue(result.longitude);
      })
      .catch(error => {
        console.log(error);
        return;
      });
    this.fetchingGeoLocation = false;
  }

  async save() {
    if (!confirm('この内容で登録しますか？')) {
      return;
    }
    this.loading = true;
    this.disableAllForms();
    try {
      await this.pharmacyService.update({
        id: this.pharmacyId,
        company_name: this.companyNameFormControl.value,
        store_name: this.storeNameFormControl.value,
        store_name_kana: this.storeNameKanaFormControl.value,
        zip: this.zipFormControl.value,
        prefecture: this.prefectureFormControl.value,
        address1: this.address1FormControl.value,
        address2: this.address2FormControl.value,
        address3: this.address3FormControl.value,
        tel: this.telFormControl.value,
        fax: this.faxFormControl.value,
        is_parking_available: this.isParkingAvailable,
        business_hours: this.businessHours,
        email: this.emailFormControl.value,
        web: this.webFormControl.value,
        location: {
          latitude: Number(this.latitudeFormControl.value),
          longitude: Number(this.longitudeFormControl.value),
        },
        pci_slot_interval: this.unitTimeFormControl.value,
        free_comment: this.freeComment,
      });
      for (const image of this.exteriorImages.filter(i => i.newlyUploaded)) {
        await this.pharmacyService.uploadImage(
          this.pharmacyId,
          image.source.replace(new RegExp('data:image/.*;base64,'), ''),
          'exterior',
        );
      }
      for (const source of this.deletingExteriorImageSources) {
        await this.pharmacyService.deleteImage(this.pharmacyId, source);
      }
      for (const image of this.interiorImages.filter(i => i.newlyUploaded)) {
        await this.pharmacyService.uploadImage(
          this.pharmacyId,
          image.source.replace(new RegExp('data:image/.*;base64,'), ''),
          'interior',
        );
      }
      for (const source of this.deletingInteriorImageSources) {
        await this.pharmacyService.deleteImage(this.pharmacyId, source);
      }
      if (this.logoImage) {
        if (this.logoImage.newlyUploaded) {
          await this.pharmacyService.updateLogo(
            this.pharmacyId,
            this.logoImage.source.replace(new RegExp('data:image/.*;base64,'), ''),
          );
        }
      } else {
        if (this.deletingLogoImageSource) {
          await this.pharmacyService.deleteLogo(this.pharmacyId);
        }
      }

      this.isSaved = true;
      alert('登録されました');
      await this.fetchProfile();
    } catch (error) {
      alert('登録に失敗しました');
      throw new Error('');
    } finally {
      this.loading = false;
      this.enableAllForms();
    }
  }

  async activatePharmacy() {
    this.loading = true;
    this.isSaved = false;
    if (!confirm('この薬局を公開しますか？公開すると、患者アプリの検索結果に表示されるようになります。')) {
      this.loading = false;
      return;
    }
    try {
      if (!this.areAllFormsValid) {
        alert('入力されていない項目があります！すべての項目を入力しないと公開できません。');
        return;
      }
      await this.save();
      if (!this.isSaved) {
        return;
      }
      await this.pharmacyService.activate(this.pharmacyId);
      this.isActive = true;
    } catch (error) {
      console.log(error);
      alert('薬局を公開できませんでした');
    } finally {
      this.loading = false;
    }
  }

  async deactivatePharmacy() {
    if (!confirm('この薬局を無効化しますか？')) {
      return;
    }
    this.loading = true;
    try {
      await this.pharmacyService.deactivate(this.pharmacyId);
      this.isActive = false;
    } catch (error) {
      console.log(error);
      alert('薬局を無効化できませんでした');
    } finally {
      this.loading = false;
    }
  }

  private disableAllForms() {
    [
      this.companyNameFormControl,
      this.storeNameFormControl,
      this.storeNameKanaFormControl,
      this.zipFormControl,
      this.prefectureFormControl,
      this.address1FormControl,
      this.address2FormControl,
      this.address3FormControl,
      this.telFormControl,
      this.faxFormControl,
      this.emailFormControl,
      this.webFormControl,
      this.latitudeFormControl,
      this.longitudeFormControl,
      this.unitTimeFormControl,
    ].forEach(c => c.disable());
  }
  private enableAllForms() {
    [
      this.companyNameFormControl,
      this.storeNameFormControl,
      this.storeNameKanaFormControl,
      this.zipFormControl,
      this.prefectureFormControl,
      this.address1FormControl,
      this.address2FormControl,
      this.address3FormControl,
      this.telFormControl,
      this.faxFormControl,
      this.emailFormControl,
      this.webFormControl,
      this.latitudeFormControl,
      this.longitudeFormControl,
      this.unitTimeFormControl,
    ].forEach(c => c.enable());
  }
}
