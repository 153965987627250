// base modules
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// material css framework modules
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { OverlayModule } from '@angular/cdk/overlay';

// components
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { HeaderComponent, HeaderNotificationsOverlayComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CardComponent } from './parts/card/card.component';
import { LoginComponent as PharmacyLoginComponent } from './pharmacy/login/login.component';
import { IndexComponent as PharmacyIndexComponent } from './pharmacy/index/index.component';
import { PharmacistDetailDialog, PharmacistsComponent } from './pharmacy/pharmacists/pharmacists.component';
import { LoginComponent as PharmacistLoginComponent } from './pharmacist/login/login.component';
import { IndexComponent as PharmacistIndexComponent } from './pharmacist/index/index.component';
import { ReservationListComponent } from './pharmacist/reservation-list/reservation-list.component';
import { ReservationDetailComponent } from './pharmacist/reservation-detail/reservation-detail.component';
import { ImplementationComponent } from './pharmacist/implementation/implementation.component';
import { AppStoreModule } from './app-store/app-store.module';
import { HttpClientModule } from '@angular/common/http';
import { ReservationStatusPipe } from '../pipes/reservation_status.pipe';
import { ReservationClassPipe } from 'src/pipes/reservation_class.pipe';
import { PharmacyService } from 'src/services/api/pharmacy.service';
import { PatientInfoService } from 'src/services/api/patient-info.service';
import { PatientAccountService } from 'src/services/api/patient-account.service';
import { CBFTemplateService } from 'src/services/api/cbf-template.service';
import { PCFTemplateService } from 'src/services/api/pcf-template.service';
import { PCITemplateService } from 'src/services/api/pci-template.service';
import { MQTemplateService } from 'src/services/api/mq-template.service';
import { DispensingStatusService } from 'src/services/api/dispensing-status.service';
import { ReservationSlotService } from 'src/services/api/reservation-slot.service';
import { ChatbotStatusPipe } from 'src/pipes/chatbot_status.pipe';
import { DispensingStatusPipe } from 'src/pipes/dispensing_status.pipe';
import { HealthInsuranceCardService } from 'src/services/api/health-ins-card.service';
import { QuestionnaireService } from 'src/services/api/questionnaire.service';
import { ChatComponent } from './parts/chat/chat.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CognitoService } from 'src/services/cognito.service';
import { PatientsListComponent } from './pharmacist/patients-list/patients-list.component';
import { PatientsDetailComponent } from './pharmacist/patients-detail/patients-detail.component';
import { PatientSexPipe } from 'src/pipes/patient_sex.pipe';
import { CalendarEntryColorPipe } from 'src/pipes/calendar_entry_color.pipe';
import { PrescriptionsComponent } from './pharmacist/prescriptions/prescriptions.component';
import { PrescriptionService } from 'src/services/api/prescription.service';
import { PrescriptionInfoService } from 'src/services/api/prescription-info.service';
import {
  PrescriptionDetailComponent,
  PrescriptionConfirmDialog,
  PrescriptionRejectDialog,
  PrescriptionRequestResendDialog,
  PrescriptionMessageEditDialog,
  PrescriptionCancelDialog,
} from './pharmacist/prescriptions/detail/detail.component';
import { PrescriptionStatusPipe } from 'src/pipes/prescription_status.pipe';
import { PrescriptionProgressPipe } from 'src/pipes/prescription_progress.pipe';
import { DatePipe, registerLocaleData } from '@angular/common';
import { FormFieldComponent } from './parts/form-field/form-field.component';
import { ReservationSlotsComponent } from './pharmacy/reservation-slots/reservation-slots.component';
import { ReservationSlotDetailComponent } from './pharmacy/reservation-slots/detail/reservation-slot-detail.component';
import { SidenavComponent } from './parts/sidenav/sidenav.component';
import { PcfTemplateGroupNameDialog, PcfTemplatesComponent } from './pharmacy/pcf-templates/pcf-templates.component';
import { PcfTemplateDetailComponent } from './pharmacy/pcf-templates/pcf-template-detail/pcf-template-detail.component';
import { LoadingSpinnerComponent } from './parts/loading-spinner/loading-spinner.component';
import { LoadingButtonComponent } from './parts/loading-button/loading-button.component';
import { CbfTemplatesComponent } from './pharmacy/cbf-templates/cbf-templates.component';
import { CbfTemplateDetailComponent } from './pharmacy/cbf-templates/cbf-template-detail/cbf-template-detail.component';
import { CalendarDetailDialog, CalendarComponent } from './pharmacist/reservation-list/calendar/calendar.component';
import { ListDetailDialog, ListComponent } from './pharmacist/reservation-list/list/list.component';
import { HoverTooltipComponent } from './parts/hover-tooltip/hover-tooltip.component';
import { PharmacyRegistrationComponent } from './pharmacy/pharmacy-registration/pharmacy-registration.component';
import { PasswordPromptComponent } from './parts/password-prompt/password-prompt.component';
import { MeetingEndDialogComponent } from './pharmacist/implementation/meeting-end-dialog/meeting-end-dialog.component';
import { FollowListComponent, FollowDetailDialog } from './pharmacist/follow/follow-list.component';
import { FollowBaseSelectComponent } from './pharmacist/follow/follow-base-select/follow-base-select.component';
import { NewFollowComponent } from './pharmacist/follow/new-follow/new-follow.component';
import { PharmacyProfileComponent } from './pharmacy/pharmacy-profile/pharmacy-profile.component';
import {
  PciTemplateListComponent,
  PciTemplateDetailDialog,
} from './pharmacist/pci-template-list/pci-template-list.component';
import { MedicalQuestionnaireTemplateComponent } from './pharmacy/medical-questionnaire-template/medical-questionnaire-template.component';
import { QuestionnaireEditorComponent } from './parts/questionnaire-editor/questionnaire-editor.component';
import { QaTemplateComponent } from './parts/qa-template/qa-template.component';
import { PciTemplatesComponent } from './pharmacy/pci-templates/pci-templates.component';
import { PciTemplateDetailComponent } from './pharmacy/pci-templates/pci-template-detail/pci-template-detail.component';
import { CheckboxListComponent } from './parts/checkbox-list/checkbox-list.component';
import { NewArrivalService } from 'src/services/new-arrival.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import localeJa from '@angular/common/locales/ja';
import { InsuranceCheckAlertComponent } from './parts/insurance-check-alert/insurance-check-alert.component';
import { PharmacistPasswordChangeComponent } from './pharmacist/pharmacist-password-change/pharmacist-password-change.component';
import { PharmacyPasswordChangeComponent } from './pharmacy/pharmacy-password-change/pharmacy-password-change.component';
import { PcfStatusPipe } from 'src/pipes/pcf_status.pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/services/notification.service';
import { CustomPaginatorComponent } from './parts/custom-paginator/custom-paginator.component';
import { PatientInfoAccordionComponent } from './parts/patient-info-accordion/patient-info-accordion.component';
import { QaTextComponent } from './parts/qa-text/qa-text.component';
import { TopButtonComponent } from './parts/top-button/top-button.component';
import { PaymentSettingComponent } from './pharmacy/payment-setting/payment-setting.component';
import { PaymentsListComponent } from './pharmacist/payments-list/payments-list.component';
import { PaymentsDetailComponent } from './pharmacist/payments-detail/payments-detail.component';
import { SettlementMethodPipe } from 'src/pipes/settlement_method.pipe';
import { PaymentStatusPipe } from 'src/pipes/payment_status.pipe';
import { DeliveryMethodPiPe } from 'src/pipes/delivery_method.pipe';
import { DeliveryStatusPiPe } from 'src/pipes/delivery_status.pipe';
import { PaymentProgressPipe } from 'src/pipes/payment_progress.pipe';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ZipCodePipe } from 'src/pipes/zip-code.pipe';
import { AlarmDataComponent } from './pharmacist/alarm-data/alarm-data.component';
import { MedicationTimingPipe } from 'src/pipes/medication_timing.pipe';
import { MedicationMethodFrequencyPipe } from 'src/pipes/medication_method_frequency.pipe';
import { CertErrorComponent } from './cert-error/cert-error.component';

const SERVICES = [
  ReservationSlotService,
  PatientInfoService,
  PharmacyService,
  PharmacyService,
  PatientAccountService,
  CBFTemplateService,
  PCFTemplateService,
  PCITemplateService,
  MQTemplateService,
  DispensingStatusService,
  HealthInsuranceCardService,
  QuestionnaireService,
  CognitoService,
  PatientAccountService,
  PrescriptionService,
  PrescriptionInfoService,
  NewArrivalService,
  NotificationService,
];

FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin, interactionPlugin]);

registerLocaleData(localeJa);

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HeaderComponent,
    FooterComponent,
    PharmacyLoginComponent,
    PharmacyIndexComponent,
    CardComponent,
    PharmacistsComponent,
    PharmacistDetailDialog,
    PharmacistLoginComponent,
    PharmacistIndexComponent,
    ReservationListComponent,
    ReservationDetailComponent,
    ImplementationComponent,
    ReservationStatusPipe,
    ReservationClassPipe,
    ChatComponent,
    PatientsListComponent,
    PatientsDetailComponent,
    PatientSexPipe,
    CalendarEntryColorPipe,
    PrescriptionsComponent,
    PrescriptionDetailComponent,
    PrescriptionConfirmDialog,
    PrescriptionRejectDialog,
    PrescriptionRequestResendDialog,
    PrescriptionCancelDialog,
    PrescriptionStatusPipe,
    PrescriptionProgressPipe,
    ChatbotStatusPipe,
    FormFieldComponent,
    ReservationSlotsComponent,
    SidenavComponent,
    PcfTemplatesComponent,
    PcfTemplateGroupNameDialog,
    PcfTemplateDetailComponent,
    LoadingSpinnerComponent,
    LoadingButtonComponent,
    CbfTemplatesComponent,
    CbfTemplateDetailComponent,
    CalendarComponent,
    ListComponent,
    ListDetailDialog,
    CalendarDetailDialog,
    HoverTooltipComponent,
    PharmacyRegistrationComponent,
    PasswordPromptComponent,
    MeetingEndDialogComponent,
    FollowListComponent,
    FollowDetailDialog,
    FollowBaseSelectComponent,
    NewFollowComponent,
    PharmacyProfileComponent,
    PciTemplateListComponent,
    PciTemplateDetailDialog,
    MedicalQuestionnaireTemplateComponent,
    QuestionnaireEditorComponent,
    QaTemplateComponent,
    PciTemplatesComponent,
    PciTemplateDetailComponent,
    ReservationSlotDetailComponent,
    CheckboxListComponent,
    HeaderNotificationsOverlayComponent,
    InsuranceCheckAlertComponent,
    PharmacistPasswordChangeComponent,
    PrescriptionMessageEditDialog,
    PharmacyPasswordChangeComponent,
    PcfStatusPipe,
    CustomPaginatorComponent,
    PatientInfoAccordionComponent,
    QaTextComponent,
    TopButtonComponent,
    PaymentSettingComponent,
    PaymentsListComponent,
    PaymentsDetailComponent,
    SettlementMethodPipe,
    PaymentStatusPipe,
    PaymentProgressPipe,
    DeliveryMethodPiPe,
    DeliveryStatusPiPe,
    ZipCodePipe,
    AlarmDataComponent,
    MedicationTimingPipe,
    MedicationMethodFrequencyPipe,
    CertErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DragDropModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatTableModule,
    MatDialogModule,
    AppStoreModule,
    HttpClientModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    FullCalendarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatChipsModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    MatRippleModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTabsModule,
    DragDropModule,
    OverlayModule,
    IvyCarouselModule,
    OverlayModule,
    MatSnackBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    NgxSliderModule,
  ],
  providers: [
    ...SERVICES,
    { provide: LOCALE_ID, useValue: 'ja-JP' },
    { provide: APP_INITIALIZER, useFactory: notificationServiceInit, deps: [NotificationService], multi: true },
    ReservationStatusPipe,
    ReservationClassPipe,
    ChatbotStatusPipe,
    DispensingStatusPipe,
    PatientSexPipe,
    CalendarEntryColorPipe,
    DatePipe,
    PrescriptionStatusPipe,
    PrescriptionProgressPipe,
    PcfStatusPipe,
    SettlementMethodPipe,
    PaymentStatusPipe,
    PaymentProgressPipe,
    DeliveryMethodPiPe,
    DeliveryStatusPiPe,
    ZipCodePipe,
    MedicationTimingPipe,
    MedicationMethodFrequencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
export function notificationServiceInit() {
  return () => { };
}
