import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';
import { when } from 'src/app/modules/when';
import { IReservationSlot } from 'src/models';
import { ITimeframe } from 'src/models/reservation-slot';
import { ReservationSlotService } from 'src/services/api/reservation-slot.service';
import { endOfMonth } from 'date-fns';

type FrequencyType =
  | 'once'
  | 'every_year'
  | 'every_month'
  | 'every_week'
  | 'every_day'
  | 'every_2_weeks'
  | 'every_3_weeks'
  | 'every_4_weeks'
  | null;

type MonthType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;

type DateType =
  | number
  | '1st'
  | '2nd'
  | '3rd'
  | '4th'
  | '5th'
  | 'in_1st_week'
  | 'in_2nd_week'
  | 'in_3rd_week'
  | 'in_4th_week'
  | 'in_5th_week'
  | null;

type SlotForEdit = {
  type: 'open' | 'close';
  frequency: FrequencyType;
  month: MonthType;
  date: DateType;
  day: 0 | 1 | 2 | 3 | 4 | 5 | 6 | null;
  startDate: Date | null;
  startTime: string;
  endTime: string;
};

@Component({
  selector: 'app-reservation-slot-detail',
  templateUrl: './reservation-slot-detail.component.html',
  styleUrls: ['./reservation-slot-detail.component.scss'],
})
export class ReservationSlotDetailComponent implements OnInit {
  loading = true;
  slotId = '';
  pharmacyId = '';
  reservationSlot?: IReservationSlot | null;
  slotsForEdit: SlotForEdit[] = [];
  readonly nameFormControl = new FormControl('', [Validators.required]);
  readonly priorityFormControl = new FormControl('', [Validators.required, Validators.pattern('([0-9]|[1-9][0-9])')]);
  readonly hours = Array(24)
    .fill(0)
    .map((_, i) => i);
  readonly daySuffix = (dateType: DateType | FrequencyType): string =>
    when(dateType)
      .on(
        t => t === '1st',
        _ => '@1',
      )
      .on(
        t => t === '2nd',
        _ => '@2',
      )
      .on(
        t => t === '3rd',
        _ => '@3',
      )
      .on(
        t => t === '4th',
        _ => '@4',
      )
      .on(
        t => t === '5th',
        _ => '@5',
      )
      .on(
        t => t === 'in_1st_week',
        _ => '^1',
      )
      .on(
        t => t === 'in_2nd_week',
        _ => '^2',
      )
      .on(
        t => t === 'in_3rd_week',
        _ => '^3',
      )
      .on(
        t => t === 'in_4th_week',
        _ => '^4',
      )
      .on(
        t => t === 'in_5th_week',
        _ => '^5',
      )
      .on(
        t => t === 'every_2_weeks',
        _ => '/2',
      )
      .on(
        t => t === 'every_3_weeks',
        _ => '/3',
      )
      .on(
        t => t === 'every_4_weeks',
        _ => '/4',
      )
      .otherwise(_ => '');

  readonly datePickerFilter = (d: Date | null) => {
    if (!d) {
      return false;
    }
    return d.getTime() > new Date().getTime() - 86400000;
  };

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private reservationSlotService: ReservationSlotService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.slotId = await this.route.queryParams
      .pipe(
        first(),
        map(params => (params.slotId ? (params.slotId as string) : '')),
      )
      .toPromise();
    this.pharmacyId = await getLoginSession(this.store)
      .pipe(
        first(),
        map(s => s.pharmacy?.id ?? ''),
      )
      .toPromise();
    if (this.slotId) {
      try {
        this.reservationSlot = await this.reservationSlotService.find(this.slotId);
      } catch (error) {
        console.log(error);
      } finally {
        if (!this.reservationSlot) {
          console.log('reservation slot not found');
          this.router.navigate(['pharmacy/reservation-slots/detail']);
        } else {
          const parsedTimeFrames = this.parseTimeFrames(this.reservationSlot.timeframes);
          console.log('timeframes parsing result:', parsedTimeFrames);
          this.slotsForEdit = parsedTimeFrames;
          this.nameFormControl.setValue(this.reservationSlot.name);
          this.priorityFormControl.setValue(this.reservationSlot.priority);
          this.loading = false;
        }
      }
    } else {
      this.loading = false;
    }
    if (this.slotsForEdit.length === 0) {
      this.addSlot();
    }
  }

  addSlot() {
    this.slotsForEdit.push(
      this.slotsForEdit.length === 0
        ? {
            type: 'open',
            frequency: null,
            month: null,
            date: null,
            day: null,
            startDate: null,
            startTime: '00:00',
            endTime: '24:00',
          }
        : {
            type: this.slotsForEdit[this.slotsForEdit.length - 1].type,
            frequency: this.slotsForEdit[this.slotsForEdit.length - 1].frequency,
            month: this.slotsForEdit[this.slotsForEdit.length - 1].month,
            date: null,
            day: null,
            startDate: null,
            startTime: this.slotsForEdit[this.slotsForEdit.length - 1].startTime,
            endTime: this.slotsForEdit[this.slotsForEdit.length - 1].endTime,
          },
    );
  }

  frequencyChange(index: number, event: FrequencyType) {
    const slot = this.slotsForEdit[index];
    this.slotsForEdit[index] = {
      type: slot.type,
      frequency: event,
      month: null,
      date: null,
      day: null,
      startDate: null,
      startTime: slot.startTime,
      endTime: slot.endTime,
    };
  }

  monthChange(index: number, event: MonthType) {
    const slot = this.slotsForEdit[index];
    this.slotsForEdit[index] = {
      type: slot.type,
      frequency: slot.frequency,
      month: event,
      date: null,
      day: null,
      startDate: null,
      startTime: slot.startTime,
      endTime: slot.endTime,
    };
  }

  startTimeChange(index: number, event: string) {
    const slot = this.slotsForEdit[index];
    if (event > slot.endTime) {
      slot.startTime = slot.endTime;
      slot.endTime = event;
    } else {
      slot.startTime = event;
    }
  }

  endTimeChange(index: number, event: string) {
    const slot = this.slotsForEdit[index];
    if (event < slot.startTime) {
      slot.endTime = slot.startTime;
      slot.startTime = event;
    } else {
      slot.endTime = event;
    }
  }

  removeSlot(index: number) {
    this.slotsForEdit.splice(index, 1);
  }

  availableDates(month: MonthType) {
    if (!month) {
      return Array(31)
        .fill(0)
        .map((_, i) => i + 1);
    } else {
      return Array(endOfMonth(new Date(2004, month - 1, 1)).getDate())
        .fill(0)
        .map((_, i) => i + 1);
    }
  }

  displayMonthSelector(slot: SlotForEdit) {
    return slot.frequency === 'every_year';
  }

  displayDateSelector(slot: SlotForEdit) {
    return (slot.frequency === 'every_year' && slot.month) || slot.frequency === 'every_month';
  }

  displayDaySelector(slot: SlotForEdit) {
    return (
      (slot.frequency === 'every_year' && slot.month && typeof slot.date === 'string') ||
      (slot.frequency === 'every_month' && typeof slot.date === 'string') ||
      slot.frequency === 'every_week'
    );
  }

  displayStartDateSelector(slot: SlotForEdit) {
    return slot.frequency && ['once', 'every_2_weeks', 'every_3_weeks', 'every_4_weeks'].includes(slot.frequency);
  }

  displayTimeSelector(slot: SlotForEdit) {
    if (
      slot.frequency === 'every_year' &&
      slot.month &&
      slot.date &&
      (slot.day !== null || typeof slot.date === 'number')
    ) {
      return true;
    } else if (slot.frequency === 'every_month' && slot.date && (slot.day !== null || typeof slot.date === 'number')) {
      return true;
    } else if (slot.frequency === 'every_week' && slot.day !== null) {
      return true;
    } else if (slot.frequency === 'every_day') {
      return true;
    } else if (
      slot.frequency &&
      (slot.frequency === 'once' ||
        slot.frequency === 'every_2_weeks' ||
        slot.frequency === 'every_3_weeks' ||
        slot.frequency === 'every_4_weeks') &&
      slot.startDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  get areAllFormsValid() {
    return (
      this.nameFormControl.valid &&
      this.priorityFormControl.valid &&
      this.slotsForEdit.every(s => this.displayTimeSelector(s))
    );
  }

  async save() {
    if (!confirm('この内容で保存しますか？')) {
      return;
    }
    this.loading = true;
    try {
      if (this.slotId) {
        await this.reservationSlotService.update({
          id: this.slotId,
          name: this.nameFormControl.value,
          priority: this.priorityFormControl.value,
          timeframes: this.timeFrames,
        });
      } else {
        await this.reservationSlotService.create({
          pharmacy_id: this.pharmacyId,
          name: this.nameFormControl.value,
          priority: this.priorityFormControl.value,
          timeframes: this.timeFrames,
        });
      }
      alert('保存しました');
      this.router.navigate(['pharmacy/reservation-slots']);
    } catch (error) {
      console.log(error);
      alert('登録に失敗しました');
    } finally {
      this.loading = false;
    }
  }

  async delete() {
    if (!confirm('この予約可能枠を削除しますか？')) {
      return;
    }
    this.loading = true;
    try {
      await this.reservationSlotService.remove(this.slotId);
      alert('削除しました');
      this.router.navigate(['pharmacy/reservation-slots']);
    } catch (error) {
      console.log(error);
      alert('削除に失敗しました');
    } finally {
      this.loading = false;
    }
  }

  private createTimeFrame(
    type: 'open' | 'close',
    start: string,
    end: string,
    year: string = '*',
    month: string = '*',
    date: string = '*',
    day: string = '*',
    timezone: string = '+9',
  ) {
    return { type, year, month, date, day, timezone, start, end };
  }

  private get timeFrames() {
    return this.slotsForEdit.reduce(
      (acc: ITimeframe[], cur: SlotForEdit) =>
        when(cur.frequency)
          .on<ITimeframe[]>(
            f => f === 'once',
            _ => {
              if (!cur.startDate) {
                return acc;
              }
              return [
                ...acc,
                this.createTimeFrame(
                  cur.type,
                  cur.startTime,
                  cur.endTime,
                  cur.startDate.getFullYear().toString(),
                  (cur.startDate.getMonth() + 1).toString(),
                  cur.startDate.getDate().toString(),
                  cur.startDate.getDay().toString(),
                ),
              ];
            },
          )
          .on<ITimeframe[]>(
            f => f === 'every_year',
            _ => {
              if (cur.month === null || !cur.date) {
                return acc;
              }
              if (typeof cur.date === 'number') {
                return [
                  ...acc,
                  this.createTimeFrame(
                    cur.type,
                    cur.startTime,
                    cur.endTime,
                    undefined,
                    cur.month.toString(),
                    cur.date.toString(),
                  ),
                ];
              }
              if (cur.day === null) {
                return acc;
              }
              return [
                ...acc,
                this.createTimeFrame(
                  cur.type,
                  cur.startTime,
                  cur.endTime,
                  undefined,
                  cur.month.toString(),
                  undefined,
                  cur.day.toString() + this.daySuffix(cur.date),
                ),
              ];
            },
          )
          .on<ITimeframe[]>(
            f => f === 'every_month',
            _ => {
              if (!cur.date) {
                return acc;
              }
              if (typeof cur.date === 'number') {
                return [
                  ...acc,
                  this.createTimeFrame(cur.type, cur.startTime, cur.endTime, undefined, undefined, cur.date.toString()),
                ];
              }
              if (cur.day === null) {
                return acc;
              }
              return [
                ...acc,
                this.createTimeFrame(
                  cur.type,
                  cur.startTime,
                  cur.endTime,
                  undefined,
                  undefined,
                  undefined,
                  cur.day.toString() + this.daySuffix(cur.date),
                ),
              ];
            },
          )
          .on<ITimeframe[]>(
            f => f === 'every_week',
            _ => {
              if (cur.day === null) {
                return acc;
              }
              return [
                ...acc,
                this.createTimeFrame(
                  cur.type,
                  cur.startTime,
                  cur.endTime,
                  undefined,
                  undefined,
                  undefined,
                  cur.day.toString(),
                ),
              ];
            },
          )
          .on<ITimeframe[]>(
            f => f === 'every_day',
            _ => {
              return [...acc, this.createTimeFrame(cur.type, cur.startTime, cur.endTime)];
            },
          )
          .on<ITimeframe[]>(
            f => !!f && ['every_2_weeks', 'every_3_weeks', 'every_4_weeks'].includes(f),
            _ => {
              if (!cur.startDate) {
                return acc;
              }
              return [
                ...acc,
                this.createTimeFrame(
                  cur.type,
                  cur.startTime,
                  cur.endTime,
                  cur.startDate.getFullYear().toString(),
                  (cur.startDate.getMonth() + 1).toString(),
                  cur.startDate.getDate().toString(),
                  cur.startDate.getDay().toString() + this.daySuffix(cur.frequency),
                ),
              ];
            },
          )
          .otherwise<ITimeframe[]>(_ => acc),
      [],
    );
  }

  private parseTimeFrames(timeframes: ITimeframe[]) {
    return timeframes.reduce((acc: SlotForEdit[], cur: ITimeframe, index: number): SlotForEdit[] => {
      const type: 'open' | 'close' | null = cur.type === 'open' ? 'open' : cur.type === 'close' ? 'close' : null;
      const year = new RegExp('^([0-9]{1,4}|[*])$').test(cur.year) ? cur.year : null;
      const month = new RegExp('^(1[0-2]|[1-9]|[*])$').test(cur.month) ? cur.month : null;
      const date = new RegExp('^(3[0-1]|[1-2][0-9]|[1-9]|[*])$').test(cur.date) ? cur.date : null;
      const day = new RegExp('^([0-6](/[2-4]|[@|^][1-5])?|[*])$').test(cur.day) ? cur.day : null;
      const start = new RegExp('^(2[0-3]|[0-1][0-9]):(00|30)$').test(cur.start) ? cur.start : null;
      const end = new RegExp('^((2[0-3]|[0-1][0-9]):(00|30)|24:00)$').test(cur.end) ? cur.end : null;
      if (!type || !year || !month || !date || !day || !start || !end) {
        console.log('parseTimeFrames: match error at index ' + index);
        return acc;
      }
      try {
        if (day.includes('@') || day.includes('^')) {
          const suffix = (day.includes('@') ? '@' : '^') + day.split(new RegExp('[@^]'))[1];
          const _date = when(suffix)
            .on(
              s => s === '@1',
              _ => '1st' as DateType,
            )
            .on(
              s => s === '@2',
              _ => '2nd' as DateType,
            )
            .on(
              s => s === '@3',
              _ => '3rd' as DateType,
            )
            .on(
              s => s === '@4',
              _ => '4th' as DateType,
            )
            .on(
              s => s === '@5',
              _ => '5th' as DateType,
            )
            .on(
              s => s === '^1',
              _ => 'in_1st_week' as DateType,
            )
            .on(
              s => s === '^2',
              _ => 'in_2nd_week' as DateType,
            )
            .on(
              s => s === '^3',
              _ => 'in_3rd_week' as DateType,
            )
            .on(
              s => s === '^4',
              _ => 'in_4th_week' as DateType,
            )
            .on(
              s => s === '^5',
              _ => 'in_5th_week' as DateType,
            )
            .otherwise(_ => null);
          if (!_date) {
            console.log('parseTimeFrames: index ' + index + ' skipped');
            return acc;
          }
          return [
            ...acc,
            {
              type,
              frequency: month !== '*' ? 'every_year' : 'every_month',
              month: month !== '*' ? (Number(month) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) : null,
              date: _date,
              day: Number(day.split(new RegExp('[@^]'))[0]) as 0 | 1 | 2 | 3 | 4 | 5 | 6,
              startDate: null,
              startTime: start,
              endTime: end,
            },
          ];
        }
        if (year === '*' && month === '*' && date === '*') {
          return [
            ...acc,
            {
              type,
              frequency: day !== '*' ? 'every_week' : 'every_day',
              month: null,
              date: null,
              day: day !== '*' ? (Number(day) as 0 | 1 | 2 | 3 | 4 | 5 | 6) : null,
              startDate: null,
              startTime: start,
              endTime: end,
            },
          ];
        }
        if (year !== '*') {
          if (month === '*' || date === '*') {
            console.log('parseTimeFrames: index ' + index + ' skipped');
            return acc;
          }
          if (day.includes('/')) {
            const frequency: FrequencyType = when(day.split('/')[1])
              .on(
                w => w === '2',
                _ => 'every_2_weeks' as FrequencyType,
              )
              .on(
                w => w === '3',
                _ => 'every_3_weeks' as FrequencyType,
              )
              .on(
                w => w === '4',
                _ => 'every_4_weeks' as FrequencyType,
              )
              .otherwise(_ => null);
            if (!frequency) {
              console.log('parseTimeFrames: index ' + index + ' skipped');
              return acc;
            }
            return [
              ...acc,
              {
                type,
                frequency,
                month: null,
                date: null,
                day: null,
                startDate: new Date(`${year}/${month}/${date}`),
                startTime: start,
                endTime: end,
              },
            ];
          } else {
            return [
              ...acc,
              {
                type,
                frequency: 'once',
                month: null,
                date: null,
                day: null,
                startDate: new Date(`${year}/${month}/${date}`),
                startTime: start,
                endTime: end,
              },
            ];
          }
        }
        if (date !== '*' && day === '*') {
          return [
            ...acc,
            {
              type,
              frequency: month !== '*' ? 'every_year' : 'every_month',
              month: month !== '*' ? (Number(month) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) : null,
              date: Number(date),
              day: null,
              startDate: null,
              startTime: start,
              endTime: end,
            },
          ];
        }
        console.log('parseTimeFrames: index ' + index + ' skipped');
        return acc;
      } catch (error) {
        console.log('parseTimeFrames: error at index ' + index);
        console.log(error);
      }
      return acc;
    }, []);
  }
}
