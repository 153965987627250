import { Injectable } from '@angular/core';
import { IPrescription } from 'src/models';
import { CognitoService } from '../cognito.service';
import { ServiceBase } from '../service-base';
import { Store } from '@ngrx/store';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionService extends ServiceBase<IPrescription> {
  readonly propertyName = 'prescriptions';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'prescriptions');
  }

  findAll(pharmacy_id?: string, status?: string): Promise<IPrescription[]> {
    const params = {
      pharmacy_id,
      status,
    };
    return super.findAll(params);
  }

  public findAllWithPagination(params: { status?: string; limit?: number; last_key?: string } = { limit: 25 }) {
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IPrescription[],
      pagination: result.pagination as { totalrecords: number; displayrecords: number; last_key: string },
    }));
  }

  async confirm(prescriptionId: string, message: string) {
    axios.post(
      environment.api_base_url + `pharmacist/prescriptions/${prescriptionId}/confirm`,
      { message },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  async dispensed(prescriptionId: string, message: string) {
    axios.post(
      environment.api_base_url + `pharmacist/prescriptions/${prescriptionId}/dispensed`,
      { message },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  async complete(prescriptionId: string, message: string) {
    axios.post(
      environment.api_base_url + `pharmacist/prescriptions/${prescriptionId}/complete`,
      { message },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  async requestResend(prescriptionId: string, message?: string, status_reason?: string) {
    status_reason = status_reason === undefined || status_reason === 'その他' ? '' : status_reason;
    axios.post(
      environment.api_base_url + `pharmacist/prescriptions/${prescriptionId}/req_resend`,
      { message, status_reason },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  async reject(prescriptionId: string, message?: string, status_reason?: string) {
    status_reason = status_reason === undefined || status_reason === 'その他' ? '' : status_reason;
    axios.post(
      environment.api_base_url + `pharmacist/prescriptions/${prescriptionId}/reject`,
      { message, status_reason },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  async changeMessage(prescriptionId: string, message: string): Promise<void> {
    await axios.post(
      environment.api_base_url + `pharmacist/prescriptions/${prescriptionId}/change_message`,
      { message },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  async getImage(prescriptionId: string, uploadId: string, imageId: string): Promise<string> {
    return (
      await axios.get<any>(
        environment.api_base_url + `pharmacist/prescriptions/${prescriptionId}/uploads/${uploadId}/images/${imageId}`,
        await this.buildConfig(undefined, 'application/json'),
      )
    ).data.image as string;
  }

  async cancel(prescriptionId: string, message?: string, status_reason?: string) {
    status_reason = status_reason === undefined || status_reason === 'その他' ? '' : status_reason;
    axios.post(
      environment.api_base_url + `pharmacist/prescriptions/${prescriptionId}/cancel`,
      { message, status_reason },
      await this.buildConfig(undefined, 'application/json'),
    );
  }

  async confirmCancel(prescriptionId: string) {
    axios.post(
      environment.api_base_url + `pharmacist/prescriptions/${prescriptionId}/confirm_cancel`,
      {},
      await this.buildConfig(undefined, 'application/json'),
    );
  }
}
