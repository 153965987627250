<div class="root" fxLayout="column">
  <h1>服薬フォロー一覧</h1>
  <div fxLayout="row" class="patient-search-form-container">
    <mat-form-field fxFlex="50">
      <mat-label class="search-label">名前で検索</mat-label>
      <input matInput type="text" [formControl]="patientFilterControl" />
    </mat-form-field>
    <div fxFlex="50" fxLayout="row-reverse">
      <loading-button
        class="loading-button"
        color="primary"
        name="新規服薬フォロー"
        [loading]="loading"
        icon="playlist_add"
        routerLink="/pharmacist/patients"
      ></loading-button>
    </div>
  </div>
  <div class="table-container">
    <div class="paginator-and-table">
      <app-custom-paginator
        class="paginator"
        [disabled]="loading"
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
        (reload)="reloadEvent()"
      ></app-custom-paginator>
      <div class="table" fxLayout="column">
        <mat-card class="header" fxLayout="row" fxFlexGap="10px">
          <div
            class="header-content"
            fxFlex="{{ columnProportion[0] }}"
            fxFlex.lt-md="{{ columnProportionLtMd[0] }}"
            fxLayout="row"
          >
            <div>送信日時</div>
          </div>
          <div class="header-content" fxFlex="{{ columnProportion[1] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">
            薬剤師名
          </div>
          <div class="header-content" fxFlex="{{ columnProportion[2] }}" fxFlex.lt-md="{{ columnProportionLtMd[1] }}">
            患者名
          </div>
          <div class="header-content" fxFlex="{{ columnProportion[3] }}" fxHide.lt-md>電話番号</div>
          <div
            class="header-content clickable"
            fxFlex="{{ columnProportion[4] }}"
            fxFlex.lt-md="{{ columnProportionLtMd[0] }}"
            #stateFilterControllerOrigin="cdkOverlayOrigin"
            cdk-overlay-origin
            fxLayout="row"
            (click)="filterByStatus()"
          >
            <div>実施状況</div>
            <mat-icon class="patient-info-icon">filter_alt</mat-icon>
          </div>
          <div
            class="header-content"
            fxFlex="{{ columnProportion[5] }}"
            fxFlex.lt-md="{{ columnProportionLtMd[0] }}"
            fxLayout="row"
          >
            <div>回答日時</div>
          </div>
        </mat-card>
        <mat-card
          *ngFor="let follow of followsForDisplay; index as i"
          class="element clickable"
          (click)="openDialog(follow)"
          [class.unclickable]="followsDisabled[i]"
        >
          <div class="element-content" fxFlex="{{ columnProportion[0] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">
            {{ follow.send_at | date: timeDisplayFormat }}
          </div>
          <div class="element-content" fxFlex="{{ columnProportion[1] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">
            {{ getPharmacistName(follow.pharmacist) }}
          </div>
          <div class="element-content" fxFlex="{{ columnProportion[2] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">
            <a
              color="primary"
              (click)="$event.stopPropagation()"
              [routerLink]="'/pharmacist/patients/' + follow.patient_info_id"
            >
              <mat-icon
                class="patient-info-icon"
                matTooltip="患者詳細"
                matTooltipPosition="right"
                matTooltipShowDelay="250"
              >
                assignment_ind</mat-icon
              >
            </a>
            {{ getPatientName(follow.patient_info) }}
          </div>
          <div class="element-content" fxFlex="{{ columnProportion[3] }}" fxHide.lt-md>
            {{ follow.patient_info?.tel }}
          </div>
          <div
            class="element-content"
            [class]="follow.status === '未確認' ? 'unanswered' : ''"
            fxFlex="{{ columnProportion[4] }}"
            fxFlex.lt-md="{{ columnProportionLtMd[0] }}"
          >
            {{ follow.status }}
          </div>
          <div class="element-content" fxFlex="{{ columnProportion[5] }}" fxFlex.lt-md="{{ columnProportionLtMd[0] }}">
            {{ follow.answered_at | date: timeDisplayFormat }}
          </div>
        </mat-card>
      </div>
    </div>
    <div *ngIf="loading" class="loading-shade"><mat-spinner></mat-spinner></div>
  </div>
</div>
