import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { IPCITemplate } from 'src/models';
import { PCITemplateService } from 'src/services/api/pci-template.service';

@Component({
  selector: 'app-pci-templates',
  templateUrl: './pci-templates.component.html',
  styleUrls: ['./pci-templates.component.scss'],
})
export class PciTemplatesComponent implements OnInit {
  pciTemplates: IPCITemplate[] = [];
  readonly displayedColumns: readonly string[] = ['name'];

  totalRecords = 0;
  pageNumber = 0;
  readonly pageSize = 100;
  private lastKeys: string[] = [];

  loading = true;

  constructor(private pciTemplateService: PCITemplateService) {}

  async ngOnInit() {
    this.loading = true;
    try {
      await this.fetchPciTemplates();
    } finally {
      this.loading = false;
    }
  }

  private async fetchPciTemplates(params?: { last_key?: string }) {
    const pagination = await this.pciTemplateService
      .findAllWithPagination({ ...params, limit: this.pageSize })
      .then(data => {
        this.pciTemplates = data.pci_templates;
        return data.pagination;
      });
    this.totalRecords = pagination.totalrecords;
    if (pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      this.lastKeys.push(pagination.last_key);
    }
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      await this.fetchPciTemplates({
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
    } finally {
      this.loading = false;
    }
  }

  async reloadEvent() {
    this.loading = true;
    this.pageNumber = 0;
    this.lastKeys = [];
    try {
      await this.fetchPciTemplates();
    } finally {
      this.loading = false;
    }
  }
}
