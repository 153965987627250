import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { IPatientInfo } from 'src/models';
import { Payment, PaymentStatus, PaymentMethod } from 'src/models/payment';
import { PaymentService } from 'src/services/api/payment.service';
import { NotificationService } from 'src/services/notification.service';
import { PopupCheckboxService } from 'src/app/parts/checkbox-list/checkbox-list.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { PaymentStatusPipe } from 'src/pipes/payment_status.pipe';
import { Sort } from '@angular/material/sort';
import { SettlementMethodPipe } from 'src/pipes/settlement_method.pipe';
import { DeliveryMethodPiPe } from 'src/pipes/delivery_method.pipe';
import { DeliveryMethodEnum } from 'src/models/pci';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss'],
})
export class PaymentsListComponent implements OnInit {
  @ViewChild('statusHeader') private statusHeader!: CdkOverlayOrigin;
  @ViewChild('paymentMethodHeader') private paymentMethodHeader!: CdkOverlayOrigin;
  @ViewChild('deliveryMethodHeader') private deliveryMethodHeader!: CdkOverlayOrigin;
  payments: Payment[] = [];
  paymentsDisabled: boolean[] = [];
  patientFilterController = new FormControl('');
  patientFilterControllerSubscription: Subscription;
  readonly displayedColumns: readonly string[] = ['patient_info', 'settlement_method', 'delivery_method', 'status', 'insurance_covered_price', 'insurance_uncovered_price', 'shipping_fee', 'created_at', 'completed_date'];

  nameFilterFormControl = new FormControl('');
  loading = true;

  readonly pageSize = 100;
  totalRecords = 0;
  pageNumber = 0;
  lastKeys: string[] = [];

  sort_type = {
    key: 'created_at',
    order: 'asc',
  }

  status: PaymentStatus[] = [
    PaymentStatus.created,
    PaymentStatus.started,
    PaymentStatus.completed,
    PaymentStatus.failed,
    PaymentStatus.cancelled,
  ];
  statusFilter: boolean[] = [true, true, true, true, true];
  reloadEventId: NodeJS.Timer | null = null;

  private get statusFilterString() {
    return this.statusFilter
      .reduce((acc: string, cur, index) => (cur ? acc + this.status[index] + ',' : acc), '')
      .slice(0, -1);
  }

  paymentMethod: PaymentMethod[] = [
    PaymentMethod.credit_card,
    PaymentMethod.others,
  ];
  paymentMethodFilter: boolean[] = [true, true];

  private get paymentMethodFilterString() {
    return this.paymentMethodFilter
      .reduce((acc: string, cur, index) => (cur ? acc + this.paymentMethod[index] + ',' : acc), '')
      .slice(0, -1);
  }

  deliveryMethod: DeliveryMethodEnum[] = [
    DeliveryMethodEnum.normal,
    DeliveryMethodEnum.collect_on_delivery,
    DeliveryMethodEnum.drop_by,
  ];
  deliveryMethodFilter: boolean[] = [true, true, true];

  private get deliveryMethodFilterString() {
    return this.deliveryMethodFilter
      .reduce((acc: string, cur, index) => (cur ? acc + this.deliveryMethod[index] + ',' : acc), '')
      + DeliveryMethodEnum.cash_on_delivery;
  }

  get displayPayments() {
    return this.payments.filter(p => p.patient_info !== undefined);
  }

  constructor(
    private readonly paymentService: PaymentService,
    private readonly notificationService: NotificationService,
    private readonly popupCheckboxService: PopupCheckboxService,
    private readonly paymentStatusPipe: PaymentStatusPipe,
    private readonly settlementMethodPipe: SettlementMethodPipe,
    private readonly deliveryMethodPipe: DeliveryMethodPiPe,
  ) {
    this.patientFilterControllerSubscription = this.patientFilterController.valueChanges
      .pipe<string>(startWith(''))
      .subscribe(_ => this.filterByName());
  }

  async ngOnInit() {
    const init = async () => {
      this.loading = true;
      try {
        this.payments = await this.fetchPayment();
        this.filterByName();
      } finally {
        this.loading = false;
      }
    };
    this.notificationService.messageReceive.subscribe(async _ => await init());
    await init();
    this.reloadEventId = setInterval(async () => {
      this.loading = true;
      try {
        this.payments = await this.fetchPayment({
          last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
        });
        this.filterByName();
      } finally {
        this.loading = false;
      }
    }, 300000);
  }

  ngOnDestroy() {
    if (this.reloadEventId) {
      clearInterval(this.reloadEventId);
    }
    this.reloadEventId = null;
  }

  filterByName(): void {
    const filters = (this.patientFilterController.value as string).trim().split(' ');
    this.paymentsDisabled = this.payments.map(
      p =>
        !(
          (filters.length === 1 && !filters[0]) ||
          filters.every(f => this.getPatientName(p.patient_info).includes(f)) ||
          filters.every(f => this.getPatientNameKana(p.patient_info).includes(f))
        ),
    );
  }

  async filterByStatus() {
    this.statusFilter = await this.popupCheckboxService.showOverlay(
      this.statusHeader.elementRef,
      this.status.map(s => this.paymentStatusPipe.transform(s)),
      this.statusFilter,
    );
    try {
      this.loading = true;
      this.pageNumber = 0;
      this.lastKeys = [];
      this.payments = await this.fetchPayment();
    } finally {
      this.filterByName();
      this.loading = false;
    }
  }

  async filterByPaymentMethod() {
    this.paymentMethodFilter = await this.popupCheckboxService.showOverlay(
      this.paymentMethodHeader.elementRef,
      this.paymentMethod.map(s => this.settlementMethodPipe.transform(s)),
      this.paymentMethodFilter,
    );
    try {
      this.loading = true;
      this.pageNumber = 0;
      this.lastKeys = [];
      this.payments = await this.fetchPayment();
    } finally {
      this.filterByName();
      this.loading = false;
    }
  }

  async filterByDeliveryMethod() {
    this.deliveryMethodFilter = await this.popupCheckboxService.showOverlay(
      this.deliveryMethodHeader.elementRef,
      this.deliveryMethod.map(s => this.deliveryMethodPipe.transform(s)),
      this.deliveryMethodFilter,
    );
    try {
      this.loading = true;
      this.pageNumber = 0;
      this.lastKeys = [];
      this.payments = await this.fetchPayment();
    } finally {
      this.filterByName();
      this.loading = false;
    }
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      this.payments = await this.fetchPayment({
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
      this.filterByName();
    } finally {
      this.loading = false;
    }
  }

  async reloadEvent() {
    this.loading = true;
    this.pageNumber = 0;
    this.lastKeys = [];
    try {
      this.payments = await this.fetchPayment();
      this.filterByName();
    } finally {
      this.loading = false;
    }
  }

  private async fetchPayment(params?: { last_key?: string }) {
    const data = await this.paymentService.findAllWithPagination({
      ...params, limit: this.pageSize,
      status: this.statusFilterString, payment_method: this.paymentMethodFilterString, delivery_method: this.deliveryMethodFilterString, sort_key: this.sort_type.key, sort_order: this.sort_type.order
    });
    const pagination = data.pagination;
    this.totalRecords = pagination.totalrecords;
    if (pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      this.lastKeys.push(pagination.last_key);
    }
    return data.payments;
  }

  getPatientName(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name ?? '') + (patientInfo?.given_name ?? '');
  }

  getPatientNameKana(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name_kana ?? '') + (patientInfo?.given_name_kana ?? '');
  }

  async sortData(sort: Sort) {
    this.sort_type = {
      key: sort.active,
      order: sort.direction,
    };
    try {
      this.loading = true;
      this.pageNumber = 0;
      this.lastKeys = [];
      this.payments = await this.fetchPayment();
    } finally {
      this.filterByName();
      this.loading = false;
    }
  }
}
