import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDispensingStatus } from 'src/models';
import { DispensingStatus } from 'src/models/dispensing-status';
import { CognitoService } from '../cognito.service';
import { ServiceBase } from '../service-base';

@Injectable({
  providedIn: 'root',
})
export class DispensingStatusService extends ServiceBase<IDispensingStatus> {
  propertyName = 'dispensing_statuses';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'dispensing_statuses');
  }

  public findAll(params: {
    pharmacy_id: string;
    offset?: number;
    limit?: number;
    patient_account_id?: string;
    patient_info_id?: string;
    pharmacist_id?: string;
  }): Promise<IDispensingStatus[]> {
    return super.findAll(params);
  }

  public confirmed(dispensingStatus: IDispensingStatus, type: 'post' | 'put') {
    dispensingStatus = { ...dispensingStatus, status: DispensingStatus.confirmed };
    if (type === 'post') {
      return super.create(dispensingStatus);
    } else {
      return super.update(dispensingStatus);
    }
  }

  public informedToPatient(dispensingStatus: IDispensingStatus, type: 'post' | 'put') {
    dispensingStatus = { ...dispensingStatus, status: DispensingStatus.informedToPatient };
    if (type === 'post') {
      return super.create(dispensingStatus);
    } else {
      return super.update(dispensingStatus);
    }
  }
}
