import {ICognitoAccount} from './cognito-account';
import { IEntityBase } from './entity-base';

export interface IDispensingStatus extends IEntityBase {
    id: string;
    pharmacy_id: string;
    pharmacist_id?: string;
    patient_account_id: string;
    patient_info_id: string;
    prescription_id: string;
    status: DispensingStatus;
}


export enum DispensingStatus {
    received = 'received',
    confirmed = 'confirmed',
    connectedToHistory = 'connectedToHistory',
    dispensed = 'dispensed',
    informedToPatient = 'informedToPatient',
    receivedByPatient = 'receivedByPatient'
}
